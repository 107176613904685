import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    PackageApi,
    PackagePackageRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type PackageApiObject = PackagePackageRead & Record<string, unknown>;

export type Packages = PackageApiObject;

export const PACKAGES_SLICE_NAME = 'packages';

const mapperPackages = function (packages: PackageApiObject) {
    return {
        ...packages,
    } as Packages;
};

export function createPackagesSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new PackageApi(conf);

    const fetchPackagesCollection: FetchCollectionData<PackageApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
    }) =>
        api
            .apiPackagesGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
                domaineSocietesSocieteId: xIdSociete,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<PackageApiObject>>;

    const fetchPackagesItem: FetchItemDataType<PackageApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiPackagesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<PackageApiObject>;

    const { slice: packagesSlice, dataSourcesThunks: packagesDataSourcesThunks } =
        SliceFactory.createSlice<PackageApiObject, Packages>(
            PACKAGES_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchPackagesCollection,
            fetchPackagesItem,
            mapperPackages,
        );

    return { packagesSlice, packagesDataSourcesThunks };
}
