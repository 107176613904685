import {
    DomainesDomaineRead,
    GroupementGroupementRead,
    OriginesOffresOrigineOffreRead,
    PackagePackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useEffect, useState } from 'react';
import { originApi } from '@b2d/redux/subReducers/Offers';
import offerGroupModel from '../../models/offerGroup';
import offerPackageModel from '../../models/offerPackage';
import offerModel from '../../models/offer';
import useFetchApiResource from '../../Package/Form/useFetchCollection';
import { formatOptionsByKeys } from '../../forms/options';
import { SelectInputOptions } from '../../forms/types';
import { domainIds } from '../../constants';

type Result<T> = { values: T[]; options: SelectInputOptions };

export type UseDomainRelatedResult = {
    packages: Result<PackagePackageRead>;
    groups: Result<GroupementGroupementRead>;
    offers: Result<DomainesDomaineRead>;
    origins: Result<OriginesOffresOrigineOffreRead>;
};

type Callback = (result: UseDomainRelatedResult) => void;

/** Hook used to simplify domain related values lifecycle managment in catalog form :
 *
 * - Listener on domain id value change that apply callback on result :
 * - Result are raw values and formated for options for selects
 *
 * - This hook provide fetch method for endpoints that are domain filterable
 * - It expose fetching status
 *
 */
const useDomainRelatedValues = (domainId: number, onResultCallback?: Callback) => {
    const [hasFetched, setHasFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    /** Domain related values fetch configuration */

    const { packages, groups, offers, origins } = {
        packages: () => offerPackageModel.list({ domaineId: domainId, itemsPerPage: 1000 }),
        groups: () => offerGroupModel.list({ domaineId: domainId, itemsPerPage: 1000 }),
        offers: () => offerModel.list({ domaineId: domainId, itemsPerPage: 1000 }),
        origins: () =>
            originApi.apiOriginesGetCollection({ domainesDomaineId: domainId, itemsPerPage: 1000 }),
    };

    const { fetch: fetchDomainPackages, result: domainPackageValues } =
        useFetchApiResource<PackagePackageRead[]>(packages);

    const { fetch: fetchDomainGroups, result: domainGroupValues } =
        useFetchApiResource<GroupementGroupementRead[]>(groups);

    const { fetch: fetchDomainOffers, result: domainOffersValues } =
        useFetchApiResource<DomainesDomaineRead[]>(offers);

    const { fetch: fetchDomainOrigins, result: domainOriginsValues } =
        useFetchApiResource<OriginesOffresOrigineOffreRead[]>(origins);

    const getValues = () => {
        const packagesOptions = domainPackageValues.map(
            ({ libelle: label, formalite, id, domaine }: PackagePackageRead) => {
                const formalityDomain = domaine?.id !== domainIds.formality;
                const formalityName = formalite?.libelle;
                const optionLabel = formalityDomain ? `${label}` : `${label} (${formalityName})`;
                return { value: id, label: optionLabel };
            },
        );
        const groupsOptions = formatOptionsByKeys(domainGroupValues, 'id', 'libelle');
        const offersOptions = formatOptionsByKeys(domainOffersValues, 'id', 'libelle');
        const originsOptions = formatOptionsByKeys(domainOriginsValues, 'id', 'origine');

        return {
            packages: { values: domainPackageValues, options: packagesOptions },
            groups: { values: domainGroupValues, options: groupsOptions },
            offers: { values: domainOffersValues, options: offersOptions },
            origins: { values: domainOriginsValues, options: originsOptions },
        };
    };

    const fetchDomainRelatedData = async () => {
        if (!hasFetched) {
            setHasFetched(true);
        }
        setIsFetching(true);
        await Promise.allSettled([
            fetchDomainPackages(),
            fetchDomainGroups(),
            fetchDomainOffers(),
            fetchDomainOrigins(),
        ]).then(() => {
            setIsFetching(false);
        });
    };

    useEffect(() => {
        if (hasFetched && !isFetching && onResultCallback) {
            onResultCallback(getValues());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFetched, isFetching]);

    return {
        isFetching,
        hasFetched,
        values: getValues(),
        fetch: fetchDomainRelatedData,
    };
};
export default useDomainRelatedValues;
