import React, { useState } from 'react';
import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { selectFormality } from '@b2d/redux/subReducers/FormalityReducer';
import { todoIcon } from '@europrocurement/l2d-icons';
import { AnyAction } from '@reduxjs/toolkit';
import { FormaliteJsonldFormaliteUpdate } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import FormalityCompatibilitiesCard from './FormalityCompatibilitiesCard';
import CardTitle from '../../components/CardTitle';
import ActionButton from '../../components/ActionButton';
import CompatibilitiesModal from './CompatibilitiesModal';
import useFormalityFormHandler from '../../hooks/useFormalityFormHandler';

type FormalityCompatbilitiesTabProps = {
    formality: Formalite;
};

const FormalityCompatbilitiesTab: React.FunctionComponent<FormalityCompatbilitiesTabProps> =
    function (props) {
        const { formality } = props;
        const dispatch = useDispatch();
        const [editModalOpen, setEditModalOpen] = useState(false);
        const { updateFormality } = useFormalityFormHandler();

        const onOpenCompatibilitiesModal = () => {
            setEditModalOpen(true);
        };
        const onCloseCompatibilitiesModal = () => {
            setEditModalOpen(false);
        };
        const onEditCompatibilities = (selectedItems: (string | number)[]) => {
            updateFormality(formality, {
                compatibilities: selectedItems,
            } as FormaliteJsonldFormaliteUpdate).then(() => {
                dispatch(selectFormality({ id: formality.id as number }) as unknown as AnyAction);
            });
        };
        const onRemoveCompatibility = (compatibilityId: string | number) => {
            const tmp = formality.compatibilities ? formality.compatibilities : [];
            const compatibilities = tmp.filter((item: string | number) => item !== compatibilityId);
            onEditCompatibilities(compatibilities);
        };

        return (
            <>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={10}
                    >
                        <CardTitle title="Liste des formalités compatibles" />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={2}
                    >
                        <ActionButton
                            label="Modifier la liste"
                            onClick={onOpenCompatibilitiesModal}
                            icon={todoIcon}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormalityCompatibilitiesCard
                            formalityId={formality.id as number}
                            totalItemsCount={formality?.compatibilities?.length as number}
                            onRemoveCompatibility={onRemoveCompatibility}
                        />
                    </Grid>
                </Grid>
                <CompatibilitiesModal
                    isOpen={editModalOpen}
                    onModalClose={onCloseCompatibilitiesModal}
                    onSubmitModal={onEditCompatibilities}
                    itemsSelected={formality?.compatibilities as unknown as number[]}
                    formality={formality}
                />
            </>
        );
    };

export default FormalityCompatbilitiesTab;
