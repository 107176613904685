import { Formalite } from '@europrocurement/l2d-domain/reducers/formalites/slices/formalite';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { FormaliteJsonldFormaliteUpdate } from '@europrocurement/l2d-domain/openApi/ApiFormalite';
import { useEffect, useState } from 'react';
import { CategoriesFormalite, mapperFormalites } from '@europrocurement/l2d-domain';
import { FiltersOpts, SearchOpts } from '@europrocurement/flexy-datatable';
import { OfferGroup } from '@europrocurement/l2d-domain/reducers/offre/slices/offerGroupSlice';
import { useSelector } from 'react-redux';
import { categoriesFormalitySelector } from '@b2d/redux/subReducers/FormalityReducer';
import model from '../models/formality';
import useGroup from './useGroup';

const useCompatibility = () => {
    const { request, requestState } = useApiRequest();
    const dataSetter = useApiRequest();
    const [formalities, setFormalities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({});
    const { groups } = useGroup();
    const categoriesDatasource = useSelector(categoriesFormalitySelector);

    const getFilteredFormalities = () =>
        formalities.filter((formality: Formalite) =>
            formality.libelle?.toLowerCase().match(searchTerm.toLowerCase()),
        );

    const toggleCompatibility = (formality: Formalite, compatibility: Formalite) => {
        const formalityId = formality?.id as unknown as string;
        const compatibilityId = compatibility?.id as unknown as string;
        const formalityCompatibilities = formality.compatibilities
            ? [...formality.compatibilities]
            : [];
        const compatibilityCompatibilities = compatibility.compatibilities
            ? [...compatibility.compatibilities]
            : [];

        if (formalityCompatibilities.indexOf(compatibilityId) === -1) {
            formalityCompatibilities.push(compatibilityId);
        } else {
            formalityCompatibilities.splice(formalityCompatibilities.indexOf(compatibilityId), 1);
        }

        if (compatibilityCompatibilities.indexOf(formalityId) === -1) {
            compatibilityCompatibilities.push(formalityId);
        } else {
            compatibilityCompatibilities.splice(
                compatibilityCompatibilities.indexOf(formalityId),
                1,
            );
        }

        const newItems = formalities.map((item: Formalite) => {
            if (item.id === formality.id) {
                const newItem = { ...item };
                newItem.compatibilities = formalityCompatibilities;

                return newItem;
            }

            if (item.id === compatibility.id) {
                const newItem = { ...item };
                newItem.compatibilities = compatibilityCompatibilities;

                return newItem;
            }

            return item;
        });
        setFormalities(newItems);

        const payload: FormaliteJsonldFormaliteUpdate = {
            compatibilities: formalityCompatibilities,
            libelle: formality?.libelle as string,
        };

        dataSetter.request(
            model.update({
                id: formalityId,
                formaliteJsonldFormaliteUpdate: payload,
            }),
            {
                withToaster: false,
            },
        );

        return { formalityCompatibilities, compatibilityCompatibilities };
    };

    const loadFormalities = () => {
        const query = {
            ...filters,
            page: 1,
            itemsPerPage: 1000,
            deleted: false,
        };
        request(model.list(query), {
            successCallback: (response) => {
                const result = response.data['hydra:member']
                    .map(mapperFormalites)
                    .sort((a: Formalite, b: Formalite) => {
                        const categoryA = a?.categorie?.id || null;
                        const categoryB = b?.categorie?.id || null;

                        if (categoryA === null) {
                            return 1;
                        }

                        if (categoryB === null) {
                            return -1;
                        }

                        if (categoryA === categoryB) {
                            return 0;
                        }

                        return categoryA < categoryB ? -1 : 1;
                    });

                setFormalities(result);
            },
            withToaster: false,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadFormalities, [filters]);

    const filterOpts: FiltersOpts = {
        filters: [
            {
                label: 'Groupement',
                field: 'groupement',
                type: 'staticSelect',
                options: groups.map((group: OfferGroup) => ({
                    value: group.id,
                    label: group.libelle,
                })),
                renderFilterList: (value) => {
                    const t: OfferGroup = groups.find((group: OfferGroup) => group.id === value);

                    return t?.libelle as string;
                },
            },
            {
                label: 'Catégorie',
                field: 'categorieId',
                type: 'staticSelect',
                options: categoriesDatasource.data.map((category: CategoriesFormalite) => ({
                    value: category.id,
                    label: category.libelle,
                })),
                renderFilterList: (value) =>
                    categoriesDatasource.data.find(
                        (category: CategoriesFormalite) => category.id === value,
                    )?.libelle,
            },
        ],
        activeFilters: filters,
        onFilterChange: (key: string, value) => {
            const tmp: Record<string, unknown> = { ...filters };
            tmp[key] = value;

            setFilters(tmp);
        },
        onFilterDelete: (key: string) => {
            const tmp: Record<string, unknown> = { ...filters };

            if (tmp[key]) {
                delete tmp[key];
            }
            setFilters(tmp);
        },
    };

    const searchOpts: SearchOpts = {
        onSearchChange: (newTerm) => {
            setSearchTerm(newTerm);
        },
        search: searchTerm,
        filtersOpts: filterOpts,
    };

    const getDistinctCategoriesMap = (formalitiesItems: Formalite[]) => {
        const hasCategory: number[] = [];
        const distinctCategories = new Map();

        formalitiesItems.forEach((formality: Formalite) => {
            const categoryId = formality?.categorie?.id || 0;

            if (hasCategory.indexOf(categoryId) === -1) {
                hasCategory.push(categoryId);
                distinctCategories.set(categoryId, {
                    id: categoryId,
                    label: formality?.categorie?.libelle || '-',
                    iconeName: formality?.categorie?.iconeName || '',
                    count: 0,
                });
            }

            distinctCategories.set(categoryId, {
                id: categoryId,
                label: formality?.categorie?.libelle || '-',
                iconeName: formality?.categorie?.iconeName || '',
                count: distinctCategories.get(categoryId).count + 1,
            });
        });

        return distinctCategories;
    };

    const getDistinctCategories = (formalitiesItems: Formalite[]) =>
        Array.from(getDistinctCategoriesMap(formalitiesItems)).map((item) => item[1]);

    return {
        requestState,
        formalities,
        searchOpts,
        loadFormalities,
        toggleCompatibility,
        getFilteredFormalities,
        getDistinctCategories,
        getDistinctCategoriesMap,
    };
};

export default useCompatibility;
