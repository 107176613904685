/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Formalite
 * API de gestion des formalite
 *
 * The version of the OpenAPI document: 1.9.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailRead
 */
export interface ActionDetailJsonldActionDetailRead {
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionDetailJsonldActionDetailRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
}
/**
 * @type ActionDetailJsonldActionDetailReadContext
 * @export
 */
export type ActionDetailJsonldActionDetailReadContext = ActionDetailJsonldActionDetailReadContextOneOf | string;

/**
 * 
 * @export
 * @interface ActionDetailJsonldActionDetailReadContextOneOf
 */
export interface ActionDetailJsonldActionDetailReadContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof ActionDetailJsonldActionDetailReadContextOneOf
     */
    'hydra': ActionDetailJsonldActionDetailReadContextOneOfHydraEnum;
}

export const ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type ActionDetailJsonldActionDetailReadContextOneOfHydraEnum = typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum[keyof typeof ActionDetailJsonldActionDetailReadContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface ActionsEtActivitSActionCreate
 */
export interface ActionsEtActivitSActionCreate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionCreated
 */
export interface ActionsEtActivitSActionCreated {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActionCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSActionCreatedAction}
     * @memberof ActionsEtActivitSActionCreated
     */
    'action'?: ActionsEtActivitSActionCreatedAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionCreatedAction
 */
export interface ActionsEtActivitSActionCreatedAction {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionCreatedAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionCreatedAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionRead
 */
export interface ActionsEtActivitSActionRead {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActionRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSActionReadAction}
     * @memberof ActionsEtActivitSActionRead
     */
    'action'?: ActionsEtActivitSActionReadAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionReadAction
 */
export interface ActionsEtActivitSActionReadAction {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionReadAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionReadAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActionUpdate
 */
export interface ActionsEtActivitSActionUpdate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActionUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreate
 */
export interface ActionsEtActivitSActiviteCreate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreated
 */
export interface ActionsEtActivitSActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSActiviteCreatedAction}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'action'?: ActionsEtActivitSActiviteCreatedAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {ActionsEtActivitSActiviteCreatedDossier}
     * @memberof ActionsEtActivitSActiviteCreated
     */
    'dossier'?: ActionsEtActivitSActiviteCreatedDossier | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreatedAction
 */
export interface ActionsEtActivitSActiviteCreatedAction {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreatedAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteCreatedAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteCreatedDossier
 */
export interface ActionsEtActivitSActiviteCreatedDossier {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteCreatedDossier
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteRead
 */
export interface ActionsEtActivitSActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSActiviteReadAction}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'action'?: ActionsEtActivitSActiviteReadAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {ActionsEtActivitSActiviteReadDossier}
     * @memberof ActionsEtActivitSActiviteRead
     */
    'dossier'?: ActionsEtActivitSActiviteReadDossier | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteReadAction
 */
export interface ActionsEtActivitSActiviteReadAction {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteReadAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteReadAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteReadDossier
 */
export interface ActionsEtActivitSActiviteReadDossier {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteReadDossier
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSActiviteUpdate
 */
export interface ActionsEtActivitSActiviteUpdate {
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSActiviteUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionCreate
 */
export interface ActionsEtActivitSJsonldActionCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionCreated
 */
export interface ActionsEtActivitSJsonldActionCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActionCreatedAction}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'action'?: ActionsEtActivitSJsonldActionCreatedAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionCreatedAction
 */
export interface ActionsEtActivitSJsonldActionCreatedAction {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionCreatedAction
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreatedAction
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreatedAction
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionCreatedAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionCreatedAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionRead
 */
export interface ActionsEtActivitSJsonldActionRead {
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActionReadAction}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'action'?: ActionsEtActivitSJsonldActionReadAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionReadAction
 */
export interface ActionsEtActivitSJsonldActionReadAction {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionReadAction
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionReadAction
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionReadAction
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionReadAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionReadAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActionUpdate
 */
export interface ActionsEtActivitSJsonldActionUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActionUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreate
 */
export interface ActionsEtActivitSJsonldActiviteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreated
 */
export interface ActionsEtActivitSJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActiviteCreatedAction}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'action'?: ActionsEtActivitSJsonldActiviteCreatedAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActiviteCreatedDossier}
     * @memberof ActionsEtActivitSJsonldActiviteCreated
     */
    'dossier'?: ActionsEtActivitSJsonldActiviteCreatedDossier | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreatedAction
 */
export interface ActionsEtActivitSJsonldActiviteCreatedAction {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedAction
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedAction
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedAction
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteCreatedDossier
 */
export interface ActionsEtActivitSJsonldActiviteCreatedDossier {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedDossier
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedDossier
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedDossier
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteCreatedDossier
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteRead
 */
export interface ActionsEtActivitSJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'user'?: number | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActiviteReadAction}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'action'?: ActionsEtActivitSJsonldActiviteReadAction | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {ActionsEtActivitSJsonldActiviteReadDossier}
     * @memberof ActionsEtActivitSJsonldActiviteRead
     */
    'dossier'?: ActionsEtActivitSJsonldActiviteReadDossier | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteReadAction
 */
export interface ActionsEtActivitSJsonldActiviteReadAction {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteReadAction
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteReadAction
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteReadAction
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteReadAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteReadAction
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteReadDossier
 */
export interface ActionsEtActivitSJsonldActiviteReadDossier {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteReadDossier
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteReadDossier
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteReadDossier
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteReadDossier
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ActionsEtActivitSJsonldActiviteUpdate
 */
export interface ActionsEtActivitSJsonldActiviteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'user'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionsEtActivitSJsonldActiviteUpdate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalCreate
 */
export interface AnnoncesLGalesJsonldRelationalCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreate
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalCreated
 */
export interface AnnoncesLGalesJsonldRelationalCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalCreated
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalRead
 */
export interface AnnoncesLGalesJsonldRelationalRead {
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'pdf'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'dossierAL'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalRead
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesJsonldRelationalUpdate
 */
export interface AnnoncesLGalesJsonldRelationalUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesJsonldRelationalUpdate
     */
    'pdf'?: boolean;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalCreate
 */
export interface AnnoncesLGalesRelationalCreate {
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalCreate
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalCreated
 */
export interface AnnoncesLGalesRelationalCreated {
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'pdf': boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'dossierAL': number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalCreated
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalRead
 */
export interface AnnoncesLGalesRelationalRead {
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'pdf'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'dossierAL'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnoncesLGalesRelationalRead
     */
    'dossierF'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnoncesLGalesRelationalUpdate
 */
export interface AnnoncesLGalesRelationalUpdate {
    /**
     * 
     * @type {boolean}
     * @memberof AnnoncesLGalesRelationalUpdate
     */
    'pdf'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdGet404Response
 */
export interface ApiAcomptesIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdGet404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesIdGet404ResponseException}
     * @memberof ApiAcomptesIdGet404Response
     */
    'exception'?: ApiAcomptesIdGet404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdGet404ResponseException
 */
export interface ApiAcomptesIdGet404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdGet404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcomptesIdGet404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdPut400Response
 */
export interface ApiAcomptesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesIdPut400ResponseException}
     * @memberof ApiAcomptesIdPut400Response
     */
    'exception'?: ApiAcomptesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesIdPut400ResponseException
 */
export interface ApiAcomptesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesIdPut400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost400Response
 */
export interface ApiAcomptesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesPost400ResponseException}
     * @memberof ApiAcomptesPost400Response
     */
    'exception'?: ApiAcomptesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost400ResponseException
 */
export interface ApiAcomptesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost401Response
 */
export interface ApiAcomptesPost401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost403Response
 */
export interface ApiAcomptesPost403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiAcomptesPost403ResponseException}
     * @memberof ApiAcomptesPost403Response
     */
    'exception'?: ApiAcomptesPost403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiAcomptesPost403ResponseException
 */
export interface ApiAcomptesPost403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiAcomptesPost403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAcomptesPost403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200Response
 */
export interface ApiActionDetailsGetCollection200Response {
    /**
     * 
     * @type {Array<ActionDetailJsonldActionDetailRead>}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:member': Array<ActionDetailJsonldActionDetailRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiActionDetailsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearch
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiActionDetailsGetCollection200ResponseHydraView
 */
export interface ApiActionDetailsGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiActionDetailsGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiCategoriesGetCollection200Response
 */
export interface ApiCategoriesGetCollection200Response {
    /**
     * 
     * @type {Array<CategorieJsonldCategorieRead>}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:member': Array<CategorieJsonldCategorieRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCategoriesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiCategoriesPost400Response
 */
export interface ApiCategoriesPost400Response {
    /**
     * 
     * @type {ApiCategoriesPost400ResponseError}
     * @memberof ApiCategoriesPost400Response
     */
    'error'?: ApiCategoriesPost400ResponseError;
}
/**
 * 
 * @export
 * @interface ApiCategoriesPost400ResponseError
 */
export interface ApiCategoriesPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiCategoriesPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCategoriesPost400ResponseError
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiCollectionsDossiersGetCollection200Response
 */
export interface ApiCollectionsDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<CollectionDeDossierJsonldCollectiondossierRead>}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:member': Array<CollectionDeDossierJsonldCollectiondossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiCollectionsDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDevisGetCollection200Response
 */
export interface ApiDevisGetCollection200Response {
    /**
     * 
     * @type {Array<DevisJsonldDevisRead>}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:member': Array<DevisJsonldDevisRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDevisGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDocumentgedTypeApplicationtagGetCollection200Response
 */
export interface ApiDocumentgedTypeApplicationtagGetCollection200Response {
    /**
     * 
     * @type {Array<DocumentGedJsonld>}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:member': Array<DocumentGedJsonld>;
    /**
     * 
     * @type {number}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
 */
export interface ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
     */
    'tag'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ApiDomainesGetCollection200Response
 */
export interface ApiDomainesGetCollection200Response {
    /**
     * 
     * @type {Array<DomainesJsonldDomaineRead>}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:member': Array<DomainesJsonldDomaineRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDomainesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierAcomptesGetCollection200Response
 */
export interface ApiDossiersDossierAcomptesGetCollection200Response {
    /**
     * 
     * @type {Array<ReglementsEtAcomptesCBJsonldAcompteRead>}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:member': Array<ReglementsEtAcomptesCBJsonldAcompteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierAcomptesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierActionsGetCollection200Response
 */
export interface ApiDossiersDossierActionsGetCollection200Response {
    /**
     * 
     * @type {Array<ActionsEtActivitSJsonldActionRead>}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:member': Array<ActionsEtActivitSJsonldActionRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierActionsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersGetCollection200Response
 */
export interface ApiDossiersDossierFichiersGetCollection200Response {
    /**
     * 
     * @type {Array<FichiersDossierJsonldDocumentfileRead>}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:member': Array<FichiersDossierJsonldDocumentfileRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierFichiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400Response
 */
export interface ApiDossiersDossierFichiersPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersDossierFichiersPost400ResponseException}
     * @memberof ApiDossiersDossierFichiersPost400Response
     */
    'exception': ApiDossiersDossierFichiersPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400ResponseException
 */
export interface ApiDossiersDossierFichiersPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersDossierFichiersPost400ResponseException
     */
    'violations'?: Array<ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
 */
export interface ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersDossierFichiersPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierMessagesGetCollection200Response
 */
export interface ApiDossiersDossierMessagesGetCollection200Response {
    /**
     * 
     * @type {Array<MessageJsonldMessageRead>}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:member': Array<MessageJsonldMessageRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierMessagesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierNotesGetCollection200Response
 */
export interface ApiDossiersDossierNotesGetCollection200Response {
    /**
     * 
     * @type {Array<NotesEtRappelsJsonldNoteRead>}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:member': Array<NotesEtRappelsJsonldNoteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierNotesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierRappelsGetCollection200Response
 */
export interface ApiDossiersDossierRappelsGetCollection200Response {
    /**
     * 
     * @type {Array<NotesEtRappelsJsonldNoterappelReadCollection>}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:member': Array<NotesEtRappelsJsonldNoterappelReadCollection>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierRappelsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierRelationAnnoncesGetCollection200Response
 */
export interface ApiDossiersDossierRelationAnnoncesGetCollection200Response {
    /**
     * 
     * @type {Array<AnnoncesLGalesJsonldRelationalRead>}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:member': Array<AnnoncesLGalesJsonldRelationalRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierRelationAnnoncesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierTypesDocDossierGetCollection200Response
 */
export interface ApiDossiersDossierTypesDocDossierGetCollection200Response {
    /**
     * 
     * @type {Array<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:member': Array<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierTypesDocDossierGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersGetCollection200Response
 */
export interface ApiDossiersGetCollection200Response {
    /**
     * 
     * @type {Array<DossierJsonldDossierRead>}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:member': Array<DossierJsonldDossierRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400Response
 */
export interface ApiDossiersIdDematFacilePut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdDematFacilePut400ResponseException}
     * @memberof ApiDossiersIdDematFacilePut400Response
     */
    'exception': ApiDossiersIdDematFacilePut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400ResponseException
 */
export interface ApiDossiersIdDematFacilePut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdDematFacilePut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdDematFacilePut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400Response
 */
export interface ApiDossiersIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdPut400ResponseException}
     * @memberof ApiDossiersIdPut400Response
     */
    'exception': ApiDossiersIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400ResponseException
 */
export interface ApiDossiersIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdPut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdPut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdStatutsPut400Response
 */
export interface ApiDossiersIdStatutsPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdStatutsPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdStatutsPut400ResponseException}
     * @memberof ApiDossiersIdStatutsPut400Response
     */
    'exception': ApiDossiersIdStatutsPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdStatutsPut400ResponseException
 */
export interface ApiDossiersIdStatutsPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdStatutsPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdStatutsPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdStatutsPut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdStatutsPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdValidatePut400Response
 */
export interface ApiDossiersIdValidatePut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdValidatePut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersIdValidatePut400ResponseException}
     * @memberof ApiDossiersIdValidatePut400Response
     */
    'exception': ApiDossiersIdValidatePut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdValidatePut400ResponseException
 */
export interface ApiDossiersIdValidatePut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersIdValidatePut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersIdValidatePut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersIdValidatePut400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersIdValidatePut400ResponseException
     */
    'violations'?: Array<ApiDossiersIdValidatePut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersIdValidatePut400ResponseExceptionViolationsInner
 */
export interface ApiDossiersIdValidatePut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersIdValidatePut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersIdValidatePut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersPost400Response
 */
export interface ApiDossiersPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiDossiersPost400ResponseException}
     * @memberof ApiDossiersPost400Response
     */
    'exception': ApiDossiersPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersPost400ResponseException
 */
export interface ApiDossiersPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiDossiersPost400ResponseExceptionViolationsInner>}
     * @memberof ApiDossiersPost400ResponseException
     */
    'violations'?: Array<ApiDossiersPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersPost400ResponseExceptionViolationsInner
 */
export interface ApiDossiersPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiDossiersPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiDossiersPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiFormalistesGetCollection200Response
 */
export interface ApiFormalistesGetCollection200Response {
    /**
     * 
     * @type {Array<FormalisteJsonldFormalisteRead>}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:member': Array<FormalisteJsonldFormalisteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormalistesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalitesGetCollection200Response
 */
export interface ApiFormalitesGetCollection200Response {
    /**
     * 
     * @type {Array<FormaliteJsonldFormaliteRead>}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:member': Array<FormaliteJsonldFormaliteRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormalitesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiFormalitesPost400Response
 */
export interface ApiFormalitesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiFormalitesPost400ResponseException}
     * @memberof ApiFormalitesPost400Response
     */
    'exception'?: ApiFormalitesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFormalitesPost400ResponseException
 */
export interface ApiFormalitesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFormalitesPost400ResponseException
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface ApiFormesJuridiquesGetCollection200Response
 */
export interface ApiFormesJuridiquesGetCollection200Response {
    /**
     * 
     * @type {Array<FormeJuridiqueJsonldFormejuridiqueRead>}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:member': Array<FormeJuridiqueJsonldFormejuridiqueRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiFormesJuridiquesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiGreffesGetCollection200Response
 */
export interface ApiGreffesGetCollection200Response {
    /**
     * 
     * @type {Array<GreffeJsonldGreffeRead>}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:member': Array<GreffeJsonldGreffeRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiGreffesGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiStatutsGetCollection200Response
 */
export interface ApiStatutsGetCollection200Response {
    /**
     * 
     * @type {Array<StatutJsonldStatutRead>}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:member': Array<StatutJsonldStatutRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiStatutsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiTypesDocDossierIdPut400Response
 */
export interface ApiTypesDocDossierIdPut400Response {
    /**
     * 
     * @type {ApiAcomptesIdPut400ResponseException}
     * @memberof ApiTypesDocDossierIdPut400Response
     */
    'error'?: ApiAcomptesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTypesDocDossierPost400Response
 */
export interface ApiTypesDocDossierPost400Response {
    /**
     * 
     * @type {ApiAcomptesPost400ResponseException}
     * @memberof ApiTypesDocDossierPost400Response
     */
    'error'?: ApiAcomptesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiTypesDocumentsGetCollection200Response
 */
export interface ApiTypesDocumentsGetCollection200Response {
    /**
     * 
     * @type {Array<TypeDeDocumentJsonldTypedocumentRead>}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:member': Array<TypeDeDocumentJsonldTypedocumentRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraView}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:view'?: ApiActionDetailsGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiActionDetailsGetCollection200ResponseHydraSearch}
     * @memberof ApiTypesDocumentsGetCollection200Response
     */
    'hydra:search'?: ApiActionDetailsGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface CallbackDematFacile
 */
export interface CallbackDematFacile {
    /**
     * type du callback
     * @type {string}
     * @memberof CallbackDematFacile
     */
    'type'?: string;
    /**
     * 
     * @type {CallbackRecu}
     * @memberof CallbackDematFacile
     */
    'dossier'?: CallbackRecu;
}
/**
 * 
 * @export
 * @interface CallbackDematFacileJsonld
 */
export interface CallbackDematFacileJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackDematFacileJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    '@type'?: string;
    /**
     * type du callback
     * @type {string}
     * @memberof CallbackDematFacileJsonld
     */
    'type'?: string;
    /**
     * 
     * @type {CallbackRecuJsonld}
     * @memberof CallbackDematFacileJsonld
     */
    'dossier'?: CallbackRecuJsonld;
}
/**
 * 
 * @export
 * @interface CallbackDocument
 */
export interface CallbackDocument {
    /**
     * identifiant du document
     * @type {number}
     * @memberof CallbackDocument
     */
    'id'?: number;
    /**
     * type du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'type'?: string;
    /**
     * contenu du document (en base64)
     * @type {string}
     * @memberof CallbackDocument
     */
    'fichier'?: string;
    /**
     * nom du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'nom'?: string;
    /**
     * cheksum du document
     * @type {string}
     * @memberof CallbackDocument
     */
    'somme_controle'?: string;
    /**
     * Identifiant inpi
     * @type {number}
     * @memberof CallbackDocument
     */
    'id_inpi'?: number;
}
/**
 * 
 * @export
 * @interface CallbackDocumentJsonld
 */
export interface CallbackDocumentJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackDocumentJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    '@type'?: string;
    /**
     * identifiant du document
     * @type {number}
     * @memberof CallbackDocumentJsonld
     */
    'id'?: number;
    /**
     * type du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'type'?: string;
    /**
     * contenu du document (en base64)
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'fichier'?: string;
    /**
     * nom du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'nom'?: string;
    /**
     * cheksum du document
     * @type {string}
     * @memberof CallbackDocumentJsonld
     */
    'somme_controle'?: string;
    /**
     * Identifiant inpi
     * @type {number}
     * @memberof CallbackDocumentJsonld
     */
    'id_inpi'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiement
 */
export interface CallbackPaiement {
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiement
     */
    'id'?: number;
    /**
     * 
     * @type {Array<CallbackPaiementCartRate>}
     * @memberof CallbackPaiement
     */
    'cartRates'?: Array<CallbackPaiementCartRate>;
    /**
     * Get the value of total
     * @type {number}
     * @memberof CallbackPaiement
     */
    'total'?: number;
    /**
     * Get the value of mipOrderNum
     * @type {number}
     * @memberof CallbackPaiement
     */
    'mipOrderNum'?: number;
    /**
     * Get the value of paymentDate
     * @type {string}
     * @memberof CallbackPaiement
     */
    'paymentDate'?: string;
    /**
     * Get the value of paymentType
     * @type {string}
     * @memberof CallbackPaiement
     */
    'paymentType'?: string;
    /**
     * Get the value of status
     * @type {string}
     * @memberof CallbackPaiement
     */
    'status'?: string;
    /**
     * Get the value of created
     * @type {string}
     * @memberof CallbackPaiement
     */
    'created'?: string;
    /**
     * Get the value of updated
     * @type {string}
     * @memberof CallbackPaiement
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementCartRate
 */
export interface CallbackPaiementCartRate {
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackPaiementRate}
     * @memberof CallbackPaiementCartRate
     */
    'rate'?: CallbackPaiementRate;
    /**
     * Get the value of quantity
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'quantity'?: number;
    /**
     * Get the value of amount
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'amount'?: number;
    /**
     * Get the value of isForcedManually
     * @type {boolean}
     * @memberof CallbackPaiementCartRate
     */
    'isForcedManually'?: boolean;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'htAmount'?: number;
    /**
     * Get the value of recipientCode
     * @type {string}
     * @memberof CallbackPaiementCartRate
     */
    'recipientCode'?: string | null;
    /**
     * Get the value of recipientName
     * @type {string}
     * @memberof CallbackPaiementCartRate
     */
    'recipientName'?: string | null;
    /**
     * Get the value of subTotal
     * @type {number}
     * @memberof CallbackPaiementCartRate
     */
    'subTotal'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiementCartRateJsonld
 */
export interface CallbackPaiementCartRateJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackPaiementRateJsonld}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'rate'?: CallbackPaiementRateJsonld;
    /**
     * Get the value of quantity
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'quantity'?: number;
    /**
     * Get the value of amount
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'amount'?: number;
    /**
     * Get the value of isForcedManually
     * @type {boolean}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'isForcedManually'?: boolean;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'htAmount'?: number;
    /**
     * Get the value of recipientCode
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'recipientCode'?: string | null;
    /**
     * Get the value of recipientName
     * @type {string}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'recipientName'?: string | null;
    /**
     * Get the value of subTotal
     * @type {number}
     * @memberof CallbackPaiementCartRateJsonld
     */
    'subTotal'?: number;
}
/**
 * 
 * @export
 * @interface CallbackPaiementJsonld
 */
export interface CallbackPaiementJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    '@type'?: string;
    /**
     * Get the value of id
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {Array<CallbackPaiementCartRateJsonld>}
     * @memberof CallbackPaiementJsonld
     */
    'cartRates'?: Array<CallbackPaiementCartRateJsonld>;
    /**
     * Get the value of total
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'total'?: number;
    /**
     * Get the value of mipOrderNum
     * @type {number}
     * @memberof CallbackPaiementJsonld
     */
    'mipOrderNum'?: number;
    /**
     * Get the value of paymentDate
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'paymentDate'?: string;
    /**
     * Get the value of paymentType
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'paymentType'?: string;
    /**
     * Get the value of status
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'status'?: string;
    /**
     * Get the value of created
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'created'?: string;
    /**
     * Get the value of updated
     * @type {string}
     * @memberof CallbackPaiementJsonld
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementRate
 */
export interface CallbackPaiementRate {
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementRate
     */
    'htAmount'?: number;
    /**
     * Get the value of code
     * @type {string}
     * @memberof CallbackPaiementRate
     */
    'code'?: string;
    /**
     * Get the value of label
     * @type {string}
     * @memberof CallbackPaiementRate
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface CallbackPaiementRateJsonld
 */
export interface CallbackPaiementRateJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackPaiementRateJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    '@type'?: string;
    /**
     * Get the value of htAmount
     * @type {number}
     * @memberof CallbackPaiementRateJsonld
     */
    'htAmount'?: number;
    /**
     * Get the value of code
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    'code'?: string;
    /**
     * Get the value of label
     * @type {string}
     * @memberof CallbackPaiementRateJsonld
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface CallbackRecu
 */
export interface CallbackRecu {
    /**
     * identifiant du dossier côté demat facile
     * @type {number}
     * @memberof CallbackRecu
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackDocument}
     * @memberof CallbackRecu
     */
    'documents'?: CallbackDocument;
    /**
     * 
     * @type {CallbackPaiement}
     * @memberof CallbackRecu
     */
    'paiement'?: CallbackPaiement;
    /**
     * 
     * @type {CallbackDocument}
     * @memberof CallbackRecu
     */
    'document'?: CallbackDocument;
}
/**
 * 
 * @export
 * @interface CallbackRecuJsonld
 */
export interface CallbackRecuJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CallbackRecuJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CallbackRecuJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackRecuJsonld
     */
    '@type'?: string;
    /**
     * identifiant du dossier côté demat facile
     * @type {number}
     * @memberof CallbackRecuJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {CallbackDocumentJsonld}
     * @memberof CallbackRecuJsonld
     */
    'documents'?: CallbackDocumentJsonld;
    /**
     * 
     * @type {CallbackPaiementJsonld}
     * @memberof CallbackRecuJsonld
     */
    'paiement'?: CallbackPaiementJsonld;
    /**
     * 
     * @type {CallbackDocumentJsonld}
     * @memberof CallbackRecuJsonld
     */
    'document'?: CallbackDocumentJsonld;
}
/**
 * 
 * @export
 * @interface CategorieCategorieCreate
 */
export interface CategorieCategorieCreate {
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieCreated
 */
export interface CategorieCategorieCreated {
    /**
     * 
     * @type {number}
     * @memberof CategorieCategorieCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieCreated
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieRead
 */
export interface CategorieCategorieRead {
    /**
     * 
     * @type {number}
     * @memberof CategorieCategorieRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieCategorieUpdate
 */
export interface CategorieCategorieUpdate {
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieCategorieUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieCreate
 */
export interface CategorieJsonldCategorieCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieCreated
 */
export interface CategorieJsonldCategorieCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldCategorieCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieCreated
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieRead
 */
export interface CategorieJsonldCategorieRead {
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof CategorieJsonldCategorieRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CategorieJsonldCategorieUpdate
 */
export interface CategorieJsonldCategorieUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategorieJsonldCategorieUpdate
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierRead
 */
export interface CollectionDeDossierCollectiondossierRead {
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'domaine'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'prescripteur'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'client'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'statut'?: object;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierRead
     */
    'societe'?: string;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierCollectiondossierUpdate
 */
export interface CollectionDeDossierCollectiondossierUpdate {
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'prescripteur'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierCollectiondossierUpdate
     */
    'client'?: string | null;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierRead
 */
export interface CollectionDeDossierJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'domaine'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'prescripteur'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'client'?: string | null;
    /**
     * 
     * @type {TblDefStatutDossierJsonldCollectiondossierRead}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'statut'?: TblDefStatutDossierJsonldCollectiondossierRead;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierRead
     */
    'societe'?: string;
}
/**
 * 
 * @export
 * @interface CollectionDeDossierJsonldCollectiondossierUpdate
 */
export interface CollectionDeDossierJsonldCollectiondossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'infoComp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'prescripteur'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierJsonldCollectiondossierUpdate
     */
    'client'?: string | null;
}
/**
 * 
 * @export
 * @interface DevisDevisRead
 */
export interface DevisDevisRead {
    /**
     * 
     * @type {number}
     * @memberof DevisDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisDevisRead
     */
    'totalTtc'?: string | null;
}
/**
 * 
 * @export
 * @interface DevisJsonldDevisRead
 */
export interface DevisJsonldDevisRead {
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DevisJsonldDevisRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof DevisJsonldDevisRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DevisJsonldDevisRead
     */
    'totalTtc'?: string | null;
}
/**
 * Créer une route get item pour un doc cette route a besoin d\'un id
 * @export
 * @interface DocumentGedJsonld
 */
export interface DocumentGedJsonld {
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    '@type'?: string;
    /**
     * identifiant du document
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'idDocument'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentGedJsonld
     */
    '_ged_visibilty'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'api_application_tag'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentGedJsonld
     */
    'fgDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'update'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'urlDoc'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGedJsonld
     */
    'filename'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof DocumentGedJsonld
     */
    'tags'?: { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface DomainesJsonldDomaineRead
 */
export interface DomainesJsonldDomaineRead {
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainesJsonldDomaineRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DomainesJsonldDomaineRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
}
/**
 * 
 * @export
 * @interface DossierDossierCreate
 */
export interface DossierDossierCreate {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierCreate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'client'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierDossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {Array<number>}
     * @memberof DossierDossierCreate
     */
    'idsFormaliteMultiple'?: Array<number>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierCreate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierDossierCreateJsonObject
 */
export interface DossierDossierCreateJsonObject {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_cap'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_rbe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_cdm'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_rpi'?: boolean;
    /**
     * 0: hors ressort, 1: même ressort
     * @type {boolean}
     * @memberof DossierDossierCreateJsonObject
     */
    'dos_res'?: boolean;
}
/**
 * 
 * @export
 * @interface DossierDossierRead
 */
export interface DossierDossierRead {
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierDossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierDossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'libelleFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierDossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof DossierDossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierDossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof DossierDossierRead
     */
    'client'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierDossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierDossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierDossierRead
     */
    'formaliste'?: number | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof DossierDossierRead
     */
    'formaliteMultiple'?: Array<object>;
    /**
     * Adresse de facturation du dossier
     * @type {Array<object>}
     * @memberof DossierDossierRead
     */
    'adresses'?: Array<object>;
    /**
     * 
     * @type {Array<FproDossierDocDossierRead>}
     * @memberof DossierDossierRead
     */
    'documents'?: Array<FproDossierDocDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierDossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierDossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierRead
     */
    'marque'?: number | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierRead
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierDossierUpdate
 */
export interface DossierDossierUpdate {
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierDossierUpdate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierDossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierDossierUpdate
     */
    'idFormeJuridique'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierDossierUpdate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierDossierUpdateStatut
 */
export interface DossierDossierUpdateStatut {
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierDossierUpdateStatut
     */
    'statut': number | null;
    /**
     * Statut du dossier pour le SIE
     * @type {number}
     * @memberof DossierDossierUpdateStatut
     */
    'idStatutSie'?: number;
    /**
     * Statut du dossier chez le greffe
     * @type {number}
     * @memberof DossierDossierUpdateStatut
     */
    'idStatutGreffe'?: number;
    /**
     * Statut du dossier d\'annonce légale
     * @type {number}
     * @memberof DossierDossierUpdateStatut
     */
    'idStatutAl'?: number;
}
/**
 * 
 * @export
 * @interface DossierJsonld
 */
export interface DossierJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierCreate
 */
export interface DossierJsonldDossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    '@type'?: string;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'contact'?: number | null;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierCreate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'origine'?: number | null;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'societeSiren'?: string | null;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'client'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierJsonldDossierCreate
     */
    'formaliste'?: number | null;
    /**
     * Identifiants des formalites
     * @type {Array<number>}
     * @memberof DossierJsonldDossierCreate
     */
    'idsFormaliteMultiple'?: Array<number>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierCreate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierRead
 */
export interface DossierJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'id'?: number;
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'societe'?: number;
    /**
     * Identifiant du prescripteur
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'prescripteur'?: number;
    /**
     * Identifiant du contact
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'libelleFormalite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'numero'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'demo'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateCreation'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateMaj'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeDenomination'?: string | null;
    /**
     * Forme juridique de la société
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeFormeJuridique'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ht'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'tva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'ttc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cheminDoc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierRead
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * 0: via gestion, 1: via client (Front), 2: via Web service
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'origine'?: number | null;
    /**
     * Greffe du dossier
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'greffe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'dateFinalise'?: string | null;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'cegidRegimetva'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'factureMultiple'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DossierJsonldDossierRead
     */
    'recuPaiement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idFacturemultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idDematfacile'?: number;
    /**
     * ID du client si renseigné
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'client'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureBrouillon'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factActiveSansAvoir'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'factureBrouillonMultiple'?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'idFormeJuridique'?: number | null;
    /**
     * Identifiant de la formalite
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'formalite'?: number | null;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'statut'?: number | null;
    /**
     * Identifiant du formaliste
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'formaliste'?: number | null;
    /**
     * 
     * @type {Array<FproRelFormaliteDossierJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'formaliteMultiple'?: Array<FproRelFormaliteDossierJsonldDossierRead>;
    /**
     * Adresse de facturation du dossier
     * @type {Array<FproAdresseFactJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'adresses'?: Array<FproAdresseFactJsonldDossierRead>;
    /**
     * 
     * @type {Array<FproDossierDocJsonldDossierRead>}
     * @memberof DossierJsonldDossierRead
     */
    'documents'?: Array<FproDossierDocJsonldDossierRead>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DossierJsonldDossierRead
     */
    'fichiers'?: Array<string>;
    /**
     * Identifiants des formalites
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'acompteAttendu'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'affaire'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierRead
     */
    'marque'?: number | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierRead
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierUpdate
 */
export interface DossierJsonldDossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    '@type'?: string;
    /**
     * Référence du tiers pour ce dossier (La référence sera notée sur la facture)
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeRef'?: string | null;
    /**
     * Dénomination de la société
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeDenomination'?: string | null;
    /**
     * 
     * @type {DossierDossierCreateJsonObject}
     * @memberof DossierJsonldDossierUpdate
     */
    'jsonObject'?: DossierDossierCreateJsonObject;
    /**
     * Identifiant du greffe
     * @type {number}
     * @memberof DossierJsonldDossierUpdate
     */
    'idGreffe'?: number;
    /**
     * Siren de la société liée à la formalité
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'societeSiren'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DossierJsonldDossierUpdate
     */
    'idFormeJuridique'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'affaire'?: string | null;
    /**
     * Identifiant de la collection
     * @type {string}
     * @memberof DossierJsonldDossierUpdate
     */
    'collection'?: string | null;
}
/**
 * 
 * @export
 * @interface DossierJsonldDossierUpdateStatut
 */
export interface DossierJsonldDossierUpdateStatut {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    '@type'?: string;
    /**
     * Statut du dossier
     * @type {number}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    'statut': number | null;
    /**
     * Statut du dossier pour le SIE
     * @type {number}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    'idStatutSie'?: number;
    /**
     * Statut du dossier chez le greffe
     * @type {number}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    'idStatutGreffe'?: number;
    /**
     * Statut du dossier d\'annonce légale
     * @type {number}
     * @memberof DossierJsonldDossierUpdateStatut
     */
    'idStatutAl'?: number;
}
/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileRead
 */
export interface FichiersDossierDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'expediteur'?: FichiersDossierDocumentfileReadExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'expedition'?: FichiersDossierDocumentfileReadExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocDocumentfileRead}
     * @memberof FichiersDossierDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadStatut}
     * @memberof FichiersDossierDocumentfileRead
     */
    'statut'?: FichiersDossierDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FichiersDossierDocumentfileRead
     */
    'docSigned'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FichiersDossierDocumentfileRead
     */
    'docOriginal'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadTypedocIADoc}
     * @memberof FichiersDossierDocumentfileRead
     */
    'typedocIADoc'?: FichiersDossierDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierDocumentfileReadExpediteurEnum = typeof FichiersDossierDocumentfileReadExpediteurEnum[keyof typeof FichiersDossierDocumentfileReadExpediteurEnum];
export const FichiersDossierDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierDocumentfileReadExpeditionEnum = typeof FichiersDossierDocumentfileReadExpeditionEnum[keyof typeof FichiersDossierDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileReadDocSigned
 */
export interface FichiersDossierDocumentfileReadDocSigned {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'expediteur'?: FichiersDossierDocumentfileReadDocSignedExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'expedition'?: FichiersDossierDocumentfileReadDocSignedExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocDocumentfileRead}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'typeDocDossier'?: FproDossierDocDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadStatut}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'statut'?: FichiersDossierDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'docSigned'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'docOriginal'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadTypedocIADoc}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'typedocIADoc'?: FichiersDossierDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadDocSigned
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierDocumentfileReadDocSignedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierDocumentfileReadDocSignedExpediteurEnum = typeof FichiersDossierDocumentfileReadDocSignedExpediteurEnum[keyof typeof FichiersDossierDocumentfileReadDocSignedExpediteurEnum];
export const FichiersDossierDocumentfileReadDocSignedExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierDocumentfileReadDocSignedExpeditionEnum = typeof FichiersDossierDocumentfileReadDocSignedExpeditionEnum[keyof typeof FichiersDossierDocumentfileReadDocSignedExpeditionEnum];

/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileReadStatut
 */
export interface FichiersDossierDocumentfileReadStatut {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadStatut
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileReadTypedocIADoc
 */
export interface FichiersDossierDocumentfileReadTypedocIADoc {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierDocumentfileReadTypedocIADoc
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface FichiersDossierDocumentfileUpdate
 */
export interface FichiersDossierDocumentfileUpdate {
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierDocumentfileUpdate
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FichiersDossierJsonldDocumentfileRead
 */
export interface FichiersDossierJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'expediteur'?: FichiersDossierJsonldDocumentfileReadExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'expedition'?: FichiersDossierJsonldDocumentfileReadExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocJsonldDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadStatut}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'statut'?: FichiersDossierJsonldDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'docSigned'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'docOriginal'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadTypedocIADoc}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'typedocIADoc'?: FichiersDossierJsonldDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierJsonldDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierJsonldDocumentfileReadExpediteurEnum = typeof FichiersDossierJsonldDocumentfileReadExpediteurEnum[keyof typeof FichiersDossierJsonldDocumentfileReadExpediteurEnum];
export const FichiersDossierJsonldDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierJsonldDocumentfileReadExpeditionEnum = typeof FichiersDossierJsonldDocumentfileReadExpeditionEnum[keyof typeof FichiersDossierJsonldDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FichiersDossierJsonldDocumentfileReadDocSigned
 */
export interface FichiersDossierJsonldDocumentfileReadDocSigned {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'expediteur'?: FichiersDossierJsonldDocumentfileReadDocSignedExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'expedition'?: FichiersDossierJsonldDocumentfileReadDocSignedExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocJsonldDocumentfileRead}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'typeDocDossier'?: FproDossierDocJsonldDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadStatut}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'statut'?: FichiersDossierJsonldDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'docSigned'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'docOriginal'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadTypedocIADoc}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'typedocIADoc'?: FichiersDossierJsonldDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadDocSigned
     */
    'jsonIADoc'?: string | null;
}

export const FichiersDossierJsonldDocumentfileReadDocSignedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FichiersDossierJsonldDocumentfileReadDocSignedExpediteurEnum = typeof FichiersDossierJsonldDocumentfileReadDocSignedExpediteurEnum[keyof typeof FichiersDossierJsonldDocumentfileReadDocSignedExpediteurEnum];
export const FichiersDossierJsonldDocumentfileReadDocSignedExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FichiersDossierJsonldDocumentfileReadDocSignedExpeditionEnum = typeof FichiersDossierJsonldDocumentfileReadDocSignedExpeditionEnum[keyof typeof FichiersDossierJsonldDocumentfileReadDocSignedExpeditionEnum];

/**
 * 
 * @export
 * @interface FichiersDossierJsonldDocumentfileReadStatut
 */
export interface FichiersDossierJsonldDocumentfileReadStatut {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadStatut
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FichiersDossierJsonldDocumentfileReadTypedocIADoc
 */
export interface FichiersDossierJsonldDocumentfileReadTypedocIADoc {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierJsonldDocumentfileReadTypedocIADoc
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface FormalisteFormalisteRead
 */
export interface FormalisteFormalisteRead {
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteFormalisteRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDemo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistAdmin'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitGestMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitGestLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'fmistDroitDosSup'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'fmistFormaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteFormalisteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteFormalisteRead
     */
    'accesFpro'?: boolean;
}
/**
 * 
 * @export
 * @interface FormalisteJsonldFormalisteRead
 */
export interface FormalisteJsonldFormalisteRead {
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormalisteJsonldFormalisteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'nom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'prenom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'initiales'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'mail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDemo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistAdmin'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitGestMod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitGestLect'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistDroitDosSup'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'fmistFormaliste'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormalisteJsonldFormalisteRead
     */
    'accesFpro'?: boolean;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteCreate
 */
export interface FormaliteFormaliteCreate {
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreate
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteCreated
 */
export interface FormaliteFormaliteCreated {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteCreated
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteCreated
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteRead
 */
export interface FormaliteFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {FormaliteFormaliteReadCategorie}
     * @memberof FormaliteFormaliteRead
     */
    'categorie'?: FormaliteFormaliteReadCategorie | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteRead
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteRead
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteReadCategorie
 */
export interface FormaliteFormaliteReadCategorie {
    /**
     * 
     * @type {number}
     * @memberof FormaliteFormaliteReadCategorie
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteReadCategorie
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteReadCategorie
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteReadCategorie
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteFormaliteUpdate
 */
export interface FormaliteFormaliteUpdate {
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteFormaliteUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteFormaliteUpdate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteFormaliteUpdate
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteCreate
 */
export interface FormaliteJsonldFormaliteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreate
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteCreated
 */
export interface FormaliteJsonldFormaliteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteCreated
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteRead
 */
export interface FormaliteJsonldFormaliteRead {
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {FormaliteJsonldFormaliteReadCategorie}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'categorie'?: FormaliteJsonldFormaliteReadCategorie | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteRead
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteReadCategorie
 */
export interface FormaliteJsonldFormaliteReadCategorie {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteReadCategorie
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FormaliteJsonldFormaliteUpdate
 */
export interface FormaliteJsonldFormaliteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'categorie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'informationsSupplementaires'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'documents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'legalStatuses'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'compatibilities'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'iconeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormaliteJsonldFormaliteUpdate
     */
    'formulaire'?: string | null;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueFormejuridiqueRead
 */
export interface FormeJuridiqueFormejuridiqueRead {
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'abreviation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'dirigeant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'dirigeance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fObjetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fCapvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fAgrement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fSci'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'nomenclature'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'idparent'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueFormejuridiqueRead
     */
    'fAfficheform'?: boolean;
}
/**
 * 
 * @export
 * @interface FormeJuridiqueJsonldFormejuridiqueRead
 */
export interface FormeJuridiqueJsonldFormejuridiqueRead {
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'abreviation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'dirigeant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'dirigeance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fObjetDuree'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fCapvar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fAgrement'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fSci'?: number;
    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'nomenclature'?: string;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'rang'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'idparent'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormeJuridiqueJsonldFormejuridiqueRead
     */
    'fAfficheform'?: boolean;
}
/**
 * 
 * @export
 * @interface FproAdresseFactJsonldDossierRead
 */
export interface FproAdresseFactJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproAdresseFactJsonldDossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface FproDosDocFileDocumentfileRead
 */
export interface FproDosDocFileDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'expediteur'?: FproDosDocFileDocumentfileReadExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'expedition'?: FproDosDocFileDocumentfileReadExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocDocumentfileRead}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadStatut}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'statut'?: FichiersDossierDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'docSigned'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadDocSigned}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'docOriginal'?: FichiersDossierDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadTypedocIADoc}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'typedocIADoc'?: FichiersDossierDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FproDosDocFileDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FproDosDocFileDocumentfileReadExpediteurEnum = typeof FproDosDocFileDocumentfileReadExpediteurEnum[keyof typeof FproDosDocFileDocumentfileReadExpediteurEnum];
export const FproDosDocFileDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FproDosDocFileDocumentfileReadExpeditionEnum = typeof FproDosDocFileDocumentfileReadExpeditionEnum[keyof typeof FproDosDocFileDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FproDosDocFileJsonldDocumentfileRead
 */
export interface FproDosDocFileJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'contentUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'urlExpires'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'fichier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'chemin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'idGed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'dateAjout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'dateUpdate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'expediteur'?: FproDosDocFileJsonldDocumentfileReadExpediteurEnum;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'expedition'?: FproDosDocFileJsonldDocumentfileReadExpeditionEnum;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'idDocDf'?: number;
    /**
     * 
     * @type {number}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'signed'?: number;
    /**
     * 
     * @type {FproDossierDocJsonldDocumentfileRead}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'typeDocDossier'?: FproDossierDocJsonldDocumentfileRead;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadStatut}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'statut'?: FichiersDossierJsonldDocumentfileReadStatut | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'docSigned'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadDocSigned}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'docOriginal'?: FichiersDossierJsonldDocumentfileReadDocSigned | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadTypedocIADoc}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'typedocIADoc'?: FichiersDossierJsonldDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosDocFileJsonldDocumentfileRead
     */
    'jsonIADoc'?: string | null;
}

export const FproDosDocFileJsonldDocumentfileReadExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FproDosDocFileJsonldDocumentfileReadExpediteurEnum = typeof FproDosDocFileJsonldDocumentfileReadExpediteurEnum[keyof typeof FproDosDocFileJsonldDocumentfileReadExpediteurEnum];
export const FproDosDocFileJsonldDocumentfileReadExpeditionEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FproDosDocFileJsonldDocumentfileReadExpeditionEnum = typeof FproDosDocFileJsonldDocumentfileReadExpeditionEnum[keyof typeof FproDosDocFileJsonldDocumentfileReadExpeditionEnum];

/**
 * 
 * @export
 * @interface FproDosStatutDocumentfileRead
 */
export interface FproDosStatutDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutDocumentfileRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FproDosStatutDocumentfileRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutDocumentfileRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutDocumentfileRead
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FproDosStatutJsonldDocumentfileRead
 */
export interface FproDosStatutJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FproDosStatutJsonldDocumentfileRead
     */
    'affichefront'?: boolean;
}
/**
 * 
 * @export
 * @interface FproDossierActiviteCreated
 */
export interface FproDossierActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof FproDossierActiviteCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierActiviteRead
 */
export interface FproDossierActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierActiviteRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierDocDocumentfileRead
 */
export interface FproDossierDocDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDocumentfileRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadTypedocIADoc}
     * @memberof FproDossierDocDocumentfileRead
     */
    'document'?: FichiersDossierDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {FichiersDossierDocumentfileReadStatut}
     * @memberof FproDossierDocDocumentfileRead
     */
    'statut'?: FichiersDossierDocumentfileReadStatut | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocDossierRead
 */
export interface FproDossierDocDossierRead {
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocDossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocJsonldDocumentfileRead
 */
export interface FproDossierDocJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadTypedocIADoc}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'document'?: FichiersDossierJsonldDocumentfileReadTypedocIADoc | null;
    /**
     * 
     * @type {FichiersDossierJsonldDocumentfileReadStatut}
     * @memberof FproDossierDocJsonldDocumentfileRead
     */
    'statut'?: FichiersDossierJsonldDocumentfileReadStatut | null;
}
/**
 * 
 * @export
 * @interface FproDossierDocJsonldDossierRead
 */
export interface FproDossierDocJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproDossierDocJsonldDossierRead
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldActiviteCreated
 */
export interface FproDossierJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldActiviteCreated
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproDossierJsonldActiviteRead
 */
export interface FproDossierJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproDossierJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproDossierJsonldActiviteRead
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FproFormaliteCategorieFormaliteRead
 */
export interface FproFormaliteCategorieFormaliteRead {
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproFormaliteCategorieJsonldFormaliteRead
 */
export interface FproFormaliteCategorieJsonldFormaliteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'libelleDematFacile'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproFormaliteCategorieJsonldFormaliteRead
     */
    'iconeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FproRelFormaliteDossierJsonldDossierRead
 */
export interface FproRelFormaliteDossierJsonldDossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof FproRelFormaliteDossierJsonldDossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof FproRelFormaliteDossierJsonldDossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FproRelFormaliteDossierJsonldDossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface GreffeGreffeRead
 */
export interface GreffeGreffeRead {
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'art'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeGreffeRead
     */
    'dep'?: string;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefEp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeGreffeRead
     */
    'prefGen'?: number | null;
}
/**
 * 
 * @export
 * @interface GreffeJsonldGreffeRead
 */
export interface GreffeJsonldGreffeRead {
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof GreffeJsonldGreffeRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'greffes'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'art'?: string;
    /**
     * 
     * @type {string}
     * @memberof GreffeJsonldGreffeRead
     */
    'dep'?: string;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefAp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefEp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GreffeJsonldGreffeRead
     */
    'prefGen'?: number | null;
}
/**
 * 
 * @export
 * @interface MessageJsonld
 */
export interface MessageJsonld {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonld
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface MessageJsonldMessageCreate
 */
export interface MessageJsonldMessageCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreate
     */
    'expediteur': MessageJsonldMessageCreateExpediteurEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreate
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreate
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreate
     */
    'dossier'?: string | null;
}

export const MessageJsonldMessageCreateExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageJsonldMessageCreateExpediteurEnum = typeof MessageJsonldMessageCreateExpediteurEnum[keyof typeof MessageJsonldMessageCreateExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageJsonldMessageCreated
 */
export interface MessageJsonldMessageCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageCreated
     */
    'expediteur': MessageJsonldMessageCreatedExpediteurEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageCreated
     */
    'dossier'?: string | null;
}

export const MessageJsonldMessageCreatedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageJsonldMessageCreatedExpediteurEnum = typeof MessageJsonldMessageCreatedExpediteurEnum[keyof typeof MessageJsonldMessageCreatedExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageJsonldMessageRead
 */
export interface MessageJsonldMessageRead {
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof MessageJsonldMessageRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof MessageJsonldMessageRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof MessageJsonldMessageRead
     */
    'expediteur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'expediteurNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageJsonldMessageRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageJsonldMessageRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageMessageCreate
 */
export interface MessageMessageCreate {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreate
     */
    'expediteur': MessageMessageCreateExpediteurEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreate
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreate
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreate
     */
    'dossier'?: string | null;
}

export const MessageMessageCreateExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageMessageCreateExpediteurEnum = typeof MessageMessageCreateExpediteurEnum[keyof typeof MessageMessageCreateExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageMessageCreated
 */
export interface MessageMessageCreated {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageMessageCreated
     */
    'expediteur': MessageMessageCreatedExpediteurEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'expediteurNom': string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'message': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageCreated
     */
    'dossier'?: string | null;
}

export const MessageMessageCreatedExpediteurEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MessageMessageCreatedExpediteurEnum = typeof MessageMessageCreatedExpediteurEnum[keyof typeof MessageMessageCreatedExpediteurEnum];

/**
 * 
 * @export
 * @interface MessageMessageRead
 */
export interface MessageMessageRead {
    /**
     * 
     * @type {number}
     * @memberof MessageMessageRead
     */
    'id'?: number;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof MessageMessageRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof MessageMessageRead
     */
    'expediteur'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'expediteurNom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'readByPrescri'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'readByFrmlist'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageMessageRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MessageMessageRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteCreate
 */
export interface NotesEtRappelsJsonldNoteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteCreated
 */
export interface NotesEtRappelsJsonldNoteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteRead
 */
export interface NotesEtRappelsJsonldNoteRead {
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateProgramme'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateCreation'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsJsonldNoteRead
     */
    'dateProgrammeFin'?: Datetime | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoteUpdate
 */
export interface NotesEtRappelsJsonldNoteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoteUpdate
     */
    'userCreation'?: number;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelCreate
 */
export interface NotesEtRappelsJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreate}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'evenement'?: TblEvenementJsonldNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelCreated
 */
export interface NotesEtRappelsJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreated}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'evenement'?: TblEvenementJsonldNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelRead
 */
export interface NotesEtRappelsJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelRead}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'evenement'?: TblEvenementJsonldNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelReadCollection
 */
export interface NotesEtRappelsJsonldNoterappelReadCollection {
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelReadCollection>}
     * @memberof NotesEtRappelsJsonldNoterappelReadCollection
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelReadCollection>;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsJsonldNoterappelUpdate
 */
export interface NotesEtRappelsJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelUpdate}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'evenement'?: TblEvenementJsonldNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsJsonldNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteCreate
 */
export interface NotesEtRappelsNoteCreate {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreate
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteCreated
 */
export interface NotesEtRappelsNoteCreated {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreated
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteCreated
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteCreated
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteCreated
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteRead
 */
export interface NotesEtRappelsNoteRead {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateProgramme'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteRead
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteRead
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteRead
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateCreation'?: Datetime | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteRead
     */
    'userCreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Datetime}
     * @memberof NotesEtRappelsNoteRead
     */
    'dateProgrammeFin'?: Datetime | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoteUpdate
 */
export interface NotesEtRappelsNoteUpdate {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'idPrescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'userProgramme'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'clos'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoteUpdate
     */
    'userCreation'?: number;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelCreate
 */
export interface NotesEtRappelsNoterappelCreate {
    /**
     * 
     * @type {TblEvenementNoterappelCreate}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'evenement'?: TblEvenementNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelCreated
 */
export interface NotesEtRappelsNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelCreated}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'evenement'?: TblEvenementNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelRead
 */
export interface NotesEtRappelsNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelRead}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'evenement'?: TblEvenementNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelReadCollection
 */
export interface NotesEtRappelsNoterappelReadCollection {
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelReadCollection>}
     * @memberof NotesEtRappelsNoterappelReadCollection
     */
    'rappels'?: Array<TblEvenementRappelNoterappelReadCollection>;
}
/**
 * 
 * @export
 * @interface NotesEtRappelsNoterappelUpdate
 */
export interface NotesEtRappelsNoterappelUpdate {
    /**
     * 
     * @type {TblEvenementNoterappelUpdate}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'evenement'?: TblEvenementNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotesEtRappelsNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteCreate
 */
export interface ReglementsEtAcomptesCBAcompteCreate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreate
     */
    'origine'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteCreated
 */
export interface ReglementsEtAcomptesCBAcompteCreated {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteCreated
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteRead
 */
export interface ReglementsEtAcomptesCBAcompteRead {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteRead
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBAcompteUpdate
 */
export interface ReglementsEtAcomptesCBAcompteUpdate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBAcompteUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteCreate
 */
export interface ReglementsEtAcomptesCBJsonldAcompteCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreate
     */
    'origine'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteCreated
 */
export interface ReglementsEtAcomptesCBJsonldAcompteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteCreated
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteRead
 */
export interface ReglementsEtAcomptesCBJsonldAcompteRead {
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteRead
     */
    'dremisebanque'?: string | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldAcompteUpdate
 */
export interface ReglementsEtAcomptesCBJsonldAcompteUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldAcompteUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementCreate
 */
export interface ReglementsEtAcomptesCBJsonldReglementCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementCreated
 */
export interface ReglementsEtAcomptesCBJsonldReglementCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementCreated
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementRead
 */
export interface ReglementsEtAcomptesCBJsonldReglementRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementRead
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBJsonldReglementUpdate
 */
export interface ReglementsEtAcomptesCBJsonldReglementUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBJsonldReglementUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementCreate
 */
export interface ReglementsEtAcomptesCBReglementCreate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'origine'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementCreated
 */
export interface ReglementsEtAcomptesCBReglementCreated {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementCreated
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementRead
 */
export interface ReglementsEtAcomptesCBReglementRead {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'mode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dreception'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'flgexport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dexport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'datecreation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'fComptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dexportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'origine'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'dremisebanque'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementRead
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface ReglementsEtAcomptesCBReglementUpdate
 */
export interface ReglementsEtAcomptesCBReglementUpdate {
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'tiers'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'transactioncb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'montant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'mode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'usercreation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReglementsEtAcomptesCBReglementUpdate
     */
    'idfacture'?: number | null;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutCreate
 */
export interface StatutJsonldStatutCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    '@type'?: string;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutCreate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutCreated
 */
export interface StatutJsonldStatutCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatutJsonldStatutCreated
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutCreated
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutCreated
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutRead
 */
export interface StatutJsonldStatutRead {
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof StatutJsonldStatutRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutRead
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutJsonldStatutUpdate
 */
export interface StatutJsonldStatutUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof StatutJsonldStatutUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    '@type'?: string;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutJsonldStatutUpdate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutJsonldStatutUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutJsonldStatutUpdate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutCreate
 */
export interface StatutStatutCreate {
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutCreate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutCreated
 */
export interface StatutStatutCreated {
    /**
     * 
     * @type {number}
     * @memberof StatutStatutCreated
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutCreated
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutCreated
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutRead
 */
export interface StatutStatutRead {
    /**
     * 
     * @type {number}
     * @memberof StatutStatutRead
     */
    'id'?: number;
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutRead
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'class'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutRead
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface StatutStatutUpdate
 */
export interface StatutStatutUpdate {
    /**
     * 1:Statut d\'un dossier - 2:Statut d\'un article - 3:Statut d\'un dossier niveau impôt - 4:Statut d\'un dossier niveau greffe - 5:Statut d\'un dossier niveau annonce légale
     * @type {number}
     * @memberof StatutStatutUpdate
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'class': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'pardefaut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof StatutStatutUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'cacheFront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'supprimable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'affichefront'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutStatutUpdate
     */
    'maildossencours'?: boolean;
}
/**
 * 
 * @export
 * @interface TblActionDetailActionCreated
 */
export interface TblActionDetailActionCreated {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActionCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActionRead
 */
export interface TblActionDetailActionRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActiviteCreated
 */
export interface TblActionDetailActiviteCreated {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActiviteCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailActiviteRead
 */
export interface TblActionDetailActiviteRead {
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailActiviteRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActionCreated
 */
export interface TblActionDetailJsonldActionCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActionCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActionRead
 */
export interface TblActionDetailJsonldActionRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActionRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActionRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActiviteCreated
 */
export interface TblActionDetailJsonldActiviteCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteCreated
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblActionDetailJsonldActiviteRead
 */
export interface TblActionDetailJsonldActiviteRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblActionDetailJsonldActiviteRead
     */
    'libelle'?: string | null;
}
/**
 * 
 * @export
 * @interface TblDefStatutDossierJsonldCollectiondossierRead
 */
export interface TblDefStatutDossierJsonldCollectiondossierRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblDefStatutDossierJsonldCollectiondossierRead
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelCreate
 */
export interface TblEvenementJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelCreate>}
     * @memberof TblEvenementJsonldNoterappelCreate
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelCreate>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelCreated
 */
export interface TblEvenementJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelCreated>}
     * @memberof TblEvenementJsonldNoterappelCreated
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelCreated>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelRead
 */
export interface TblEvenementJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelRead>}
     * @memberof TblEvenementJsonldNoterappelRead
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelRead>;
}
/**
 * 
 * @export
 * @interface TblEvenementJsonldNoterappelUpdate
 */
export interface TblEvenementJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelJsonldNoterappelUpdate>}
     * @memberof TblEvenementJsonldNoterappelUpdate
     */
    'rappels'?: Array<TblEvenementRappelJsonldNoterappelUpdate>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelCreate
 */
export interface TblEvenementNoterappelCreate {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelCreate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelCreate>}
     * @memberof TblEvenementNoterappelCreate
     */
    'rappels'?: Array<TblEvenementRappelNoterappelCreate>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelCreated
 */
export interface TblEvenementNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelCreated
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelCreated>}
     * @memberof TblEvenementNoterappelCreated
     */
    'rappels'?: Array<TblEvenementRappelNoterappelCreated>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelRead
 */
export interface TblEvenementNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelRead
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelRead>}
     * @memberof TblEvenementNoterappelRead
     */
    'rappels'?: Array<TblEvenementRappelNoterappelRead>;
}
/**
 * 
 * @export
 * @interface TblEvenementNoterappelUpdate
 */
export interface TblEvenementNoterappelUpdate {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementNoterappelUpdate
     */
    'societe'?: number;
    /**
     * 
     * @type {Array<TblEvenementRappelNoterappelUpdate>}
     * @memberof TblEvenementNoterappelUpdate
     */
    'rappels'?: Array<TblEvenementRappelNoterappelUpdate>;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelCreate
 */
export interface TblEvenementRappelJsonldNoterappelCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreate}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'evenement'?: TblEvenementJsonldNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelCreated
 */
export interface TblEvenementRappelJsonldNoterappelCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelCreated}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'evenement'?: TblEvenementJsonldNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelRead
 */
export interface TblEvenementRappelJsonldNoterappelRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelRead}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'evenement'?: TblEvenementJsonldNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelReadCollection
 */
export interface TblEvenementRappelJsonldNoterappelReadCollection {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelReadCollection
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelJsonldNoterappelUpdate
 */
export interface TblEvenementRappelJsonldNoterappelUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {TblEvenementJsonldNoterappelUpdate}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'evenement'?: TblEvenementJsonldNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelJsonldNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelCreate
 */
export interface TblEvenementRappelNoterappelCreate {
    /**
     * 
     * @type {TblEvenementNoterappelCreate}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'evenement'?: TblEvenementNoterappelCreate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelCreated
 */
export interface TblEvenementRappelNoterappelCreated {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelCreated}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'evenement'?: TblEvenementNoterappelCreated;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelCreated
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelRead
 */
export interface TblEvenementRappelNoterappelRead {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'id'?: number;
    /**
     * 
     * @type {TblEvenementNoterappelRead}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'evenement'?: TblEvenementNoterappelRead;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelRead
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelReadCollection
 */
export interface TblEvenementRappelNoterappelReadCollection {
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'closed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelReadCollection
     */
    'deleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblEvenementRappelNoterappelUpdate
 */
export interface TblEvenementRappelNoterappelUpdate {
    /**
     * 
     * @type {TblEvenementNoterappelUpdate}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'evenement'?: TblEvenementNoterappelUpdate;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'idPrescripteur'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'actionDetail'?: number | null;
    /**
     * 
     * @type {EuroprocurementApiFormaliteEntityG3Datetime}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'date'?: EuroprocurementApiFormaliteEntityG3Datetime | null;
    /**
     * 
     * @type {string}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'motif'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'idUser'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblEvenementRappelNoterappelUpdate
     */
    'closed'?: boolean | null;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentDocumentfileRead
 */
export interface TblTypeDocumentDocumentfileRead {
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentDocumentfileRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TblTypeDocumentJsonldDocumentfileRead
 */
export interface TblTypeDocumentJsonldDocumentfileRead {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblTypeDocumentJsonldDocumentfileRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
 */
export interface TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierCreate
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierCreate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreate
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierCreated
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierCreated {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierCreated
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierRead
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierRead {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'statut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierRead
     */
    'dossier'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentDesDossiersTypedocumentdossierUpdate
 */
export interface TypeDeDocumentDesDossiersTypedocumentdossierUpdate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'noteFormaliste'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'document'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersTypedocumentdossierUpdate
     */
    'statut'?: string | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreate
 */
export interface TypeDeDocumentJsonldTypedocumentCreate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreate
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentCreated
 */
export interface TypeDeDocumentJsonldTypedocumentCreated {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentCreated
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentRead
 */
export interface TypeDeDocumentJsonldTypedocumentRead {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@type'?: string;
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentJsonldTypedocumentRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentJsonldTypedocumentUpdate
 */
export interface TypeDeDocumentJsonldTypedocumentUpdate {
    /**
     * 
     * @type {ActionDetailJsonldActionDetailReadContext}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@context'?: ActionDetailJsonldActionDetailReadContext;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentJsonldTypedocumentUpdate
     */
    'statut'?: number | null;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreate
 */
export interface TypeDeDocumentTypedocumentCreate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreate
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentCreated
 */
export interface TypeDeDocumentTypedocumentCreated {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentCreated
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentRead
 */
export interface TypeDeDocumentTypedocumentRead {
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'type'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'vide'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'statut'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'signable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'domaine'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeDeDocumentTypedocumentRead
     */
    'typageAuto'?: boolean;
}
/**
 * 
 * @export
 * @interface TypeDeDocumentTypedocumentUpdate
 */
export interface TypeDeDocumentTypedocumentUpdate {
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'help'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'fichierHelp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'libelleDF'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentTypedocumentUpdate
     */
    'statut'?: number | null;
}

/**
 * AcomptesApi - axios parameter creator
 * @export
 */
export const AcomptesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAcomptesIdGet', 'id', id)
            const localVarPath = `/formalite/acomptes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldAcompteUpdate} reglementsEtAcomptesCBJsonldAcompteUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdPut: async (id: string, reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAcomptesIdPut', 'id', id)
            // verify required parameter 'reglementsEtAcomptesCBJsonldAcompteUpdate' is not null or undefined
            assertParamExists('apiAcomptesIdPut', 'reglementsEtAcomptesCBJsonldAcompteUpdate', reglementsEtAcomptesCBJsonldAcompteUpdate)
            const localVarPath = `/formalite/acomptes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldAcompteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {ReglementsEtAcomptesCBJsonldAcompteCreate} reglementsEtAcomptesCBJsonldAcompteCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesPost: async (reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reglementsEtAcomptesCBJsonldAcompteCreate' is not null or undefined
            assertParamExists('apiAcomptesPost', 'reglementsEtAcomptesCBJsonldAcompteCreate', reglementsEtAcomptesCBJsonldAcompteCreate)
            const localVarPath = `/formalite/acomptes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldAcompteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierAcomptesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierAcomptesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/acomptes`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AcomptesApi - functional programming interface
 * @export
 */
export const AcomptesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AcomptesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldAcompteUpdate} reglementsEtAcomptesCBJsonldAcompteUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesIdPut(id: string, reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesIdPut(id, reglementsEtAcomptesCBJsonldAcompteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {ReglementsEtAcomptesCBJsonldAcompteCreate} reglementsEtAcomptesCBJsonldAcompteCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAcomptesPost(reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAcomptesPost(reglementsEtAcomptesCBJsonldAcompteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierAcomptesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierAcomptesGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AcomptesApi - factory interface
 * @export
 */
export const AcomptesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AcomptesApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'un acompte
         * @summary Récupérer les informations d\'un acompte
         * @param {AcomptesApiApiAcomptesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdGet(requestParameters: AcomptesApiApiAcomptesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiAcomptesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un acompte
         * @param {AcomptesApiApiAcomptesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesIdPut(requestParameters: AcomptesApiApiAcomptesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiAcomptesIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldAcompteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un acompte
         * @param {AcomptesApiApiAcomptesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAcomptesPost(requestParameters: AcomptesApiApiAcomptesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteCreated> {
            return localVarFp.apiAcomptesPost(requestParameters.reglementsEtAcomptesCBJsonldAcompteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des acomptes
         * @summary Obtenir la liste des acomptes
         * @param {AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierAcomptesGetCollection(requestParameters: AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response> {
            return localVarFp.apiDossiersDossierAcomptesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiAcomptesIdGet operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesIdGetRequest
 */
export interface AcomptesApiApiAcomptesIdGetRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof AcomptesApiApiAcomptesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiAcomptesIdPut operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesIdPutRequest
 */
export interface AcomptesApiApiAcomptesIdPutRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof AcomptesApiApiAcomptesIdPut
     */
    readonly id: string

    /**
     * The updated Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldAcompteUpdate}
     * @memberof AcomptesApiApiAcomptesIdPut
     */
    readonly reglementsEtAcomptesCBJsonldAcompteUpdate: ReglementsEtAcomptesCBJsonldAcompteUpdate
}

/**
 * Request parameters for apiAcomptesPost operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiAcomptesPostRequest
 */
export interface AcomptesApiApiAcomptesPostRequest {
    /**
     * The new Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldAcompteCreate}
     * @memberof AcomptesApiApiAcomptesPost
     */
    readonly reglementsEtAcomptesCBJsonldAcompteCreate: ReglementsEtAcomptesCBJsonldAcompteCreate
}

/**
 * Request parameters for apiDossiersDossierAcomptesGetCollection operation in AcomptesApi.
 * @export
 * @interface AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest
 */
export interface AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AcomptesApiApiDossiersDossierAcomptesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * AcomptesApi - object-oriented interface
 * @export
 * @class AcomptesApi
 * @extends {BaseAPI}
 */
export class AcomptesApi extends BaseAPI {
    /**
     * Récupérer les informations d\'un acompte
     * @summary Récupérer les informations d\'un acompte
     * @param {AcomptesApiApiAcomptesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesIdGet(requestParameters: AcomptesApiApiAcomptesIdGetRequest, options?: AxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Reglements et acomptes CB resource.
     * @summary Modifier un acompte
     * @param {AcomptesApiApiAcomptesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesIdPut(requestParameters: AcomptesApiApiAcomptesIdPutRequest, options?: AxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldAcompteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Reglements et acomptes CB resource.
     * @summary Créer un acompte
     * @param {AcomptesApiApiAcomptesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiAcomptesPost(requestParameters: AcomptesApiApiAcomptesPostRequest, options?: AxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiAcomptesPost(requestParameters.reglementsEtAcomptesCBJsonldAcompteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des acomptes
     * @summary Obtenir la liste des acomptes
     * @param {AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AcomptesApi
     */
    public apiDossiersDossierAcomptesGetCollection(requestParameters: AcomptesApiApiDossiersDossierAcomptesGetCollectionRequest, options?: AxiosRequestConfig) {
        return AcomptesApiFp(this.configuration).apiDossiersDossierAcomptesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActionDetailApi - axios parameter creator
 * @export
 */
export const ActionDetailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/action-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionDetailsIdGet', 'id', id)
            const localVarPath = `/formalite/action-details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionDetailApi - functional programming interface
 * @export
 */
export const ActionDetailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionDetailApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiActionDetailsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsGetCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {string} id TblActionDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionDetailsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionDetailJsonldActionDetailRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionDetailsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionDetailApi - factory interface
 * @export
 */
export const ActionDetailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionDetailApiFp(configuration)
    return {
        /**
         * Récupérer la liste des action-details
         * @summary Récupérer la liste des action-details
         * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiActionDetailsGetCollection200Response> {
            return localVarFp.apiActionDetailsGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une action-detail
         * @summary Récupérer les informations d\'une action-detail
         * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ActionDetailJsonldActionDetailRead> {
            return localVarFp.apiActionDetailsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActionDetailsGetCollection operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsGetCollectionRequest
 */
export interface ActionDetailApiApiActionDetailsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionDetailApiApiActionDetailsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiActionDetailsIdGet operation in ActionDetailApi.
 * @export
 * @interface ActionDetailApiApiActionDetailsIdGetRequest
 */
export interface ActionDetailApiApiActionDetailsIdGetRequest {
    /**
     * TblActionDetail identifier
     * @type {string}
     * @memberof ActionDetailApiApiActionDetailsIdGet
     */
    readonly id: string
}

/**
 * ActionDetailApi - object-oriented interface
 * @export
 * @class ActionDetailApi
 * @extends {BaseAPI}
 */
export class ActionDetailApi extends BaseAPI {
    /**
     * Récupérer la liste des action-details
     * @summary Récupérer la liste des action-details
     * @param {ActionDetailApiApiActionDetailsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsGetCollection(requestParameters: ActionDetailApiApiActionDetailsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une action-detail
     * @summary Récupérer les informations d\'une action-detail
     * @param {ActionDetailApiApiActionDetailsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionDetailApi
     */
    public apiActionDetailsIdGet(requestParameters: ActionDetailApiApiActionDetailsIdGetRequest, options?: AxiosRequestConfig) {
        return ActionDetailApiFp(this.configuration).apiActionDetailsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActionsApi - axios parameter creator
 * @export
 */
export const ActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionsIdGet', 'id', id)
            const localVarPath = `/formalite/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActionUpdate} actionsEtActivitSJsonldActionUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdPut: async (id: string, actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActionsIdPut', 'id', id)
            // verify required parameter 'actionsEtActivitSJsonldActionUpdate' is not null or undefined
            assertParamExists('apiActionsIdPut', 'actionsEtActivitSJsonldActionUpdate', actionsEtActivitSJsonldActionUpdate)
            const localVarPath = `/formalite/actions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActionUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsEtActivitSJsonldActionCreate} actionsEtActivitSJsonldActionCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsPost: async (actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionsEtActivitSJsonldActionCreate' is not null or undefined
            assertParamExists('apiActionsPost', 'actionsEtActivitSJsonldActionCreate', actionsEtActivitSJsonldActionCreate)
            const localVarPath = `/formalite/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActionsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierActionsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/actions`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionsApi - functional programming interface
 * @export
 */
export const ActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActionUpdate} actionsEtActivitSJsonldActionUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsIdPut(id: string, actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsIdPut(id, actionsEtActivitSJsonldActionUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsEtActivitSJsonldActionCreate} actionsEtActivitSJsonldActionCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActionsPost(actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActionsPost(actionsEtActivitSJsonldActionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierActionsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierActionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierActionsGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionsApi - factory interface
 * @export
 */
export const ActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionsApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une action
         * @summary Récupérer les informations d\'une action
         * @param {ActionsApiApiActionsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdGet(requestParameters: ActionsApiApiActionsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActionsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une action
         * @param {ActionsApiApiActionsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsIdPut(requestParameters: ActionsApiApiActionsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActionsIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActionUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une action
         * @param {ActionsApiApiActionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActionsPost(requestParameters: ActionsApiApiActionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionCreated> {
            return localVarFp.apiActionsPost(requestParameters.actionsEtActivitSJsonldActionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des actions
         * @summary Obtenir la liste des actions
         * @param {ActionsApiApiDossiersDossierActionsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActionsGetCollection(requestParameters: ActionsApiApiDossiersDossierActionsGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierActionsGetCollection200Response> {
            return localVarFp.apiDossiersDossierActionsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActionsIdGet operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsIdGetRequest
 */
export interface ActionsApiApiActionsIdGetRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActionsApiApiActionsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiActionsIdPut operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsIdPutRequest
 */
export interface ActionsApiApiActionsIdPutRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActionsApiApiActionsIdPut
     */
    readonly id: string

    /**
     * The updated Actions et activités resource
     * @type {ActionsEtActivitSJsonldActionUpdate}
     * @memberof ActionsApiApiActionsIdPut
     */
    readonly actionsEtActivitSJsonldActionUpdate: ActionsEtActivitSJsonldActionUpdate
}

/**
 * Request parameters for apiActionsPost operation in ActionsApi.
 * @export
 * @interface ActionsApiApiActionsPostRequest
 */
export interface ActionsApiApiActionsPostRequest {
    /**
     * The new Actions et activités resource
     * @type {ActionsEtActivitSJsonldActionCreate}
     * @memberof ActionsApiApiActionsPost
     */
    readonly actionsEtActivitSJsonldActionCreate: ActionsEtActivitSJsonldActionCreate
}

/**
 * Request parameters for apiDossiersDossierActionsGetCollection operation in ActionsApi.
 * @export
 * @interface ActionsApiApiDossiersDossierActionsGetCollectionRequest
 */
export interface ActionsApiApiDossiersDossierActionsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActionsApiApiDossiersDossierActionsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * ActionsApi - object-oriented interface
 * @export
 * @class ActionsApi
 * @extends {BaseAPI}
 */
export class ActionsApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une action
     * @summary Récupérer les informations d\'une action
     * @param {ActionsApiApiActionsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsIdGet(requestParameters: ActionsApiApiActionsIdGetRequest, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Actions et activités resource.
     * @summary Modifier une action
     * @param {ActionsApiApiActionsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsIdPut(requestParameters: ActionsApiApiActionsIdPutRequest, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActionUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Actions et activités resource.
     * @summary Créer une action
     * @param {ActionsApiApiActionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiActionsPost(requestParameters: ActionsApiApiActionsPostRequest, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiActionsPost(requestParameters.actionsEtActivitSJsonldActionCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des actions
     * @summary Obtenir la liste des actions
     * @param {ActionsApiApiDossiersDossierActionsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionsApi
     */
    public apiDossiersDossierActionsGetCollection(requestParameters: ActionsApiApiDossiersDossierActionsGetCollectionRequest, options?: AxiosRequestConfig) {
        return ActionsApiFp(this.configuration).apiDossiersDossierActionsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivitsApi - axios parameter creator
 * @export
 */
export const ActivitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivitesIdGet', 'id', id)
            const localVarPath = `/formalite/activites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActiviteUpdate} actionsEtActivitSJsonldActiviteUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdPut: async (id: string, actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiActivitesIdPut', 'id', id)
            // verify required parameter 'actionsEtActivitSJsonldActiviteUpdate' is not null or undefined
            assertParamExists('apiActivitesIdPut', 'actionsEtActivitSJsonldActiviteUpdate', actionsEtActivitSJsonldActiviteUpdate)
            const localVarPath = `/formalite/activites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActiviteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActionsEtActivitSJsonldActiviteCreate} actionsEtActivitSJsonldActiviteCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesPost: async (actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionsEtActivitSJsonldActiviteCreate' is not null or undefined
            assertParamExists('apiActivitesPost', 'actionsEtActivitSJsonldActiviteCreate', actionsEtActivitSJsonldActiviteCreate)
            const localVarPath = `/formalite/activites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionsEtActivitSJsonldActiviteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActivitesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierActivitesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/activites`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitsApi - functional programming interface
 * @export
 */
export const ActivitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitsApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {string} id TblAction identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActionRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {string} id TblAction identifier
         * @param {ActionsEtActivitSJsonldActiviteUpdate} actionsEtActivitSJsonldActiviteUpdate The updated Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesIdPut(id: string, actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActiviteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesIdPut(id, actionsEtActivitSJsonldActiviteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActionsEtActivitSJsonldActiviteCreate} actionsEtActivitSJsonldActiviteCreate The new Actions et activités resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiActivitesPost(actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionsEtActivitSJsonldActiviteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiActivitesPost(actionsEtActivitSJsonldActiviteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierActivitesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierActionsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierActivitesGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivitsApi - factory interface
 * @export
 */
export const ActivitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitsApiFp(configuration)
    return {
        /**
         * Récupérer les informations d\'une activité
         * @summary Récupérer les informations d\'une action
         * @param {ActivitsApiApiActivitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdGet(requestParameters: ActivitsApiApiActivitesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActionRead> {
            return localVarFp.apiActivitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Actions et activités resource.
         * @summary Modifier une activité
         * @param {ActivitsApiApiActivitesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesIdPut(requestParameters: ActivitsApiApiActivitesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActiviteRead> {
            return localVarFp.apiActivitesIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActiviteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Actions et activités resource.
         * @summary Créer une activité
         * @param {ActivitsApiApiActivitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiActivitesPost(requestParameters: ActivitsApiApiActivitesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ActionsEtActivitSJsonldActiviteCreated> {
            return localVarFp.apiActivitesPost(requestParameters.actionsEtActivitSJsonldActiviteCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des activités
         * @summary Obtenir la liste des activités
         * @param {ActivitsApiApiDossiersDossierActivitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierActivitesGetCollection(requestParameters: ActivitsApiApiDossiersDossierActivitesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierActionsGetCollection200Response> {
            return localVarFp.apiDossiersDossierActivitesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiActivitesIdGet operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesIdGetRequest
 */
export interface ActivitsApiApiActivitesIdGetRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActivitsApiApiActivitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiActivitesIdPut operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesIdPutRequest
 */
export interface ActivitsApiApiActivitesIdPutRequest {
    /**
     * TblAction identifier
     * @type {string}
     * @memberof ActivitsApiApiActivitesIdPut
     */
    readonly id: string

    /**
     * The updated Actions et activités resource
     * @type {ActionsEtActivitSJsonldActiviteUpdate}
     * @memberof ActivitsApiApiActivitesIdPut
     */
    readonly actionsEtActivitSJsonldActiviteUpdate: ActionsEtActivitSJsonldActiviteUpdate
}

/**
 * Request parameters for apiActivitesPost operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiActivitesPostRequest
 */
export interface ActivitsApiApiActivitesPostRequest {
    /**
     * The new Actions et activités resource
     * @type {ActionsEtActivitSJsonldActiviteCreate}
     * @memberof ActivitsApiApiActivitesPost
     */
    readonly actionsEtActivitSJsonldActiviteCreate: ActionsEtActivitSJsonldActiviteCreate
}

/**
 * Request parameters for apiDossiersDossierActivitesGetCollection operation in ActivitsApi.
 * @export
 * @interface ActivitsApiApiDossiersDossierActivitesGetCollectionRequest
 */
export interface ActivitsApiApiDossiersDossierActivitesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ActivitsApiApiDossiersDossierActivitesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * ActivitsApi - object-oriented interface
 * @export
 * @class ActivitsApi
 * @extends {BaseAPI}
 */
export class ActivitsApi extends BaseAPI {
    /**
     * Récupérer les informations d\'une activité
     * @summary Récupérer les informations d\'une action
     * @param {ActivitsApiApiActivitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesIdGet(requestParameters: ActivitsApiApiActivitesIdGetRequest, options?: AxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Actions et activités resource.
     * @summary Modifier une activité
     * @param {ActivitsApiApiActivitesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesIdPut(requestParameters: ActivitsApiApiActivitesIdPutRequest, options?: AxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesIdPut(requestParameters.id, requestParameters.actionsEtActivitSJsonldActiviteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Actions et activités resource.
     * @summary Créer une activité
     * @param {ActivitsApiApiActivitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiActivitesPost(requestParameters: ActivitsApiApiActivitesPostRequest, options?: AxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiActivitesPost(requestParameters.actionsEtActivitSJsonldActiviteCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des activités
     * @summary Obtenir la liste des activités
     * @param {ActivitsApiApiDossiersDossierActivitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitsApi
     */
    public apiDossiersDossierActivitesGetCollection(requestParameters: ActivitsApiApiDossiersDossierActivitesGetCollectionRequest, options?: AxiosRequestConfig) {
        return ActivitsApiFp(this.configuration).apiDossiersDossierActivitesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnoncesLgalesApi - axios parameter creator
 * @export
 */
export const AnnoncesLgalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRelationAnnoncesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierRelationAnnoncesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/relation-annonces`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdDelete', 'id', id)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdGet', 'id', id)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {AnnoncesLGalesJsonldRelationalUpdate} annoncesLGalesJsonldRelationalUpdate The updated Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdPut: async (id: string, annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdPut', 'id', id)
            // verify required parameter 'annoncesLGalesJsonldRelationalUpdate' is not null or undefined
            assertParamExists('apiRelationAnnoncesIdPut', 'annoncesLGalesJsonldRelationalUpdate', annoncesLGalesJsonldRelationalUpdate)
            const localVarPath = `/formalite/relation-annonces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annoncesLGalesJsonldRelationalUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLGalesJsonldRelationalCreate} annoncesLGalesJsonldRelationalCreate The new Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesPost: async (annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'annoncesLGalesJsonldRelationalCreate' is not null or undefined
            assertParamExists('apiRelationAnnoncesPost', 'annoncesLGalesJsonldRelationalCreate', annoncesLGalesJsonldRelationalCreate)
            const localVarPath = `/formalite/relation-annonces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annoncesLGalesJsonldRelationalCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnoncesLgalesApi - functional programming interface
 * @export
 */
export const AnnoncesLgalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnoncesLgalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierRelationAnnoncesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierRelationAnnoncesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierRelationAnnoncesGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {string} id FproRelationFdosAldos identifier
         * @param {AnnoncesLGalesJsonldRelationalUpdate} annoncesLGalesJsonldRelationalUpdate The updated Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesIdPut(id: string, annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesIdPut(id, annoncesLGalesJsonldRelationalUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLGalesJsonldRelationalCreate} annoncesLGalesJsonldRelationalCreate The new Annonces légales resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRelationAnnoncesPost(annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnoncesLGalesJsonldRelationalCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRelationAnnoncesPost(annoncesLGalesJsonldRelationalCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnoncesLgalesApi - factory interface
 * @export
 */
export const AnnoncesLgalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnoncesLgalesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des relation dossier / annonce légale
         * @summary Obtenir la liste des relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRelationAnnoncesGetCollection(requestParameters: AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierRelationAnnoncesGetCollection200Response> {
            return localVarFp.apiDossiersDossierRelationAnnoncesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation dossier / annonce légale
         * @summary Supprimer une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdDelete(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiRelationAnnoncesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation dossier / annonce légale
         * @summary Récupérer les informations d\'une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdGet(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalRead> {
            return localVarFp.apiRelationAnnoncesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Annonces légales resource.
         * @summary Modifier une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesIdPut(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalRead> {
            return localVarFp.apiRelationAnnoncesIdPut(requestParameters.id, requestParameters.annoncesLGalesJsonldRelationalUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Annonces légales resource.
         * @summary Créer une relation dossier / annonce légale
         * @param {AnnoncesLgalesApiApiRelationAnnoncesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRelationAnnoncesPost(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesPostRequest, options?: AxiosRequestConfig): AxiosPromise<AnnoncesLGalesJsonldRelationalCreated> {
            return localVarFp.apiRelationAnnoncesPost(requestParameters.annoncesLGalesJsonldRelationalCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierRelationAnnoncesGetCollection operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest
 */
export interface AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRelationAnnoncesIdDelete operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiRelationAnnoncesIdGet operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRelationAnnoncesIdPut operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest {
    /**
     * FproRelationFdosAldos identifier
     * @type {string}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdPut
     */
    readonly id: string

    /**
     * The updated Annonces légales resource
     * @type {AnnoncesLGalesJsonldRelationalUpdate}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesIdPut
     */
    readonly annoncesLGalesJsonldRelationalUpdate: AnnoncesLGalesJsonldRelationalUpdate
}

/**
 * Request parameters for apiRelationAnnoncesPost operation in AnnoncesLgalesApi.
 * @export
 * @interface AnnoncesLgalesApiApiRelationAnnoncesPostRequest
 */
export interface AnnoncesLgalesApiApiRelationAnnoncesPostRequest {
    /**
     * The new Annonces légales resource
     * @type {AnnoncesLGalesJsonldRelationalCreate}
     * @memberof AnnoncesLgalesApiApiRelationAnnoncesPost
     */
    readonly annoncesLGalesJsonldRelationalCreate: AnnoncesLGalesJsonldRelationalCreate
}

/**
 * AnnoncesLgalesApi - object-oriented interface
 * @export
 * @class AnnoncesLgalesApi
 * @extends {BaseAPI}
 */
export class AnnoncesLgalesApi extends BaseAPI {
    /**
     * Obtenir la liste des relation dossier / annonce légale
     * @summary Obtenir la liste des relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiDossiersDossierRelationAnnoncesGetCollection(requestParameters: AnnoncesLgalesApiApiDossiersDossierRelationAnnoncesGetCollectionRequest, options?: AxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiDossiersDossierRelationAnnoncesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation dossier / annonce légale
     * @summary Supprimer une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdDelete(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdDeleteRequest, options?: AxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation dossier / annonce légale
     * @summary Récupérer les informations d\'une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdGet(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdGetRequest, options?: AxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Annonces légales resource.
     * @summary Modifier une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesIdPut(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesIdPutRequest, options?: AxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesIdPut(requestParameters.id, requestParameters.annoncesLGalesJsonldRelationalUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Annonces légales resource.
     * @summary Créer une relation dossier / annonce légale
     * @param {AnnoncesLgalesApiApiRelationAnnoncesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnoncesLgalesApi
     */
    public apiRelationAnnoncesPost(requestParameters: AnnoncesLgalesApiApiRelationAnnoncesPostRequest, options?: AxiosRequestConfig) {
        return AnnoncesLgalesApiFp(this.configuration).apiRelationAnnoncesPost(requestParameters.annoncesLGalesJsonldRelationalCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CallbackDematFacileApi - axios parameter creator
 * @export
 */
export const CallbackDematFacileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileJsonld} callbackDematFacileJsonld The new Callback DematFacile resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDematFacileCallbackPost: async (callbackDematFacileJsonld: CallbackDematFacileJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callbackDematFacileJsonld' is not null or undefined
            assertParamExists('apiDematFacileCallbackPost', 'callbackDematFacileJsonld', callbackDematFacileJsonld)
            const localVarPath = `/formalite/demat-facile/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(callbackDematFacileJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallbackDematFacileApi - functional programming interface
 * @export
 */
export const CallbackDematFacileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallbackDematFacileApiAxiosParamCreator(configuration)
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileJsonld} callbackDematFacileJsonld The new Callback DematFacile resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDematFacileCallbackPost(callbackDematFacileJsonld: CallbackDematFacileJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallbackDematFacileJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDematFacileCallbackPost(callbackDematFacileJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CallbackDematFacileApi - factory interface
 * @export
 */
export const CallbackDematFacileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallbackDematFacileApiFp(configuration)
    return {
        /**
         * Callback DematFacile
         * @summary Callback DematFacile
         * @param {CallbackDematFacileApiApiDematFacileCallbackPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDematFacileCallbackPost(requestParameters: CallbackDematFacileApiApiDematFacileCallbackPostRequest, options?: AxiosRequestConfig): AxiosPromise<CallbackDematFacileJsonld> {
            return localVarFp.apiDematFacileCallbackPost(requestParameters.callbackDematFacileJsonld, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDematFacileCallbackPost operation in CallbackDematFacileApi.
 * @export
 * @interface CallbackDematFacileApiApiDematFacileCallbackPostRequest
 */
export interface CallbackDematFacileApiApiDematFacileCallbackPostRequest {
    /**
     * The new Callback DematFacile resource
     * @type {CallbackDematFacileJsonld}
     * @memberof CallbackDematFacileApiApiDematFacileCallbackPost
     */
    readonly callbackDematFacileJsonld: CallbackDematFacileJsonld
}

/**
 * CallbackDematFacileApi - object-oriented interface
 * @export
 * @class CallbackDematFacileApi
 * @extends {BaseAPI}
 */
export class CallbackDematFacileApi extends BaseAPI {
    /**
     * Callback DematFacile
     * @summary Callback DematFacile
     * @param {CallbackDematFacileApiApiDematFacileCallbackPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallbackDematFacileApi
     */
    public apiDematFacileCallbackPost(requestParameters: CallbackDematFacileApiApiDematFacileCallbackPostRequest, options?: AxiosRequestConfig) {
        return CallbackDematFacileApiFp(this.configuration).apiDematFacileCallbackPost(requestParameters.callbackDematFacileJsonld, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategorieApi - axios parameter creator
 * @export
 */
export const CategorieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGetCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdGet', 'id', id)
            const localVarPath = `/formalite/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {CategorieJsonldCategorieUpdate} categorieJsonldCategorieUpdate The updated Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdPut: async (id: string, categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdPut', 'id', id)
            // verify required parameter 'categorieJsonldCategorieUpdate' is not null or undefined
            assertParamExists('apiCategoriesIdPut', 'categorieJsonldCategorieUpdate', categorieJsonldCategorieUpdate)
            const localVarPath = `/formalite/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorieJsonldCategorieUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieJsonldCategorieCreate} categorieJsonldCategorieCreate The new Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesPost: async (categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categorieJsonldCategorieCreate' is not null or undefined
            assertParamExists('apiCategoriesPost', 'categorieJsonldCategorieCreate', categorieJsonldCategorieCreate)
            const localVarPath = `/formalite/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categorieJsonldCategorieCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategorieApi - functional programming interface
 * @export
 */
export const CategorieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategorieApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCategoriesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesGetCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {string} id FproFormaliteCategorie identifier
         * @param {CategorieJsonldCategorieUpdate} categorieJsonldCategorieUpdate The updated Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdPut(id: string, categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdPut(id, categorieJsonldCategorieUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieJsonldCategorieCreate} categorieJsonldCategorieCreate The new Categorie resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesPost(categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorieJsonldCategorieCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesPost(categorieJsonldCategorieCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategorieApi - factory interface
 * @export
 */
export const CategorieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategorieApiFp(configuration)
    return {
        /**
         * Obtenir la liste des categories
         * @summary Obtenir la liste des categories
         * @param {CategorieApiApiCategoriesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGetCollection(requestParameters: CategorieApiApiCategoriesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiCategoriesGetCollection200Response> {
            return localVarFp.apiCategoriesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une categorie
         * @summary Récupérer les informations d\'une categorie
         * @param {CategorieApiApiCategoriesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdGet(requestParameters: CategorieApiApiCategoriesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieRead> {
            return localVarFp.apiCategoriesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Categorie resource.
         * @summary Modifier une catégorie
         * @param {CategorieApiApiCategoriesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdPut(requestParameters: CategorieApiApiCategoriesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieRead> {
            return localVarFp.apiCategoriesIdPut(requestParameters.id, requestParameters.categorieJsonldCategorieUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Categorie resource.
         * @summary Créer une categorie
         * @param {CategorieApiApiCategoriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesPost(requestParameters: CategorieApiApiCategoriesPostRequest, options?: AxiosRequestConfig): AxiosPromise<CategorieJsonldCategorieCreated> {
            return localVarFp.apiCategoriesPost(requestParameters.categorieJsonldCategorieCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCategoriesGetCollection operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesGetCollectionRequest
 */
export interface CategorieApiApiCategoriesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof CategorieApiApiCategoriesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CategorieApiApiCategoriesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiCategoriesIdGet operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesIdGetRequest
 */
export interface CategorieApiApiCategoriesIdGetRequest {
    /**
     * FproFormaliteCategorie identifier
     * @type {string}
     * @memberof CategorieApiApiCategoriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCategoriesIdPut operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesIdPutRequest
 */
export interface CategorieApiApiCategoriesIdPutRequest {
    /**
     * FproFormaliteCategorie identifier
     * @type {string}
     * @memberof CategorieApiApiCategoriesIdPut
     */
    readonly id: string

    /**
     * The updated Categorie resource
     * @type {CategorieJsonldCategorieUpdate}
     * @memberof CategorieApiApiCategoriesIdPut
     */
    readonly categorieJsonldCategorieUpdate: CategorieJsonldCategorieUpdate
}

/**
 * Request parameters for apiCategoriesPost operation in CategorieApi.
 * @export
 * @interface CategorieApiApiCategoriesPostRequest
 */
export interface CategorieApiApiCategoriesPostRequest {
    /**
     * The new Categorie resource
     * @type {CategorieJsonldCategorieCreate}
     * @memberof CategorieApiApiCategoriesPost
     */
    readonly categorieJsonldCategorieCreate: CategorieJsonldCategorieCreate
}

/**
 * CategorieApi - object-oriented interface
 * @export
 * @class CategorieApi
 * @extends {BaseAPI}
 */
export class CategorieApi extends BaseAPI {
    /**
     * Obtenir la liste des categories
     * @summary Obtenir la liste des categories
     * @param {CategorieApiApiCategoriesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesGetCollection(requestParameters: CategorieApiApiCategoriesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une categorie
     * @summary Récupérer les informations d\'une categorie
     * @param {CategorieApiApiCategoriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesIdGet(requestParameters: CategorieApiApiCategoriesIdGetRequest, options?: AxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Categorie resource.
     * @summary Modifier une catégorie
     * @param {CategorieApiApiCategoriesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesIdPut(requestParameters: CategorieApiApiCategoriesIdPutRequest, options?: AxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesIdPut(requestParameters.id, requestParameters.categorieJsonldCategorieUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Categorie resource.
     * @summary Créer une categorie
     * @param {CategorieApiApiCategoriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategorieApi
     */
    public apiCategoriesPost(requestParameters: CategorieApiApiCategoriesPostRequest, options?: AxiosRequestConfig) {
        return CategorieApiFp(this.configuration).apiCategoriesPost(requestParameters.categorieJsonldCategorieCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CollectionDeDossierApi - axios parameter creator
 * @export
 */
export const CollectionDeDossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [numero] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersGetCollection: async (page?: number, itemsPerPage?: number, numero?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/collections-dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (numero !== undefined) {
                localVarQueryParameter['numero'] = numero;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdDelete', 'id', id)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdGet', 'id', id)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPut: async (id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPut', 'id', id)
            // verify required parameter 'collectionDeDossierJsonldCollectiondossierUpdate' is not null or undefined
            assertParamExists('apiCollectionsDossiersIdPut', 'collectionDeDossierJsonldCollectiondossierUpdate', collectionDeDossierJsonldCollectiondossierUpdate)
            const localVarPath = `/formalite/collections-dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(collectionDeDossierJsonldCollectiondossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionDeDossierApi - functional programming interface
 * @export
 */
export const CollectionDeDossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionDeDossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [numero] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersGetCollection(page?: number, itemsPerPage?: number, numero?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiCollectionsDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersGetCollection(page, itemsPerPage, numero, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {string} id TblCollectionDossier identifier
         * @param {CollectionDeDossierJsonldCollectiondossierUpdate} collectionDeDossierJsonldCollectiondossierUpdate The updated Collection de dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCollectionsDossiersIdPut(id: string, collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCollectionsDossiersIdPut(id, collectionDeDossierJsonldCollectiondossierUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionDeDossierApi - factory interface
 * @export
 */
export const CollectionDeDossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionDeDossierApiFp(configuration)
    return {
        /**
         * Lister les collections de dossiers
         * @summary Lister les collections de dossiers
         * @param {CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersGetCollection(requestParameters: CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiCollectionsDossiersGetCollection200Response> {
            return localVarFp.apiCollectionsDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une collection de dossier
         * @summary Supprimer une collection de dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdDelete(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCollectionsDossiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une collection de dossier
         * @summary Récupérer les informations d\'une collection de dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdGet(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Modifier les informations d\'une collection dossier
         * @summary Modifier les informations d\'une collection dossier
         * @param {CollectionDeDossierApiApiCollectionsDossiersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCollectionsDossiersIdPut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<CollectionDeDossierJsonldCollectiondossierRead> {
            return localVarFp.apiCollectionsDossiersIdPut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCollectionsDossiersGetCollection operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersGetCollection
     */
    readonly numero?: string
}

/**
 * Request parameters for apiCollectionsDossiersIdDelete operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCollectionsDossiersIdGet operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdGetRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdGetRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCollectionsDossiersIdPut operation in CollectionDeDossierApi.
 * @export
 * @interface CollectionDeDossierApiApiCollectionsDossiersIdPutRequest
 */
export interface CollectionDeDossierApiApiCollectionsDossiersIdPutRequest {
    /**
     * TblCollectionDossier identifier
     * @type {string}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPut
     */
    readonly id: string

    /**
     * The updated Collection de dossier resource
     * @type {CollectionDeDossierJsonldCollectiondossierUpdate}
     * @memberof CollectionDeDossierApiApiCollectionsDossiersIdPut
     */
    readonly collectionDeDossierJsonldCollectiondossierUpdate: CollectionDeDossierJsonldCollectiondossierUpdate
}

/**
 * CollectionDeDossierApi - object-oriented interface
 * @export
 * @class CollectionDeDossierApi
 * @extends {BaseAPI}
 */
export class CollectionDeDossierApi extends BaseAPI {
    /**
     * Lister les collections de dossiers
     * @summary Lister les collections de dossiers
     * @param {CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersGetCollection(requestParameters: CollectionDeDossierApiApiCollectionsDossiersGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.numero, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une collection de dossier
     * @summary Supprimer une collection de dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdDelete(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdDeleteRequest, options?: AxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une collection de dossier
     * @summary Récupérer les informations d\'une collection de dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdGet(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdGetRequest, options?: AxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Modifier les informations d\'une collection dossier
     * @summary Modifier les informations d\'une collection dossier
     * @param {CollectionDeDossierApiApiCollectionsDossiersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionDeDossierApi
     */
    public apiCollectionsDossiersIdPut(requestParameters: CollectionDeDossierApiApiCollectionsDossiersIdPutRequest, options?: AxiosRequestConfig) {
        return CollectionDeDossierApiFp(this.configuration).apiCollectionsDossiersIdPut(requestParameters.id, requestParameters.collectionDeDossierJsonldCollectiondossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevisApi - axios parameter creator
 * @export
 */
export const DevisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetCollection: async (page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/devis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDevisIdGet', 'id', id)
            const localVarPath = `/formalite/devis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierDevisGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierDevisGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/devis`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevisApi - functional programming interface
 * @export
 */
export const DevisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevisApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisGetCollection(page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisGetCollection(page, itemsPerPage, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {string} id TblDevis identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDevisIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisJsonldDevisRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDevisIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierDevisGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDevisGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierDevisGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevisApi - factory interface
 * @export
 */
export const DevisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevisApiFp(configuration)
    return {
        /**
         * Obtenir la liste des devis
         * @summary Obtenir la liste des devis
         * @param {DevisApiApiDevisGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisGetCollection(requestParameters: DevisApiApiDevisGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDevisGetCollection200Response> {
            return localVarFp.apiDevisGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un devis
         * @summary Récupérer les informations d\'un devis
         * @param {DevisApiApiDevisIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDevisIdGet(requestParameters: DevisApiApiDevisIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DevisJsonldDevisRead> {
            return localVarFp.apiDevisIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des devis d\'un dossier
         * @summary Obtenir la liste des devis d\'un dossier
         * @param {DevisApiApiDossiersDossierDevisGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierDevisGetCollection(requestParameters: DevisApiApiDossiersDossierDevisGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDevisGetCollection200Response> {
            return localVarFp.apiDossiersDossierDevisGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDevisGetCollection operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisGetCollectionRequest
 */
export interface DevisApiApiDevisGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DevisApiApiDevisGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiDevisIdGet operation in DevisApi.
 * @export
 * @interface DevisApiApiDevisIdGetRequest
 */
export interface DevisApiApiDevisIdGetRequest {
    /**
     * TblDevis identifier
     * @type {string}
     * @memberof DevisApiApiDevisIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersDossierDevisGetCollection operation in DevisApi.
 * @export
 * @interface DevisApiApiDossiersDossierDevisGetCollectionRequest
 */
export interface DevisApiApiDossiersDossierDevisGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DevisApiApiDossiersDossierDevisGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DevisApi - object-oriented interface
 * @export
 * @class DevisApi
 * @extends {BaseAPI}
 */
export class DevisApi extends BaseAPI {
    /**
     * Obtenir la liste des devis
     * @summary Obtenir la liste des devis
     * @param {DevisApiApiDevisGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisGetCollection(requestParameters: DevisApiApiDevisGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un devis
     * @summary Récupérer les informations d\'un devis
     * @param {DevisApiApiDevisIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDevisIdGet(requestParameters: DevisApiApiDevisIdGetRequest, options?: AxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDevisIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des devis d\'un dossier
     * @summary Obtenir la liste des devis d\'un dossier
     * @param {DevisApiApiDossiersDossierDevisGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public apiDossiersDossierDevisGetCollection(requestParameters: DevisApiApiDossiersDossierDevisGetCollectionRequest, options?: AxiosRequestConfig) {
        return DevisApiFp(this.configuration).apiDossiersDossierDevisGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentGedApi - axios parameter creator
 * @export
 */
export const DocumentGedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {string} type DocumentGed identifier
         * @param {string} applicationtag DocumentGed identifier
         * @param {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>} [tags] Map of tags
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentgedTypeApplicationtagGetCollection: async (type: string, applicationtag: string, tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiDocumentgedTypeApplicationtagGetCollection', 'type', type)
            // verify required parameter 'applicationtag' is not null or undefined
            assertParamExists('apiDocumentgedTypeApplicationtagGetCollection', 'applicationtag', applicationtag)
            const localVarPath = `/formalite/documentged/{type}/{applicationtag}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"applicationtag"}}`, encodeURIComponent(String(applicationtag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentGedApi - functional programming interface
 * @export
 */
export const DocumentGedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentGedApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {string} type DocumentGed identifier
         * @param {string} applicationtag DocumentGed identifier
         * @param {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>} [tags] Map of tags
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocumentgedTypeApplicationtagGetCollection(type: string, applicationtag: string, tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDocumentgedTypeApplicationtagGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocumentgedTypeApplicationtagGetCollection(type, applicationtag, tags, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentGedApi - factory interface
 * @export
 */
export const DocumentGedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentGedApiFp(configuration)
    return {
        /**
         * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
         * @summary Lister les documents de la ged
         * @param {DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentgedTypeApplicationtagGetCollection(requestParameters: DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDocumentgedTypeApplicationtagGetCollection200Response> {
            return localVarFp.apiDocumentgedTypeApplicationtagGetCollection(requestParameters.type, requestParameters.applicationtag, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDocumentgedTypeApplicationtagGetCollection operation in DocumentGedApi.
 * @export
 * @interface DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest
 */
export interface DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest {
    /**
     * DocumentGed identifier
     * @type {string}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly type: string

    /**
     * DocumentGed identifier
     * @type {string}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly applicationtag: string

    /**
     * Map of tags
     * @type {Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly tags?: Array<ApiDocumentgedTypeApplicationtagGetCollectionTagsParameterInner>

    /**
     * The collection page number
     * @type {number}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DocumentGedApiApiDocumentgedTypeApplicationtagGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * DocumentGedApi - object-oriented interface
 * @export
 * @class DocumentGedApi
 * @extends {BaseAPI}
 */
export class DocumentGedApi extends BaseAPI {
    /**
     * Lister les documents de la ged parmis ceux autorisés et en filtrant sur l\'idPrescripteur si besoin
     * @summary Lister les documents de la ged
     * @param {DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentGedApi
     */
    public apiDocumentgedTypeApplicationtagGetCollection(requestParameters: DocumentGedApiApiDocumentgedTypeApplicationtagGetCollectionRequest, options?: AxiosRequestConfig) {
        return DocumentGedApiFp(this.configuration).apiDocumentgedTypeApplicationtagGetCollection(requestParameters.type, requestParameters.applicationtag, requestParameters.tags, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DomainesApi - axios parameter creator
 * @export
 */
export const DomainesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/domaines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDomainesIdGet', 'id', id)
            const localVarPath = `/formalite/domaines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainesApi - functional programming interface
 * @export
 */
export const DomainesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainesApiAxiosParamCreator(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDomainesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesGetCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {string} id TblDefDomaine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDomainesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainesJsonldDomaineRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDomainesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DomainesApi - factory interface
 * @export
 */
export const DomainesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainesApiFp(configuration)
    return {
        /**
         * Récupérer la liste des domaines
         * @summary Récupérer la liste des domaines
         * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDomainesGetCollection200Response> {
            return localVarFp.apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un domaine
         * @summary Récupérer les informations d\'un domaine
         * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DomainesJsonldDomaineRead> {
            return localVarFp.apiDomainesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDomainesGetCollection operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesGetCollectionRequest
 */
export interface DomainesApiApiDomainesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DomainesApiApiDomainesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiDomainesIdGet operation in DomainesApi.
 * @export
 * @interface DomainesApiApiDomainesIdGetRequest
 */
export interface DomainesApiApiDomainesIdGetRequest {
    /**
     * TblDefDomaine identifier
     * @type {string}
     * @memberof DomainesApiApiDomainesIdGet
     */
    readonly id: string
}

/**
 * DomainesApi - object-oriented interface
 * @export
 * @class DomainesApi
 * @extends {BaseAPI}
 */
export class DomainesApi extends BaseAPI {
    /**
     * Récupérer la liste des domaines
     * @summary Récupérer la liste des domaines
     * @param {DomainesApiApiDomainesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesGetCollection(requestParameters: DomainesApiApiDomainesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un domaine
     * @summary Récupérer les informations d\'un domaine
     * @param {DomainesApiApiDomainesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainesApi
     */
    public apiDomainesIdGet(requestParameters: DomainesApiApiDomainesIdGetRequest, options?: AxiosRequestConfig) {
        return DomainesApiFp(this.configuration).apiDomainesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DossierApi - axios parameter creator
 * @export
 */
export const DossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dateCreationBefore] 
         * @param {string} [dateCreationStrictlyBefore] 
         * @param {string} [dateCreationAfter] 
         * @param {string} [dateCreationStrictlyAfter] 
         * @param {string} [numero] 
         * @param {string} [societeDenomination] 
         * @param {string} [societeSiren] 
         * @param {string} [affaire] 
         * @param {number} [idDematfacile] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {number} [collectionId] 
         * @param {Array<number>} [collectionId2] 
         * @param {'asc' | 'desc'} [orderNumero] 
         * @param {'asc' | 'desc'} [orderSocieteDenomination] 
         * @param {'asc' | 'desc'} [orderContactNom] 
         * @param {'asc' | 'desc'} [orderDateCreation] 
         * @param {'asc' | 'desc'} [orderStatutLibelle] 
         * @param {'asc' | 'desc'} [orderFormalisteNom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGetCollection: async (page?: number, itemsPerPage?: number, dateCreationBefore?: string, dateCreationStrictlyBefore?: string, dateCreationAfter?: string, dateCreationStrictlyAfter?: string, numero?: string, societeDenomination?: string, societeSiren?: string, affaire?: string, idDematfacile?: number, statutId?: number, statutId2?: Array<number>, collectionId?: number, collectionId2?: Array<number>, orderNumero?: 'asc' | 'desc', orderSocieteDenomination?: 'asc' | 'desc', orderContactNom?: 'asc' | 'desc', orderDateCreation?: 'asc' | 'desc', orderStatutLibelle?: 'asc' | 'desc', orderFormalisteNom?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dateCreationBefore !== undefined) {
                localVarQueryParameter['dateCreation[before]'] = dateCreationBefore;
            }

            if (dateCreationStrictlyBefore !== undefined) {
                localVarQueryParameter['dateCreation[strictly_before]'] = dateCreationStrictlyBefore;
            }

            if (dateCreationAfter !== undefined) {
                localVarQueryParameter['dateCreation[after]'] = dateCreationAfter;
            }

            if (dateCreationStrictlyAfter !== undefined) {
                localVarQueryParameter['dateCreation[strictly_after]'] = dateCreationStrictlyAfter;
            }

            if (numero !== undefined) {
                localVarQueryParameter['numero'] = numero;
            }

            if (societeDenomination !== undefined) {
                localVarQueryParameter['societeDenomination'] = societeDenomination;
            }

            if (societeSiren !== undefined) {
                localVarQueryParameter['societeSiren'] = societeSiren;
            }

            if (affaire !== undefined) {
                localVarQueryParameter['affaire'] = affaire;
            }

            if (idDematfacile !== undefined) {
                localVarQueryParameter['idDematfacile'] = idDematfacile;
            }

            if (statutId !== undefined) {
                localVarQueryParameter['statut.id'] = statutId;
            }

            if (statutId2) {
                localVarQueryParameter['statut.id[]'] = statutId2;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection.id'] = collectionId;
            }

            if (collectionId2) {
                localVarQueryParameter['collection.id[]'] = collectionId2;
            }

            if (orderNumero !== undefined) {
                localVarQueryParameter['order[numero]'] = orderNumero;
            }

            if (orderSocieteDenomination !== undefined) {
                localVarQueryParameter['order[societeDenomination]'] = orderSocieteDenomination;
            }

            if (orderContactNom !== undefined) {
                localVarQueryParameter['order[contact.nom]'] = orderContactNom;
            }

            if (orderDateCreation !== undefined) {
                localVarQueryParameter['order[dateCreation]'] = orderDateCreation;
            }

            if (orderStatutLibelle !== undefined) {
                localVarQueryParameter['order[statut.libelle]'] = orderStatutLibelle;
            }

            if (orderFormalisteNom !== undefined) {
                localVarQueryParameter['order[formaliste.nom]'] = orderFormalisteNom;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdDelete', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {string} id FproDossier identifier
         * @param {DossierJsonld} dossierJsonld The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDematFacilePut: async (id: string, dossierJsonld: DossierJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdDematFacilePut', 'id', id)
            // verify required parameter 'dossierJsonld' is not null or undefined
            assertParamExists('apiDossiersIdDematFacilePut', 'dossierJsonld', dossierJsonld)
            const localVarPath = `/formalite/dossiers/{id}/demat-facile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdGet', 'id', id)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdate} dossierJsonldDossierUpdate The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPut: async (id: string, dossierJsonldDossierUpdate: DossierJsonldDossierUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdPut', 'id', id)
            // verify required parameter 'dossierJsonldDossierUpdate' is not null or undefined
            assertParamExists('apiDossiersIdPut', 'dossierJsonldDossierUpdate', dossierJsonldDossierUpdate)
            const localVarPath = `/formalite/dossiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldDossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdateStatut} dossierJsonldDossierUpdateStatut The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdStatutsPut: async (id: string, dossierJsonldDossierUpdateStatut: DossierJsonldDossierUpdateStatut, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdStatutsPut', 'id', id)
            // verify required parameter 'dossierJsonldDossierUpdateStatut' is not null or undefined
            assertParamExists('apiDossiersIdStatutsPut', 'dossierJsonldDossierUpdateStatut', dossierJsonldDossierUpdateStatut)
            const localVarPath = `/formalite/dossiers/{id}/statuts`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldDossierUpdateStatut, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonld} dossierJsonld The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdValidatePut: async (id: string, dossierJsonld: DossierJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiDossiersIdValidatePut', 'id', id)
            // verify required parameter 'dossierJsonld' is not null or undefined
            assertParamExists('apiDossiersIdValidatePut', 'dossierJsonld', dossierJsonld)
            const localVarPath = `/formalite/dossiers/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierJsonldDossierCreate} dossierJsonldDossierCreate The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersPost: async (dossierJsonldDossierCreate: DossierJsonldDossierCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossierJsonldDossierCreate' is not null or undefined
            assertParamExists('apiDossiersPost', 'dossierJsonldDossierCreate', dossierJsonldDossierCreate)
            const localVarPath = `/formalite/dossiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dossierJsonldDossierCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DossierApi - functional programming interface
 * @export
 */
export const DossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dateCreationBefore] 
         * @param {string} [dateCreationStrictlyBefore] 
         * @param {string} [dateCreationAfter] 
         * @param {string} [dateCreationStrictlyAfter] 
         * @param {string} [numero] 
         * @param {string} [societeDenomination] 
         * @param {string} [societeSiren] 
         * @param {string} [affaire] 
         * @param {number} [idDematfacile] 
         * @param {number} [statutId] 
         * @param {Array<number>} [statutId2] 
         * @param {number} [collectionId] 
         * @param {Array<number>} [collectionId2] 
         * @param {'asc' | 'desc'} [orderNumero] 
         * @param {'asc' | 'desc'} [orderSocieteDenomination] 
         * @param {'asc' | 'desc'} [orderContactNom] 
         * @param {'asc' | 'desc'} [orderDateCreation] 
         * @param {'asc' | 'desc'} [orderStatutLibelle] 
         * @param {'asc' | 'desc'} [orderFormalisteNom] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersGetCollection(page?: number, itemsPerPage?: number, dateCreationBefore?: string, dateCreationStrictlyBefore?: string, dateCreationAfter?: string, dateCreationStrictlyAfter?: string, numero?: string, societeDenomination?: string, societeSiren?: string, affaire?: string, idDematfacile?: number, statutId?: number, statutId2?: Array<number>, collectionId?: number, collectionId2?: Array<number>, orderNumero?: 'asc' | 'desc', orderSocieteDenomination?: 'asc' | 'desc', orderContactNom?: 'asc' | 'desc', orderDateCreation?: 'asc' | 'desc', orderStatutLibelle?: 'asc' | 'desc', orderFormalisteNom?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersGetCollection(page, itemsPerPage, dateCreationBefore, dateCreationStrictlyBefore, dateCreationAfter, dateCreationStrictlyAfter, numero, societeDenomination, societeSiren, affaire, idDematfacile, statutId, statutId2, collectionId, collectionId2, orderNumero, orderSocieteDenomination, orderContactNom, orderDateCreation, orderStatutLibelle, orderFormalisteNom, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {string} id FproDossier identifier
         * @param {DossierJsonld} dossierJsonld The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdDematFacilePut(id: string, dossierJsonld: DossierJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdDematFacilePut(id, dossierJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdate} dossierJsonldDossierUpdate The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdPut(id: string, dossierJsonldDossierUpdate: DossierJsonldDossierUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdPut(id, dossierJsonldDossierUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonldDossierUpdateStatut} dossierJsonldDossierUpdateStatut The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdStatutsPut(id: string, dossierJsonldDossierUpdateStatut: DossierJsonldDossierUpdateStatut, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdStatutsPut(id, dossierJsonldDossierUpdateStatut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {string} id FproDossier identifier
         * @param {DossierJsonld} dossierJsonld The updated Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersIdValidatePut(id: string, dossierJsonld: DossierJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersIdValidatePut(id, dossierJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierJsonldDossierCreate} dossierJsonldDossierCreate The new Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersPost(dossierJsonldDossierCreate: DossierJsonldDossierCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DossierJsonldDossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersPost(dossierJsonldDossierCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DossierApi - factory interface
 * @export
 */
export const DossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DossierApiFp(configuration)
    return {
        /**
         * Lister les dossiers
         * @summary Lister les dossiers
         * @param {DossierApiApiDossiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersGetCollection(requestParameters: DossierApiApiDossiersGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersGetCollection200Response> {
            return localVarFp.apiDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dateCreationBefore, requestParameters.dateCreationStrictlyBefore, requestParameters.dateCreationAfter, requestParameters.dateCreationStrictlyAfter, requestParameters.numero, requestParameters.societeDenomination, requestParameters.societeSiren, requestParameters.affaire, requestParameters.idDematfacile, requestParameters.statutId, requestParameters.statutId2, requestParameters.collectionId, requestParameters.collectionId2, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {DossierApiApiDossiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDelete(requestParameters: DossierApiApiDossiersIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDossiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer le client et le dossier dematfacile
         * @summary Créer le client et le dossier dematfacile
         * @param {DossierApiApiDossiersIdDematFacilePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdDematFacilePut(requestParameters: DossierApiApiDossiersIdDematFacilePutRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdDematFacilePut(requestParameters.id, requestParameters.dossierJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un dossier
         * @summary Récupérer les informations d\'un dossier
         * @param {DossierApiApiDossiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdGet(requestParameters: DossierApiApiDossiersIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les informations d\'un dossier
         * @summary Mettre à jour les informations d\'un dossier
         * @param {DossierApiApiDossiersIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdPut(requestParameters: DossierApiApiDossiersIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {DossierApiApiDossiersIdStatutsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdStatutsPut(requestParameters: DossierApiApiDossiersIdStatutsPutRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdStatutsPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdateStatut, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour les statuts d\'un dossier
         * @summary Mettre à jour les statuts d\'un dossier
         * @param {DossierApiApiDossiersIdValidatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersIdValidatePut(requestParameters: DossierApiApiDossiersIdValidatePutRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersIdValidatePut(requestParameters.id, requestParameters.dossierJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un dossier
         * @summary Créer un dossier
         * @param {DossierApiApiDossiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersPost(requestParameters: DossierApiApiDossiersPostRequest, options?: AxiosRequestConfig): AxiosPromise<DossierJsonldDossierRead> {
            return localVarFp.apiDossiersPost(requestParameters.dossierJsonldDossierCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersGetCollection operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersGetCollectionRequest
 */
export interface DossierApiApiDossiersGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationStrictlyBefore?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly dateCreationStrictlyAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly numero?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly societeDenomination?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly societeSiren?: string

    /**
     * 
     * @type {string}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly affaire?: string

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly idDematfacile?: number

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly statutId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly statutId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly collectionId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly collectionId2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderNumero?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderSocieteDenomination?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderContactNom?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderDateCreation?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderStatutLibelle?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof DossierApiApiDossiersGetCollection
     */
    readonly orderFormalisteNom?: 'asc' | 'desc'
}

/**
 * Request parameters for apiDossiersIdDelete operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdDeleteRequest
 */
export interface DossierApiApiDossiersIdDeleteRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersIdDematFacilePut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdDematFacilePutRequest
 */
export interface DossierApiApiDossiersIdDematFacilePutRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdDematFacilePut
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierJsonld}
     * @memberof DossierApiApiDossiersIdDematFacilePut
     */
    readonly dossierJsonld: DossierJsonld
}

/**
 * Request parameters for apiDossiersIdGet operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdGetRequest
 */
export interface DossierApiApiDossiersIdGetRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiDossiersIdPut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdPutRequest
 */
export interface DossierApiApiDossiersIdPutRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdPut
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierJsonldDossierUpdate}
     * @memberof DossierApiApiDossiersIdPut
     */
    readonly dossierJsonldDossierUpdate: DossierJsonldDossierUpdate
}

/**
 * Request parameters for apiDossiersIdStatutsPut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdStatutsPutRequest
 */
export interface DossierApiApiDossiersIdStatutsPutRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdStatutsPut
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierJsonldDossierUpdateStatut}
     * @memberof DossierApiApiDossiersIdStatutsPut
     */
    readonly dossierJsonldDossierUpdateStatut: DossierJsonldDossierUpdateStatut
}

/**
 * Request parameters for apiDossiersIdValidatePut operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersIdValidatePutRequest
 */
export interface DossierApiApiDossiersIdValidatePutRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof DossierApiApiDossiersIdValidatePut
     */
    readonly id: string

    /**
     * The updated Dossier resource
     * @type {DossierJsonld}
     * @memberof DossierApiApiDossiersIdValidatePut
     */
    readonly dossierJsonld: DossierJsonld
}

/**
 * Request parameters for apiDossiersPost operation in DossierApi.
 * @export
 * @interface DossierApiApiDossiersPostRequest
 */
export interface DossierApiApiDossiersPostRequest {
    /**
     * The new Dossier resource
     * @type {DossierJsonldDossierCreate}
     * @memberof DossierApiApiDossiersPost
     */
    readonly dossierJsonldDossierCreate: DossierJsonldDossierCreate
}

/**
 * DossierApi - object-oriented interface
 * @export
 * @class DossierApi
 * @extends {BaseAPI}
 */
export class DossierApi extends BaseAPI {
    /**
     * Lister les dossiers
     * @summary Lister les dossiers
     * @param {DossierApiApiDossiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersGetCollection(requestParameters: DossierApiApiDossiersGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dateCreationBefore, requestParameters.dateCreationStrictlyBefore, requestParameters.dateCreationAfter, requestParameters.dateCreationStrictlyAfter, requestParameters.numero, requestParameters.societeDenomination, requestParameters.societeSiren, requestParameters.affaire, requestParameters.idDematfacile, requestParameters.statutId, requestParameters.statutId2, requestParameters.collectionId, requestParameters.collectionId2, requestParameters.orderNumero, requestParameters.orderSocieteDenomination, requestParameters.orderContactNom, requestParameters.orderDateCreation, requestParameters.orderStatutLibelle, requestParameters.orderFormalisteNom, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un dossier
     * @summary Supprimer un dossier
     * @param {DossierApiApiDossiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdDelete(requestParameters: DossierApiApiDossiersIdDeleteRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer le client et le dossier dematfacile
     * @summary Créer le client et le dossier dematfacile
     * @param {DossierApiApiDossiersIdDematFacilePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdDematFacilePut(requestParameters: DossierApiApiDossiersIdDematFacilePutRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdDematFacilePut(requestParameters.id, requestParameters.dossierJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un dossier
     * @summary Récupérer les informations d\'un dossier
     * @param {DossierApiApiDossiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdGet(requestParameters: DossierApiApiDossiersIdGetRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les informations d\'un dossier
     * @summary Mettre à jour les informations d\'un dossier
     * @param {DossierApiApiDossiersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdPut(requestParameters: DossierApiApiDossiersIdPutRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les statuts d\'un dossier
     * @summary Mettre à jour les statuts d\'un dossier
     * @param {DossierApiApiDossiersIdStatutsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdStatutsPut(requestParameters: DossierApiApiDossiersIdStatutsPutRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdStatutsPut(requestParameters.id, requestParameters.dossierJsonldDossierUpdateStatut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour les statuts d\'un dossier
     * @summary Mettre à jour les statuts d\'un dossier
     * @param {DossierApiApiDossiersIdValidatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersIdValidatePut(requestParameters: DossierApiApiDossiersIdValidatePutRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersIdValidatePut(requestParameters.id, requestParameters.dossierJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un dossier
     * @summary Créer un dossier
     * @param {DossierApiApiDossiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DossierApi
     */
    public apiDossiersPost(requestParameters: DossierApiApiDossiersPostRequest, options?: AxiosRequestConfig) {
        return DossierApiFp(this.configuration).apiDossiersPost(requestParameters.dossierJsonldDossierCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FichiersDossierApi - axios parameter creator
 * @export
 */
export const FichiersDossierApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, expediteur?: number, expediteur2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFichiersGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/fichiers`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (expediteur !== undefined) {
                localVarQueryParameter['expediteur'] = expediteur;
            }

            if (expediteur2) {
                localVarQueryParameter['expediteur[]'] = expediteur2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [expediteur] 
         * @param {number} [expedition] 
         * @param {number} [idDocDf] 
         * @param {string} [typeDoc] 
         * @param {FichiersDossierDocumentfileCreateDocSigned} [docSigned] 
         * @param {FichiersDossierDocumentfileCreateDocSigned} [docOriginal] 
         * @param {File} [file] 
         * @param {boolean} [typageAuto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersPost: async (dossier: string, expediteur?: number, expedition?: number, idDocDf?: number, typeDoc?: string, docSigned?: FichiersDossierDocumentfileCreateDocSigned, docOriginal?: FichiersDossierDocumentfileCreateDocSigned, file?: File, typageAuto?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFichiersPost', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/fichiers`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


            if (expediteur !== undefined) { 
                localVarFormParams.append('expediteur', expediteur as any);
            }
    
            if (expedition !== undefined) { 
                localVarFormParams.append('expedition', expedition as any);
            }
    
            if (idDocDf !== undefined) { 
                localVarFormParams.append('idDocDf', idDocDf as any);
            }
    
            if (typeDoc !== undefined) { 
                localVarFormParams.append('typeDoc', typeDoc as any);
            }
    
            if (docSigned !== undefined) { 
                localVarFormParams.append('docSigned', new Blob([JSON.stringify(docSigned)], { type: "application/json", }));
            }
    
            if (docOriginal !== undefined) { 
                localVarFormParams.append('docOriginal', new Blob([JSON.stringify(docOriginal)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (typageAuto !== undefined) { 
                localVarFormParams.append('typageAuto', typageAuto as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdDelete', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdFileGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdFileGet', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}/file`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdGet', 'id', id)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {FichiersDossierDocumentfileUpdate} fichiersDossierDocumentfileUpdate The updated Fichiers Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdPatch: async (id: string, fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFichiersIdPatch', 'id', id)
            // verify required parameter 'fichiersDossierDocumentfileUpdate' is not null or undefined
            assertParamExists('apiFichiersIdPatch', 'fichiersDossierDocumentfileUpdate', fichiersDossierDocumentfileUpdate)
            const localVarPath = `/formalite/fichiers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fichiersDossierDocumentfileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FichiersDossierApi - functional programming interface
 * @export
 */
export const FichiersDossierApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FichiersDossierApiAxiosParamCreator(configuration)
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFichiersGetCollection(dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, expediteur?: number, expediteur2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFichiersGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFichiersGetCollection(dossier, page, itemsPerPage, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, expediteur, expediteur2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [expediteur] 
         * @param {number} [expedition] 
         * @param {number} [idDocDf] 
         * @param {string} [typeDoc] 
         * @param {FichiersDossierDocumentfileCreateDocSigned} [docSigned] 
         * @param {FichiersDossierDocumentfileCreateDocSigned} [docOriginal] 
         * @param {File} [file] 
         * @param {boolean} [typageAuto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFichiersPost(dossier: string, expediteur?: number, expedition?: number, idDocDf?: number, typeDoc?: string, docSigned?: FichiersDossierDocumentfileCreateDocSigned, docOriginal?: FichiersDossierDocumentfileCreateDocSigned, file?: File, typageAuto?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFichiersPost(dossier, expediteur, expedition, idDocDf, typeDoc, docSigned, docOriginal, file, typageAuto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdFileGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdFileGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {string} id FproDosDocFile identifier
         * @param {FichiersDossierDocumentfileUpdate} fichiersDossierDocumentfileUpdate The updated Fichiers Dossier resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFichiersIdPatch(id: string, fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FichiersDossierJsonldDocumentfileRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFichiersIdPatch(id, fichiersDossierDocumentfileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FichiersDossierApi - factory interface
 * @export
 */
export const FichiersDossierApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FichiersDossierApiFp(configuration)
    return {
        /**
         * Lister les fichiers liés au dossier
         * @summary Lister les fichiers liés au dossier
         * @param {FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersGetCollection(requestParameters: FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierFichiersGetCollection200Response> {
            return localVarFp.apiDossiersDossierFichiersGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, requestParameters.expediteur, requestParameters.expediteur2, options).then((request) => request(axios, basePath));
        },
        /**
         * Ajouter un fichier au dossier
         * @summary Ajouter un fichier au dossier
         * @param {FichiersDossierApiApiDossiersDossierFichiersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFichiersPost(requestParameters: FichiersDossierApiApiDossiersDossierFichiersPostRequest, options?: AxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiDossiersDossierFichiersPost(requestParameters.dossier, requestParameters.expediteur, requestParameters.expedition, requestParameters.idDocDf, requestParameters.typeDoc, requestParameters.docSigned, requestParameters.docOriginal, requestParameters.file, requestParameters.typageAuto, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un dossier
         * @summary Supprimer un dossier
         * @param {FichiersDossierApiApiFichiersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdDelete(requestParameters: FichiersDossierApiApiFichiersIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFichiersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdFileGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdFileGet(requestParameters: FichiersDossierApiApiFichiersIdFileGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFichiersIdFileGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Fichiers Dossier resource.
         * @summary Retrieves a Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdGet(requestParameters: FichiersDossierApiApiFichiersIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiFichiersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Fichiers Dossier resource.
         * @summary Updates the Fichiers Dossier resource.
         * @param {FichiersDossierApiApiFichiersIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFichiersIdPatch(requestParameters: FichiersDossierApiApiFichiersIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<FichiersDossierJsonldDocumentfileRead> {
            return localVarFp.apiFichiersIdPatch(requestParameters.id, requestParameters.fichiersDossierDocumentfileUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierFichiersGetCollection operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest
 */
export interface FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly expediteur?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersGetCollection
     */
    readonly expediteur2?: Array<number>
}

/**
 * Request parameters for apiDossiersDossierFichiersPost operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiDossiersDossierFichiersPostRequest
 */
export interface FichiersDossierApiApiDossiersDossierFichiersPostRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly dossier: string

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly expediteur?: number

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly expedition?: number

    /**
     * 
     * @type {number}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly idDocDf?: number

    /**
     * 
     * @type {string}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly typeDoc?: string

    /**
     * 
     * @type {FichiersDossierDocumentfileCreateDocSigned}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly docSigned?: FichiersDossierDocumentfileCreateDocSigned

    /**
     * 
     * @type {FichiersDossierDocumentfileCreateDocSigned}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly docOriginal?: FichiersDossierDocumentfileCreateDocSigned

    /**
     * 
     * @type {File}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly file?: File

    /**
     * 
     * @type {boolean}
     * @memberof FichiersDossierApiApiDossiersDossierFichiersPost
     */
    readonly typageAuto?: boolean
}

/**
 * Request parameters for apiFichiersIdDelete operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdDeleteRequest
 */
export interface FichiersDossierApiApiFichiersIdDeleteRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdFileGet operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdFileGetRequest
 */
export interface FichiersDossierApiApiFichiersIdFileGetRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdFileGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdGet operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdGetRequest
 */
export interface FichiersDossierApiApiFichiersIdGetRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFichiersIdPatch operation in FichiersDossierApi.
 * @export
 * @interface FichiersDossierApiApiFichiersIdPatchRequest
 */
export interface FichiersDossierApiApiFichiersIdPatchRequest {
    /**
     * FproDosDocFile identifier
     * @type {string}
     * @memberof FichiersDossierApiApiFichiersIdPatch
     */
    readonly id: string

    /**
     * The updated Fichiers Dossier resource
     * @type {FichiersDossierDocumentfileUpdate}
     * @memberof FichiersDossierApiApiFichiersIdPatch
     */
    readonly fichiersDossierDocumentfileUpdate: FichiersDossierDocumentfileUpdate
}

/**
 * FichiersDossierApi - object-oriented interface
 * @export
 * @class FichiersDossierApi
 * @extends {BaseAPI}
 */
export class FichiersDossierApi extends BaseAPI {
    /**
     * Lister les fichiers liés au dossier
     * @summary Lister les fichiers liés au dossier
     * @param {FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiDossiersDossierFichiersGetCollection(requestParameters: FichiersDossierApiApiDossiersDossierFichiersGetCollectionRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiDossiersDossierFichiersGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, requestParameters.expediteur, requestParameters.expediteur2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ajouter un fichier au dossier
     * @summary Ajouter un fichier au dossier
     * @param {FichiersDossierApiApiDossiersDossierFichiersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiDossiersDossierFichiersPost(requestParameters: FichiersDossierApiApiDossiersDossierFichiersPostRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiDossiersDossierFichiersPost(requestParameters.dossier, requestParameters.expediteur, requestParameters.expedition, requestParameters.idDocDf, requestParameters.typeDoc, requestParameters.docSigned, requestParameters.docOriginal, requestParameters.file, requestParameters.typageAuto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un dossier
     * @summary Supprimer un dossier
     * @param {FichiersDossierApiApiFichiersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdDelete(requestParameters: FichiersDossierApiApiFichiersIdDeleteRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Fichiers Dossier resource.
     * @summary Retrieves a Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdFileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdFileGet(requestParameters: FichiersDossierApiApiFichiersIdFileGetRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdFileGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Fichiers Dossier resource.
     * @summary Retrieves a Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdGet(requestParameters: FichiersDossierApiApiFichiersIdGetRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Fichiers Dossier resource.
     * @summary Updates the Fichiers Dossier resource.
     * @param {FichiersDossierApiApiFichiersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FichiersDossierApi
     */
    public apiFichiersIdPatch(requestParameters: FichiersDossierApiApiFichiersIdPatchRequest, options?: AxiosRequestConfig) {
        return FichiersDossierApiFp(this.configuration).apiFichiersIdPatch(requestParameters.id, requestParameters.fichiersDossierDocumentfileUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormalisteApi - axios parameter creator
 * @export
 */
export const FormalisteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesGetCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formalistes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {string} id TblUtilisateur identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalistesIdGet', 'id', id)
            const localVarPath = `/formalite/formalistes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormalisteApi - functional programming interface
 * @export
 */
export const FormalisteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormalisteApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalistesGetCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormalistesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalistesGetCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {string} id TblUtilisateur identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalistesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormalisteJsonldFormalisteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalistesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormalisteApi - factory interface
 * @export
 */
export const FormalisteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormalisteApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formalistes
         * @summary Obtenir la liste des formalistes
         * @param {FormalisteApiApiFormalistesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesGetCollection(requestParameters: FormalisteApiApiFormalistesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiFormalistesGetCollection200Response> {
            return localVarFp.apiFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un formaliste
         * @summary Récupérer les informations d\'un formaliste
         * @param {FormalisteApiApiFormalistesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalistesIdGet(requestParameters: FormalisteApiApiFormalistesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FormalisteJsonldFormalisteRead> {
            return localVarFp.apiFormalistesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalistesGetCollection operation in FormalisteApi.
 * @export
 * @interface FormalisteApiApiFormalistesGetCollectionRequest
 */
export interface FormalisteApiApiFormalistesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormalisteApiApiFormalistesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormalisteApiApiFormalistesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiFormalistesIdGet operation in FormalisteApi.
 * @export
 * @interface FormalisteApiApiFormalistesIdGetRequest
 */
export interface FormalisteApiApiFormalistesIdGetRequest {
    /**
     * TblUtilisateur identifier
     * @type {string}
     * @memberof FormalisteApiApiFormalistesIdGet
     */
    readonly id: string
}

/**
 * FormalisteApi - object-oriented interface
 * @export
 * @class FormalisteApi
 * @extends {BaseAPI}
 */
export class FormalisteApi extends BaseAPI {
    /**
     * Obtenir la liste des formalistes
     * @summary Obtenir la liste des formalistes
     * @param {FormalisteApiApiFormalistesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormalisteApi
     */
    public apiFormalistesGetCollection(requestParameters: FormalisteApiApiFormalistesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FormalisteApiFp(this.configuration).apiFormalistesGetCollection(requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un formaliste
     * @summary Récupérer les informations d\'un formaliste
     * @param {FormalisteApiApiFormalistesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormalisteApi
     */
    public apiFormalistesIdGet(requestParameters: FormalisteApiApiFormalistesIdGetRequest, options?: AxiosRequestConfig) {
        return FormalisteApiFp(this.configuration).apiFormalistesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormaliteApi - axios parameter creator
 * @export
 */
export const FormaliteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [categorieId] 
         * @param {Array<number>} [categorieId2] 
         * @param {'asc' | 'desc'} [orderLibelle] 
         * @param {'asc' | 'desc'} [orderCategorieLibelle] 
         * @param {boolean} [deleted] 
         * @param {number} [compatibleWith] Retrieve compatible formalities by formality ID
         * @param {number} [groupement] Filtre les formalités par groupement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection: async (page?: number, itemsPerPage?: number, categorieId?: number, categorieId2?: Array<number>, orderLibelle?: 'asc' | 'desc', orderCategorieLibelle?: 'asc' | 'desc', deleted?: boolean, compatibleWith?: number, groupement?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (categorieId !== undefined) {
                localVarQueryParameter['categorie.id'] = categorieId;
            }

            if (categorieId2) {
                localVarQueryParameter['categorie.id[]'] = categorieId2;
            }

            if (orderLibelle !== undefined) {
                localVarQueryParameter['order[libelle]'] = orderLibelle;
            }

            if (orderCategorieLibelle !== undefined) {
                localVarQueryParameter['order[categorie.libelle]'] = orderCategorieLibelle;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (compatibleWith !== undefined) {
                localVarQueryParameter['compatibleWith'] = compatibleWith;
            }

            if (groupement !== undefined) {
                localVarQueryParameter['groupement'] = groupement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdDelete', 'id', id)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdGet', 'id', id)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {FormaliteJsonldFormaliteUpdate} formaliteJsonldFormaliteUpdate The updated Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdPut: async (id: string, formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormalitesIdPut', 'id', id)
            // verify required parameter 'formaliteJsonldFormaliteUpdate' is not null or undefined
            assertParamExists('apiFormalitesIdPut', 'formaliteJsonldFormaliteUpdate', formaliteJsonldFormaliteUpdate)
            const localVarPath = `/formalite/formalites/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formaliteJsonldFormaliteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteJsonldFormaliteCreate} formaliteJsonldFormaliteCreate The new Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesPost: async (formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formaliteJsonldFormaliteCreate' is not null or undefined
            assertParamExists('apiFormalitesPost', 'formaliteJsonldFormaliteCreate', formaliteJsonldFormaliteCreate)
            const localVarPath = `/formalite/formalites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formaliteJsonldFormaliteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormaliteApi - functional programming interface
 * @export
 */
export const FormaliteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormaliteApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [categorieId] 
         * @param {Array<number>} [categorieId2] 
         * @param {'asc' | 'desc'} [orderLibelle] 
         * @param {'asc' | 'desc'} [orderCategorieLibelle] 
         * @param {boolean} [deleted] 
         * @param {number} [compatibleWith] Retrieve compatible formalities by formality ID
         * @param {number} [groupement] Filtre les formalités par groupement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesGetCollection(page?: number, itemsPerPage?: number, categorieId?: number, categorieId2?: Array<number>, orderLibelle?: 'asc' | 'desc', orderCategorieLibelle?: 'asc' | 'desc', deleted?: boolean, compatibleWith?: number, groupement?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormalitesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesGetCollection(page, itemsPerPage, categorieId, categorieId2, orderLibelle, orderCategorieLibelle, deleted, compatibleWith, groupement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {string} id FproFormaliteArticle identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {string} id FproFormaliteArticle identifier
         * @param {FormaliteJsonldFormaliteUpdate} formaliteJsonldFormaliteUpdate The updated Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesIdPut(id: string, formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesIdPut(id, formaliteJsonldFormaliteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteJsonldFormaliteCreate} formaliteJsonldFormaliteCreate The new Formalite resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormalitesPost(formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormaliteJsonldFormaliteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormalitesPost(formaliteJsonldFormaliteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormaliteApi - factory interface
 * @export
 */
export const FormaliteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormaliteApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formalites
         * @summary Obtenir la liste des formalites
         * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiFormalitesGetCollection200Response> {
            return localVarFp.apiFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.categorieId, requestParameters.categorieId2, requestParameters.orderLibelle, requestParameters.orderCategorieLibelle, requestParameters.deleted, requestParameters.compatibleWith, requestParameters.groupement, options).then((request) => request(axios, basePath));
        },
        /**
         * Suprimmer une formalité
         * @summary Suprimmer une formalité
         * @param {FormaliteApiApiFormalitesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdDelete(requestParameters: FormaliteApiApiFormalitesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFormalitesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une formalite
         * @summary Récupérer les informations d\'une formalite
         * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteRead> {
            return localVarFp.apiFormalitesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Formalite resource.
         * @summary Modifier une formalité
         * @param {FormaliteApiApiFormalitesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesIdPut(requestParameters: FormaliteApiApiFormalitesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteRead> {
            return localVarFp.apiFormalitesIdPut(requestParameters.id, requestParameters.formaliteJsonldFormaliteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Formalite resource.
         * @summary Créer une formalité
         * @param {FormaliteApiApiFormalitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormalitesPost(requestParameters: FormaliteApiApiFormalitesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FormaliteJsonldFormaliteCreated> {
            return localVarFp.apiFormalitesPost(requestParameters.formaliteJsonldFormaliteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormalitesGetCollection operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesGetCollectionRequest
 */
export interface FormaliteApiApiFormalitesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly categorieId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly categorieId2?: Array<number>

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly orderLibelle?: 'asc' | 'desc'

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly orderCategorieLibelle?: 'asc' | 'desc'

    /**
     * 
     * @type {boolean}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly deleted?: boolean

    /**
     * Retrieve compatible formalities by formality ID
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly compatibleWith?: number

    /**
     * Filtre les formalités par groupement
     * @type {number}
     * @memberof FormaliteApiApiFormalitesGetCollection
     */
    readonly groupement?: number
}

/**
 * Request parameters for apiFormalitesIdDelete operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdDeleteRequest
 */
export interface FormaliteApiApiFormalitesIdDeleteRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiFormalitesIdGet operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdGetRequest
 */
export interface FormaliteApiApiFormalitesIdGetRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiFormalitesIdPut operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesIdPutRequest
 */
export interface FormaliteApiApiFormalitesIdPutRequest {
    /**
     * FproFormaliteArticle identifier
     * @type {string}
     * @memberof FormaliteApiApiFormalitesIdPut
     */
    readonly id: string

    /**
     * The updated Formalite resource
     * @type {FormaliteJsonldFormaliteUpdate}
     * @memberof FormaliteApiApiFormalitesIdPut
     */
    readonly formaliteJsonldFormaliteUpdate: FormaliteJsonldFormaliteUpdate
}

/**
 * Request parameters for apiFormalitesPost operation in FormaliteApi.
 * @export
 * @interface FormaliteApiApiFormalitesPostRequest
 */
export interface FormaliteApiApiFormalitesPostRequest {
    /**
     * The new Formalite resource
     * @type {FormaliteJsonldFormaliteCreate}
     * @memberof FormaliteApiApiFormalitesPost
     */
    readonly formaliteJsonldFormaliteCreate: FormaliteJsonldFormaliteCreate
}

/**
 * FormaliteApi - object-oriented interface
 * @export
 * @class FormaliteApi
 * @extends {BaseAPI}
 */
export class FormaliteApi extends BaseAPI {
    /**
     * Obtenir la liste des formalites
     * @summary Obtenir la liste des formalites
     * @param {FormaliteApiApiFormalitesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesGetCollection(requestParameters: FormaliteApiApiFormalitesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.categorieId, requestParameters.categorieId2, requestParameters.orderLibelle, requestParameters.orderCategorieLibelle, requestParameters.deleted, requestParameters.compatibleWith, requestParameters.groupement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suprimmer une formalité
     * @summary Suprimmer une formalité
     * @param {FormaliteApiApiFormalitesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdDelete(requestParameters: FormaliteApiApiFormalitesIdDeleteRequest, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une formalite
     * @summary Récupérer les informations d\'une formalite
     * @param {FormaliteApiApiFormalitesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdGet(requestParameters: FormaliteApiApiFormalitesIdGetRequest, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Formalite resource.
     * @summary Modifier une formalité
     * @param {FormaliteApiApiFormalitesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesIdPut(requestParameters: FormaliteApiApiFormalitesIdPutRequest, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesIdPut(requestParameters.id, requestParameters.formaliteJsonldFormaliteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Formalite resource.
     * @summary Créer une formalité
     * @param {FormaliteApiApiFormalitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormaliteApi
     */
    public apiFormalitesPost(requestParameters: FormaliteApiApiFormalitesPostRequest, options?: AxiosRequestConfig) {
        return FormaliteApiFp(this.configuration).apiFormalitesPost(requestParameters.formaliteJsonldFormaliteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FormeJuridiqueApi - axios parameter creator
 * @export
 */
export const FormeJuridiqueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomenclature] 
         * @param {Array<string>} [nomenclature2] 
         * @param {string} [abreviation] 
         * @param {Array<string>} [abreviation2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesGetCollection: async (page?: number, itemsPerPage?: number, nomenclature?: string, nomenclature2?: Array<string>, abreviation?: string, abreviation2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/formes-juridiques`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (nomenclature !== undefined) {
                localVarQueryParameter['nomenclature'] = nomenclature;
            }

            if (nomenclature2) {
                localVarQueryParameter['nomenclature[]'] = nomenclature2;
            }

            if (abreviation !== undefined) {
                localVarQueryParameter['abreviation'] = abreviation;
            }

            if (abreviation2) {
                localVarQueryParameter['abreviation[]'] = abreviation2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {string} id TblAnnonceSTypeDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormesJuridiquesIdGet', 'id', id)
            const localVarPath = `/formalite/formes-juridiques/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormeJuridiqueApi - functional programming interface
 * @export
 */
export const FormeJuridiqueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormeJuridiqueApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [nomenclature] 
         * @param {Array<string>} [nomenclature2] 
         * @param {string} [abreviation] 
         * @param {Array<string>} [abreviation2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormesJuridiquesGetCollection(page?: number, itemsPerPage?: number, nomenclature?: string, nomenclature2?: Array<string>, abreviation?: string, abreviation2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiFormesJuridiquesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormesJuridiquesGetCollection(page, itemsPerPage, nomenclature, nomenclature2, abreviation, abreviation2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {string} id TblAnnonceSTypeDetail identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormesJuridiquesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormeJuridiqueJsonldFormejuridiqueRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormesJuridiquesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormeJuridiqueApi - factory interface
 * @export
 */
export const FormeJuridiqueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormeJuridiqueApiFp(configuration)
    return {
        /**
         * Obtenir la liste des formes juridiques
         * @summary Obtenir la liste des formes juridiques
         * @param {FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesGetCollection(requestParameters: FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiFormesJuridiquesGetCollection200Response> {
            return localVarFp.apiFormesJuridiquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomenclature, requestParameters.nomenclature2, requestParameters.abreviation, requestParameters.abreviation2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une forme juridique
         * @summary Récupérer les informations d\'une forme juridique
         * @param {FormeJuridiqueApiApiFormesJuridiquesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormesJuridiquesIdGet(requestParameters: FormeJuridiqueApiApiFormesJuridiquesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FormeJuridiqueJsonldFormejuridiqueRead> {
            return localVarFp.apiFormesJuridiquesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFormesJuridiquesGetCollection operation in FormeJuridiqueApi.
 * @export
 * @interface FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest
 */
export interface FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly nomenclature?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly nomenclature2?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly abreviation?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesGetCollection
     */
    readonly abreviation2?: Array<string>
}

/**
 * Request parameters for apiFormesJuridiquesIdGet operation in FormeJuridiqueApi.
 * @export
 * @interface FormeJuridiqueApiApiFormesJuridiquesIdGetRequest
 */
export interface FormeJuridiqueApiApiFormesJuridiquesIdGetRequest {
    /**
     * TblAnnonceSTypeDetail identifier
     * @type {string}
     * @memberof FormeJuridiqueApiApiFormesJuridiquesIdGet
     */
    readonly id: string
}

/**
 * FormeJuridiqueApi - object-oriented interface
 * @export
 * @class FormeJuridiqueApi
 * @extends {BaseAPI}
 */
export class FormeJuridiqueApi extends BaseAPI {
    /**
     * Obtenir la liste des formes juridiques
     * @summary Obtenir la liste des formes juridiques
     * @param {FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormeJuridiqueApi
     */
    public apiFormesJuridiquesGetCollection(requestParameters: FormeJuridiqueApiApiFormesJuridiquesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FormeJuridiqueApiFp(this.configuration).apiFormesJuridiquesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.nomenclature, requestParameters.nomenclature2, requestParameters.abreviation, requestParameters.abreviation2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une forme juridique
     * @summary Récupérer les informations d\'une forme juridique
     * @param {FormeJuridiqueApiApiFormesJuridiquesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormeJuridiqueApi
     */
    public apiFormesJuridiquesIdGet(requestParameters: FormeJuridiqueApiApiFormesJuridiquesIdGetRequest, options?: AxiosRequestConfig) {
        return FormeJuridiqueApiFp(this.configuration).apiFormesJuridiquesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GreffeApi - axios parameter creator
 * @export
 */
export const GreffeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dep] 
         * @param {Array<string>} [dep2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection: async (page?: number, itemsPerPage?: number, dep?: string, dep2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/greffes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dep !== undefined) {
                localVarQueryParameter['dep'] = dep;
            }

            if (dep2) {
                localVarQueryParameter['dep[]'] = dep2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiGreffesIdGet', 'id', id)
            const localVarPath = `/formalite/greffes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GreffeApi - functional programming interface
 * @export
 */
export const GreffeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GreffeApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dep] 
         * @param {Array<string>} [dep2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesGetCollection(page?: number, itemsPerPage?: number, dep?: string, dep2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGreffesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesGetCollection(page, itemsPerPage, dep, dep2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {string} id TblGreffes identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGreffesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GreffeJsonldGreffeRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGreffesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GreffeApi - factory interface
 * @export
 */
export const GreffeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GreffeApiFp(configuration)
    return {
        /**
         * Obtenir la liste des greffes
         * @summary Obtenir la liste des greffes
         * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiGreffesGetCollection200Response> {
            return localVarFp.apiGreffesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dep, requestParameters.dep2, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un greffe
         * @summary Récupérer les informations d\'un greffe
         * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<GreffeJsonldGreffeRead> {
            return localVarFp.apiGreffesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiGreffesGetCollection operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesGetCollectionRequest
 */
export interface GreffeApiApiGreffesGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly dep?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof GreffeApiApiGreffesGetCollection
     */
    readonly dep2?: Array<string>
}

/**
 * Request parameters for apiGreffesIdGet operation in GreffeApi.
 * @export
 * @interface GreffeApiApiGreffesIdGetRequest
 */
export interface GreffeApiApiGreffesIdGetRequest {
    /**
     * TblGreffes identifier
     * @type {string}
     * @memberof GreffeApiApiGreffesIdGet
     */
    readonly id: string
}

/**
 * GreffeApi - object-oriented interface
 * @export
 * @class GreffeApi
 * @extends {BaseAPI}
 */
export class GreffeApi extends BaseAPI {
    /**
     * Obtenir la liste des greffes
     * @summary Obtenir la liste des greffes
     * @param {GreffeApiApiGreffesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesGetCollection(requestParameters: GreffeApiApiGreffesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.dep, requestParameters.dep2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un greffe
     * @summary Récupérer les informations d\'un greffe
     * @param {GreffeApiApiGreffesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GreffeApi
     */
    public apiGreffesIdGet(requestParameters: GreffeApiApiGreffesIdGetRequest, options?: AxiosRequestConfig) {
        return GreffeApiFp(this.configuration).apiGreffesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {boolean} [readByPrescri] 
         * @param {boolean} [readByFrmlist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierMessagesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, expediteur?: number, expediteur2?: Array<number>, readByPrescri?: boolean, readByFrmlist?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierMessagesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/messages`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (expediteur !== undefined) {
                localVarQueryParameter['expediteur'] = expediteur;
            }

            if (expediteur2) {
                localVarQueryParameter['expediteur[]'] = expediteur2;
            }

            if (readByPrescri !== undefined) {
                localVarQueryParameter['readByPrescri'] = readByPrescri;
            }

            if (readByFrmlist !== undefined) {
                localVarQueryParameter['readByFrmlist'] = readByFrmlist;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {string} id FproDosMessage identifier
         * @param {MessageJsonld} messageJsonld The updated Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdAckPut: async (id: string, messageJsonld: MessageJsonld, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdAckPut', 'id', id)
            // verify required parameter 'messageJsonld' is not null or undefined
            assertParamExists('apiMessagesIdAckPut', 'messageJsonld', messageJsonld)
            const localVarPath = `/formalite/messages/{id}/ack`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdDelete', 'id', id)
            const localVarPath = `/formalite/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMessagesIdGet', 'id', id)
            const localVarPath = `/formalite/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageJsonldMessageCreate} messageJsonldMessageCreate The new Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost: async (messageJsonldMessageCreate: MessageJsonldMessageCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageJsonldMessageCreate' is not null or undefined
            assertParamExists('apiMessagesPost', 'messageJsonldMessageCreate', messageJsonldMessageCreate)
            const localVarPath = `/formalite/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageJsonldMessageCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [expediteur] 
         * @param {Array<number>} [expediteur2] 
         * @param {boolean} [readByPrescri] 
         * @param {boolean} [readByFrmlist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierMessagesGetCollection(dossier: string, page?: number, itemsPerPage?: number, expediteur?: number, expediteur2?: Array<number>, readByPrescri?: boolean, readByFrmlist?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierMessagesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierMessagesGetCollection(dossier, page, itemsPerPage, expediteur, expediteur2, readByPrescri, readByFrmlist, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {string} id FproDosMessage identifier
         * @param {MessageJsonld} messageJsonld The updated Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdAckPut(id: string, messageJsonld: MessageJsonld, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdAckPut(id, messageJsonld, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {string} id FproDosMessage identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageJsonldMessageCreate} messageJsonldMessageCreate The new Message resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPost(messageJsonldMessageCreate: MessageJsonldMessageCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageJsonldMessageCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPost(messageJsonldMessageCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * Obtenir la liste des messages
         * @summary Obtenir la liste des messages
         * @param {MessageApiApiDossiersDossierMessagesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierMessagesGetCollection(requestParameters: MessageApiApiDossiersDossierMessagesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierMessagesGetCollection200Response> {
            return localVarFp.apiDossiersDossierMessagesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.expediteur, requestParameters.expediteur2, requestParameters.readByPrescri, requestParameters.readByFrmlist, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Message resource.
         * @summary Modifier un message
         * @param {MessageApiApiMessagesIdAckPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdAckPut(requestParameters: MessageApiApiMessagesIdAckPutRequest, options?: AxiosRequestConfig): AxiosPromise<MessageJsonldMessageRead> {
            return localVarFp.apiMessagesIdAckPut(requestParameters.id, requestParameters.messageJsonld, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un message
         * @summary Supprimer un message
         * @param {MessageApiApiMessagesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdDelete(requestParameters: MessageApiApiMessagesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiMessagesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un message
         * @summary Récupérer les informations d\'un message
         * @param {MessageApiApiMessagesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesIdGet(requestParameters: MessageApiApiMessagesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<MessageJsonldMessageRead> {
            return localVarFp.apiMessagesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Message resource.
         * @summary Créer un message
         * @param {MessageApiApiMessagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPost(requestParameters: MessageApiApiMessagesPostRequest, options?: AxiosRequestConfig): AxiosPromise<MessageJsonldMessageCreated> {
            return localVarFp.apiMessagesPost(requestParameters.messageJsonldMessageCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierMessagesGetCollection operation in MessageApi.
 * @export
 * @interface MessageApiApiDossiersDossierMessagesGetCollectionRequest
 */
export interface MessageApiApiDossiersDossierMessagesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly expediteur?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly expediteur2?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly readByPrescri?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MessageApiApiDossiersDossierMessagesGetCollection
     */
    readonly readByFrmlist?: boolean
}

/**
 * Request parameters for apiMessagesIdAckPut operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdAckPutRequest
 */
export interface MessageApiApiMessagesIdAckPutRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdAckPut
     */
    readonly id: string

    /**
     * The updated Message resource
     * @type {MessageJsonld}
     * @memberof MessageApiApiMessagesIdAckPut
     */
    readonly messageJsonld: MessageJsonld
}

/**
 * Request parameters for apiMessagesIdDelete operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdDeleteRequest
 */
export interface MessageApiApiMessagesIdDeleteRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiMessagesIdGet operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesIdGetRequest
 */
export interface MessageApiApiMessagesIdGetRequest {
    /**
     * FproDosMessage identifier
     * @type {string}
     * @memberof MessageApiApiMessagesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiMessagesPost operation in MessageApi.
 * @export
 * @interface MessageApiApiMessagesPostRequest
 */
export interface MessageApiApiMessagesPostRequest {
    /**
     * The new Message resource
     * @type {MessageJsonldMessageCreate}
     * @memberof MessageApiApiMessagesPost
     */
    readonly messageJsonldMessageCreate: MessageJsonldMessageCreate
}

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * Obtenir la liste des messages
     * @summary Obtenir la liste des messages
     * @param {MessageApiApiDossiersDossierMessagesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiDossiersDossierMessagesGetCollection(requestParameters: MessageApiApiDossiersDossierMessagesGetCollectionRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiDossiersDossierMessagesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.expediteur, requestParameters.expediteur2, requestParameters.readByPrescri, requestParameters.readByFrmlist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Message resource.
     * @summary Modifier un message
     * @param {MessageApiApiMessagesIdAckPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdAckPut(requestParameters: MessageApiApiMessagesIdAckPutRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdAckPut(requestParameters.id, requestParameters.messageJsonld, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un message
     * @summary Supprimer un message
     * @param {MessageApiApiMessagesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdDelete(requestParameters: MessageApiApiMessagesIdDeleteRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un message
     * @summary Récupérer les informations d\'un message
     * @param {MessageApiApiMessagesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesIdGet(requestParameters: MessageApiApiMessagesIdGetRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Message resource.
     * @summary Créer un message
     * @param {MessageApiApiMessagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiMessagesPost(requestParameters: MessageApiApiMessagesPostRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiMessagesPost(requestParameters.messageJsonldMessageCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotesApi - axios parameter creator
 * @export
 */
export const NotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierNotesGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierNotesGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/notes`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {string} id TblEvenement identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNotesIdGet', 'id', id)
            const localVarPath = `/formalite/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {string} id TblEvenement identifier
         * @param {NotesEtRappelsJsonldNoteUpdate} notesEtRappelsJsonldNoteUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdPut: async (id: string, notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNotesIdPut', 'id', id)
            // verify required parameter 'notesEtRappelsJsonldNoteUpdate' is not null or undefined
            assertParamExists('apiNotesIdPut', 'notesEtRappelsJsonldNoteUpdate', notesEtRappelsJsonldNoteUpdate)
            const localVarPath = `/formalite/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoteUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesEtRappelsJsonldNoteCreate} notesEtRappelsJsonldNoteCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost: async (notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notesEtRappelsJsonldNoteCreate' is not null or undefined
            assertParamExists('apiNotesPost', 'notesEtRappelsJsonldNoteCreate', notesEtRappelsJsonldNoteCreate)
            const localVarPath = `/formalite/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoteCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesApi - functional programming interface
 * @export
 */
export const NotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierNotesGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierNotesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierNotesGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {string} id TblEvenement identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {string} id TblEvenement identifier
         * @param {NotesEtRappelsJsonldNoteUpdate} notesEtRappelsJsonldNoteUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesIdPut(id: string, notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesIdPut(id, notesEtRappelsJsonldNoteUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesEtRappelsJsonldNoteCreate} notesEtRappelsJsonldNoteCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotesPost(notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoteCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotesPost(notesEtRappelsJsonldNoteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotesApi - factory interface
 * @export
 */
export const NotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotesApiFp(configuration)
    return {
        /**
         * Obtenir la liste des notes
         * @summary Obtenir la liste des notes
         * @param {NotesApiApiDossiersDossierNotesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierNotesGetCollection(requestParameters: NotesApiApiDossiersDossierNotesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierNotesGetCollection200Response> {
            return localVarFp.apiDossiersDossierNotesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une note
         * @summary Récupérer les informations d\'une note
         * @param {NotesApiApiNotesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdGet(requestParameters: NotesApiApiNotesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteRead> {
            return localVarFp.apiNotesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier une note
         * @param {NotesApiApiNotesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesIdPut(requestParameters: NotesApiApiNotesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteRead> {
            return localVarFp.apiNotesIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoteUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer une note
         * @param {NotesApiApiNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotesPost(requestParameters: NotesApiApiNotesPostRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoteCreated> {
            return localVarFp.apiNotesPost(requestParameters.notesEtRappelsJsonldNoteCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierNotesGetCollection operation in NotesApi.
 * @export
 * @interface NotesApiApiDossiersDossierNotesGetCollectionRequest
 */
export interface NotesApiApiDossiersDossierNotesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof NotesApiApiDossiersDossierNotesGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiNotesIdGet operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesIdGetRequest
 */
export interface NotesApiApiNotesIdGetRequest {
    /**
     * TblEvenement identifier
     * @type {string}
     * @memberof NotesApiApiNotesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiNotesIdPut operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesIdPutRequest
 */
export interface NotesApiApiNotesIdPutRequest {
    /**
     * TblEvenement identifier
     * @type {string}
     * @memberof NotesApiApiNotesIdPut
     */
    readonly id: string

    /**
     * The updated Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoteUpdate}
     * @memberof NotesApiApiNotesIdPut
     */
    readonly notesEtRappelsJsonldNoteUpdate: NotesEtRappelsJsonldNoteUpdate
}

/**
 * Request parameters for apiNotesPost operation in NotesApi.
 * @export
 * @interface NotesApiApiNotesPostRequest
 */
export interface NotesApiApiNotesPostRequest {
    /**
     * The new Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoteCreate}
     * @memberof NotesApiApiNotesPost
     */
    readonly notesEtRappelsJsonldNoteCreate: NotesEtRappelsJsonldNoteCreate
}

/**
 * NotesApi - object-oriented interface
 * @export
 * @class NotesApi
 * @extends {BaseAPI}
 */
export class NotesApi extends BaseAPI {
    /**
     * Obtenir la liste des notes
     * @summary Obtenir la liste des notes
     * @param {NotesApiApiDossiersDossierNotesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiDossiersDossierNotesGetCollection(requestParameters: NotesApiApiDossiersDossierNotesGetCollectionRequest, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiDossiersDossierNotesGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une note
     * @summary Récupérer les informations d\'une note
     * @param {NotesApiApiNotesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesIdGet(requestParameters: NotesApiApiNotesIdGetRequest, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Notes et rappels resource.
     * @summary Modifier une note
     * @param {NotesApiApiNotesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesIdPut(requestParameters: NotesApiApiNotesIdPutRequest, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoteUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Notes et rappels resource.
     * @summary Créer une note
     * @param {NotesApiApiNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesApi
     */
    public apiNotesPost(requestParameters: NotesApiApiNotesPostRequest, options?: AxiosRequestConfig) {
        return NotesApiFp(this.configuration).apiNotesPost(requestParameters.notesEtRappelsJsonldNoteCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrigineApi - axios parameter creator
 * @export
 */
export const OrigineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {string} id TblOrigine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesIdFormatGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOriginesIdFormatGet', 'id', id)
            const localVarPath = `/formalite/origines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrigineApi - functional programming interface
 * @export
 */
export const OrigineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrigineApiAxiosParamCreator(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {string} id TblOrigine identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOriginesIdFormatGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOriginesIdFormatGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrigineApi - factory interface
 * @export
 */
export const OrigineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrigineApiFp(configuration)
    return {
        /**
         * hidden
         * @summary Retrieves a Origine resource.
         * @param {OrigineApiApiOriginesIdFormatGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOriginesIdFormatGet(requestParameters: OrigineApiApiOriginesIdFormatGetRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiOriginesIdFormatGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOriginesIdFormatGet operation in OrigineApi.
 * @export
 * @interface OrigineApiApiOriginesIdFormatGetRequest
 */
export interface OrigineApiApiOriginesIdFormatGetRequest {
    /**
     * TblOrigine identifier
     * @type {string}
     * @memberof OrigineApiApiOriginesIdFormatGet
     */
    readonly id: string
}

/**
 * OrigineApi - object-oriented interface
 * @export
 * @class OrigineApi
 * @extends {BaseAPI}
 */
export class OrigineApi extends BaseAPI {
    /**
     * hidden
     * @summary Retrieves a Origine resource.
     * @param {OrigineApiApiOriginesIdFormatGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrigineApi
     */
    public apiOriginesIdFormatGet(requestParameters: OrigineApiApiOriginesIdFormatGetRequest, options?: AxiosRequestConfig) {
        return OrigineApiFp(this.configuration).apiOriginesIdFormatGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RappelsApi - axios parameter creator
 * @export
 */
export const RappelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRappelsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierRappelsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/rappels`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRappelsIdGet', 'id', id)
            const localVarPath = `/formalite/rappels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {NotesEtRappelsJsonldNoterappelUpdate} notesEtRappelsJsonldNoterappelUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdPut: async (id: string, notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRappelsIdPut', 'id', id)
            // verify required parameter 'notesEtRappelsJsonldNoterappelUpdate' is not null or undefined
            assertParamExists('apiRappelsIdPut', 'notesEtRappelsJsonldNoterappelUpdate', notesEtRappelsJsonldNoterappelUpdate)
            const localVarPath = `/formalite/rappels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoterappelUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {NotesEtRappelsJsonldNoterappelCreate} notesEtRappelsJsonldNoterappelCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsPost: async (notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notesEtRappelsJsonldNoterappelCreate' is not null or undefined
            assertParamExists('apiRappelsPost', 'notesEtRappelsJsonldNoterappelCreate', notesEtRappelsJsonldNoterappelCreate)
            const localVarPath = `/formalite/rappels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notesEtRappelsJsonldNoterappelCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RappelsApi - functional programming interface
 * @export
 */
export const RappelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RappelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierRappelsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierRappelsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierRappelsGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {string} id TblEvenementRappel identifier
         * @param {NotesEtRappelsJsonldNoterappelUpdate} notesEtRappelsJsonldNoterappelUpdate The updated Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsIdPut(id: string, notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsIdPut(id, notesEtRappelsJsonldNoterappelUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {NotesEtRappelsJsonldNoterappelCreate} notesEtRappelsJsonldNoterappelCreate The new Notes et rappels resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRappelsPost(notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesEtRappelsJsonldNoterappelCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRappelsPost(notesEtRappelsJsonldNoterappelCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RappelsApi - factory interface
 * @export
 */
export const RappelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RappelsApiFp(configuration)
    return {
        /**
         * Obtenir la liste des rappels
         * @summary Obtenir la liste des rappels
         * @param {RappelsApiApiDossiersDossierRappelsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierRappelsGetCollection(requestParameters: RappelsApiApiDossiersDossierRappelsGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierRappelsGetCollection200Response> {
            return localVarFp.apiDossiersDossierRappelsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un rappel
         * @summary Récupérer les informations d\'un rappel
         * @param {RappelsApiApiRappelsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdGet(requestParameters: RappelsApiApiRappelsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelRead> {
            return localVarFp.apiRappelsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Notes et rappels resource.
         * @summary Modifier un rappel
         * @param {RappelsApiApiRappelsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsIdPut(requestParameters: RappelsApiApiRappelsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelRead> {
            return localVarFp.apiRappelsIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoterappelUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Notes et rappels resource.
         * @summary Créer un rappel
         * @param {RappelsApiApiRappelsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRappelsPost(requestParameters: RappelsApiApiRappelsPostRequest, options?: AxiosRequestConfig): AxiosPromise<NotesEtRappelsJsonldNoterappelCreated> {
            return localVarFp.apiRappelsPost(requestParameters.notesEtRappelsJsonldNoterappelCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierRappelsGetCollection operation in RappelsApi.
 * @export
 * @interface RappelsApiApiDossiersDossierRappelsGetCollectionRequest
 */
export interface RappelsApiApiDossiersDossierRappelsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof RappelsApiApiDossiersDossierRappelsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiRappelsIdGet operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsIdGetRequest
 */
export interface RappelsApiApiRappelsIdGetRequest {
    /**
     * TblEvenementRappel identifier
     * @type {string}
     * @memberof RappelsApiApiRappelsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiRappelsIdPut operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsIdPutRequest
 */
export interface RappelsApiApiRappelsIdPutRequest {
    /**
     * TblEvenementRappel identifier
     * @type {string}
     * @memberof RappelsApiApiRappelsIdPut
     */
    readonly id: string

    /**
     * The updated Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoterappelUpdate}
     * @memberof RappelsApiApiRappelsIdPut
     */
    readonly notesEtRappelsJsonldNoterappelUpdate: NotesEtRappelsJsonldNoterappelUpdate
}

/**
 * Request parameters for apiRappelsPost operation in RappelsApi.
 * @export
 * @interface RappelsApiApiRappelsPostRequest
 */
export interface RappelsApiApiRappelsPostRequest {
    /**
     * The new Notes et rappels resource
     * @type {NotesEtRappelsJsonldNoterappelCreate}
     * @memberof RappelsApiApiRappelsPost
     */
    readonly notesEtRappelsJsonldNoterappelCreate: NotesEtRappelsJsonldNoterappelCreate
}

/**
 * RappelsApi - object-oriented interface
 * @export
 * @class RappelsApi
 * @extends {BaseAPI}
 */
export class RappelsApi extends BaseAPI {
    /**
     * Obtenir la liste des rappels
     * @summary Obtenir la liste des rappels
     * @param {RappelsApiApiDossiersDossierRappelsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiDossiersDossierRappelsGetCollection(requestParameters: RappelsApiApiDossiersDossierRappelsGetCollectionRequest, options?: AxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiDossiersDossierRappelsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un rappel
     * @summary Récupérer les informations d\'un rappel
     * @param {RappelsApiApiRappelsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsIdGet(requestParameters: RappelsApiApiRappelsIdGetRequest, options?: AxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Notes et rappels resource.
     * @summary Modifier un rappel
     * @param {RappelsApiApiRappelsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsIdPut(requestParameters: RappelsApiApiRappelsIdPutRequest, options?: AxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsIdPut(requestParameters.id, requestParameters.notesEtRappelsJsonldNoterappelUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Notes et rappels resource.
     * @summary Créer un rappel
     * @param {RappelsApiApiRappelsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RappelsApi
     */
    public apiRappelsPost(requestParameters: RappelsApiApiRappelsPostRequest, options?: AxiosRequestConfig) {
        return RappelsApiFp(this.configuration).apiRappelsPost(requestParameters.notesEtRappelsJsonldNoterappelCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReglementsApi - axios parameter creator
 * @export
 */
export const ReglementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierReglementsGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierReglementsGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/reglements`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReglementsIdGet', 'id', id)
            const localVarPath = `/formalite/reglements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldReglementUpdate} reglementsEtAcomptesCBJsonldReglementUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdPut: async (id: string, reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiReglementsIdPut', 'id', id)
            // verify required parameter 'reglementsEtAcomptesCBJsonldReglementUpdate' is not null or undefined
            assertParamExists('apiReglementsIdPut', 'reglementsEtAcomptesCBJsonldReglementUpdate', reglementsEtAcomptesCBJsonldReglementUpdate)
            const localVarPath = `/formalite/reglements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldReglementUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsEtAcomptesCBJsonldReglementCreate} reglementsEtAcomptesCBJsonldReglementCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsPost: async (reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reglementsEtAcomptesCBJsonldReglementCreate' is not null or undefined
            assertParamExists('apiReglementsPost', 'reglementsEtAcomptesCBJsonldReglementCreate', reglementsEtAcomptesCBJsonldReglementCreate)
            const localVarPath = `/formalite/reglements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reglementsEtAcomptesCBJsonldReglementCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReglementsApi - functional programming interface
 * @export
 */
export const ReglementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReglementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierReglementsGetCollection(dossier: string, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierReglementsGetCollection(dossier, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {string} id TblReglement2 identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {string} id TblReglement2 identifier
         * @param {ReglementsEtAcomptesCBJsonldReglementUpdate} reglementsEtAcomptesCBJsonldReglementUpdate The updated Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsIdPut(id: string, reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldReglementRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsIdPut(id, reglementsEtAcomptesCBJsonldReglementUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsEtAcomptesCBJsonldReglementCreate} reglementsEtAcomptesCBJsonldReglementCreate The new Reglements et acomptes CB resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReglementsPost(reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReglementsEtAcomptesCBJsonldReglementCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReglementsPost(reglementsEtAcomptesCBJsonldReglementCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReglementsApi - factory interface
 * @export
 */
export const ReglementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReglementsApiFp(configuration)
    return {
        /**
         * Obtenir la liste des règlements
         * @summary Obtenir la liste des règlements
         * @param {ReglementsApiApiDossiersDossierReglementsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierReglementsGetCollection(requestParameters: ReglementsApiApiDossiersDossierReglementsGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierAcomptesGetCollection200Response> {
            return localVarFp.apiDossiersDossierReglementsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un règlement
         * @summary Récupérer les informations d\'un règlement
         * @param {ReglementsApiApiReglementsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdGet(requestParameters: ReglementsApiApiReglementsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldAcompteRead> {
            return localVarFp.apiReglementsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Reglements et acomptes CB resource.
         * @summary Modifier un règlement
         * @param {ReglementsApiApiReglementsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsIdPut(requestParameters: ReglementsApiApiReglementsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldReglementRead> {
            return localVarFp.apiReglementsIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldReglementUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Reglements et acomptes CB resource.
         * @summary Créer un règlement
         * @param {ReglementsApiApiReglementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReglementsPost(requestParameters: ReglementsApiApiReglementsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ReglementsEtAcomptesCBJsonldReglementCreated> {
            return localVarFp.apiReglementsPost(requestParameters.reglementsEtAcomptesCBJsonldReglementCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierReglementsGetCollection operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiDossiersDossierReglementsGetCollectionRequest
 */
export interface ReglementsApiApiDossiersDossierReglementsGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof ReglementsApiApiDossiersDossierReglementsGetCollection
     */
    readonly itemsPerPage?: number
}

/**
 * Request parameters for apiReglementsIdGet operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsIdGetRequest
 */
export interface ReglementsApiApiReglementsIdGetRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof ReglementsApiApiReglementsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiReglementsIdPut operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsIdPutRequest
 */
export interface ReglementsApiApiReglementsIdPutRequest {
    /**
     * TblReglement2 identifier
     * @type {string}
     * @memberof ReglementsApiApiReglementsIdPut
     */
    readonly id: string

    /**
     * The updated Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldReglementUpdate}
     * @memberof ReglementsApiApiReglementsIdPut
     */
    readonly reglementsEtAcomptesCBJsonldReglementUpdate: ReglementsEtAcomptesCBJsonldReglementUpdate
}

/**
 * Request parameters for apiReglementsPost operation in ReglementsApi.
 * @export
 * @interface ReglementsApiApiReglementsPostRequest
 */
export interface ReglementsApiApiReglementsPostRequest {
    /**
     * The new Reglements et acomptes CB resource
     * @type {ReglementsEtAcomptesCBJsonldReglementCreate}
     * @memberof ReglementsApiApiReglementsPost
     */
    readonly reglementsEtAcomptesCBJsonldReglementCreate: ReglementsEtAcomptesCBJsonldReglementCreate
}

/**
 * ReglementsApi - object-oriented interface
 * @export
 * @class ReglementsApi
 * @extends {BaseAPI}
 */
export class ReglementsApi extends BaseAPI {
    /**
     * Obtenir la liste des règlements
     * @summary Obtenir la liste des règlements
     * @param {ReglementsApiApiDossiersDossierReglementsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiDossiersDossierReglementsGetCollection(requestParameters: ReglementsApiApiDossiersDossierReglementsGetCollectionRequest, options?: AxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiDossiersDossierReglementsGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un règlement
     * @summary Récupérer les informations d\'un règlement
     * @param {ReglementsApiApiReglementsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsIdGet(requestParameters: ReglementsApiApiReglementsIdGetRequest, options?: AxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Reglements et acomptes CB resource.
     * @summary Modifier un règlement
     * @param {ReglementsApiApiReglementsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsIdPut(requestParameters: ReglementsApiApiReglementsIdPutRequest, options?: AxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsIdPut(requestParameters.id, requestParameters.reglementsEtAcomptesCBJsonldReglementUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Reglements et acomptes CB resource.
     * @summary Créer un règlement
     * @param {ReglementsApiApiReglementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReglementsApi
     */
    public apiReglementsPost(requestParameters: ReglementsApiApiReglementsPostRequest, options?: AxiosRequestConfig) {
        return ReglementsApiFp(this.configuration).apiReglementsPost(requestParameters.reglementsEtAcomptesCBJsonldReglementCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatutApi - axios parameter creator
 * @export
 */
export const StatutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsGetCollection: async (page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/statuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdDelete', 'id', id)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdGet', 'id', id)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {string} id FproDosStatut identifier
         * @param {StatutJsonldStatutUpdate} statutJsonldStatutUpdate The updated Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdPut: async (id: string, statutJsonldStatutUpdate: StatutJsonldStatutUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiStatutsIdPut', 'id', id)
            // verify required parameter 'statutJsonldStatutUpdate' is not null or undefined
            assertParamExists('apiStatutsIdPut', 'statutJsonldStatutUpdate', statutJsonldStatutUpdate)
            const localVarPath = `/formalite/statuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statutJsonldStatutUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutJsonldStatutCreate} statutJsonldStatutCreate The new Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsPost: async (statutJsonldStatutCreate: StatutJsonldStatutCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statutJsonldStatutCreate' is not null or undefined
            assertParamExists('apiStatutsPost', 'statutJsonldStatutCreate', statutJsonldStatutCreate)
            const localVarPath = `/formalite/statuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statutJsonldStatutCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatutApi - functional programming interface
 * @export
 */
export const StatutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatutApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsGetCollection(page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiStatutsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsGetCollection(page, itemsPerPage, type, type2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {string} id FproDosStatut identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {string} id FproDosStatut identifier
         * @param {StatutJsonldStatutUpdate} statutJsonldStatutUpdate The updated Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsIdPut(id: string, statutJsonldStatutUpdate: StatutJsonldStatutUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsIdPut(id, statutJsonldStatutUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutJsonldStatutCreate} statutJsonldStatutCreate The new Statut resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatutsPost(statutJsonldStatutCreate: StatutJsonldStatutCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatutJsonldStatutCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatutsPost(statutJsonldStatutCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatutApi - factory interface
 * @export
 */
export const StatutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatutApiFp(configuration)
    return {
        /**
         * Obtenir la liste des statuts
         * @summary Obtenir la liste des statuts
         * @param {StatutApiApiStatutsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsGetCollection(requestParameters: StatutApiApiStatutsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiStatutsGetCollection200Response> {
            return localVarFp.apiStatutsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer un statut
         * @summary Supprimer un statut
         * @param {StatutApiApiStatutsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdDelete(requestParameters: StatutApiApiStatutsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiStatutsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'un statut
         * @summary Récupérer les informations d\'un statut
         * @param {StatutApiApiStatutsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdGet(requestParameters: StatutApiApiStatutsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<StatutJsonldStatutRead> {
            return localVarFp.apiStatutsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Statut resource.
         * @summary Modifier un statut
         * @param {StatutApiApiStatutsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsIdPut(requestParameters: StatutApiApiStatutsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<StatutJsonldStatutRead> {
            return localVarFp.apiStatutsIdPut(requestParameters.id, requestParameters.statutJsonldStatutUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Statut resource.
         * @summary Créer un statut
         * @param {StatutApiApiStatutsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatutsPost(requestParameters: StatutApiApiStatutsPostRequest, options?: AxiosRequestConfig): AxiosPromise<StatutJsonldStatutCreated> {
            return localVarFp.apiStatutsPost(requestParameters.statutJsonldStatutCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiStatutsGetCollection operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsGetCollectionRequest
 */
export interface StatutApiApiStatutsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly type?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof StatutApiApiStatutsGetCollection
     */
    readonly type2?: Array<number>
}

/**
 * Request parameters for apiStatutsIdDelete operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdDeleteRequest
 */
export interface StatutApiApiStatutsIdDeleteRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiStatutsIdGet operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdGetRequest
 */
export interface StatutApiApiStatutsIdGetRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiStatutsIdPut operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsIdPutRequest
 */
export interface StatutApiApiStatutsIdPutRequest {
    /**
     * FproDosStatut identifier
     * @type {string}
     * @memberof StatutApiApiStatutsIdPut
     */
    readonly id: string

    /**
     * The updated Statut resource
     * @type {StatutJsonldStatutUpdate}
     * @memberof StatutApiApiStatutsIdPut
     */
    readonly statutJsonldStatutUpdate: StatutJsonldStatutUpdate
}

/**
 * Request parameters for apiStatutsPost operation in StatutApi.
 * @export
 * @interface StatutApiApiStatutsPostRequest
 */
export interface StatutApiApiStatutsPostRequest {
    /**
     * The new Statut resource
     * @type {StatutJsonldStatutCreate}
     * @memberof StatutApiApiStatutsPost
     */
    readonly statutJsonldStatutCreate: StatutJsonldStatutCreate
}

/**
 * StatutApi - object-oriented interface
 * @export
 * @class StatutApi
 * @extends {BaseAPI}
 */
export class StatutApi extends BaseAPI {
    /**
     * Obtenir la liste des statuts
     * @summary Obtenir la liste des statuts
     * @param {StatutApiApiStatutsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsGetCollection(requestParameters: StatutApiApiStatutsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer un statut
     * @summary Supprimer un statut
     * @param {StatutApiApiStatutsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdDelete(requestParameters: StatutApiApiStatutsIdDeleteRequest, options?: AxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'un statut
     * @summary Récupérer les informations d\'un statut
     * @param {StatutApiApiStatutsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdGet(requestParameters: StatutApiApiStatutsIdGetRequest, options?: AxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Statut resource.
     * @summary Modifier un statut
     * @param {StatutApiApiStatutsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsIdPut(requestParameters: StatutApiApiStatutsIdPutRequest, options?: AxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsIdPut(requestParameters.id, requestParameters.statutJsonldStatutUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Statut resource.
     * @summary Créer un statut
     * @param {StatutApiApiStatutsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatutApi
     */
    public apiStatutsPost(requestParameters: StatutApiApiStatutsPostRequest, options?: AxiosRequestConfig) {
        return StatutApiFp(this.configuration).apiStatutsPost(requestParameters.statutJsonldStatutCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TypeDeDocumentApi - axios parameter creator
 * @export
 */
export const TypeDeDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection: async (page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/formalite/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (type2) {
                localVarQueryParameter['type[]'] = type2;
            }

            if (domaineId !== undefined) {
                localVarQueryParameter['domaine.id'] = domaineId;
            }

            if (domaineId2) {
                localVarQueryParameter['domaine.id[]'] = domaineId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdDelete', 'id', id)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdGet', 'id', id)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut: async (id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'id', id)
            // verify required parameter 'typeDeDocumentJsonldTypedocumentUpdate' is not null or undefined
            assertParamExists('apiTypesDocumentsIdPut', 'typeDeDocumentJsonldTypedocumentUpdate', typeDeDocumentJsonldTypedocumentUpdate)
            const localVarPath = `/formalite/types-documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost: async (typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeDeDocumentJsonldTypedocumentCreate' is not null or undefined
            assertParamExists('apiTypesDocumentsPost', 'typeDeDocumentJsonldTypedocumentCreate', typeDeDocumentJsonldTypedocumentCreate)
            const localVarPath = `/formalite/types-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentJsonldTypedocumentCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeDeDocumentApi - functional programming interface
 * @export
 */
export const TypeDeDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeDeDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [type] 
         * @param {Array<number>} [type2] 
         * @param {number} [domaineId] 
         * @param {Array<number>} [domaineId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsGetCollection(page?: number, itemsPerPage?: number, type?: number, type2?: Array<number>, domaineId?: number, domaineId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTypesDocumentsGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsGetCollection(page, itemsPerPage, type, type2, domaineId, domaineId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {string} id TblTypeDocument identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {string} id TblTypeDocument identifier
         * @param {TypeDeDocumentJsonldTypedocumentUpdate} typeDeDocumentJsonldTypedocumentUpdate The updated Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsIdPut(id: string, typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsIdPut(id, typeDeDocumentJsonldTypedocumentUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentJsonldTypedocumentCreate} typeDeDocumentJsonldTypedocumentCreate The new Type de document resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocumentsPost(typeDeDocumentJsonldTypedocumentCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TypeDeDocumentApi - factory interface
 * @export
 */
export const TypeDeDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeDeDocumentApiFp(configuration)
    return {
        /**
         * Obtenir la liste des types de document
         * @summary Obtenir la liste des types de document
         * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiTypesDocumentsGetCollection200Response> {
            return localVarFp.apiTypesDocumentsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation type de document
         * @summary Supprimer une relation type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiTypesDocumentsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation type de document
         * @summary Récupérer les informations d\'une relation type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Type de document resource.
         * @summary Modifier un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentRead> {
            return localVarFp.apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Type de document resource.
         * @summary Ajouter un type de document
         * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentJsonldTypedocumentCreated> {
            return localVarFp.apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTypesDocumentsGetCollection operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest {
    /**
     * The collection page number
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly type2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof TypeDeDocumentApiApiTypesDocumentsGetCollection
     */
    readonly domaineId2?: Array<number>
}

/**
 * Request parameters for apiTypesDocumentsIdDelete operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocumentsIdGet operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdGetRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocumentsIdPut operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsIdPutRequest {
    /**
     * TblTypeDocument identifier
     * @type {string}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly id: string

    /**
     * The updated Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentUpdate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsIdPut
     */
    readonly typeDeDocumentJsonldTypedocumentUpdate: TypeDeDocumentJsonldTypedocumentUpdate
}

/**
 * Request parameters for apiTypesDocumentsPost operation in TypeDeDocumentApi.
 * @export
 * @interface TypeDeDocumentApiApiTypesDocumentsPostRequest
 */
export interface TypeDeDocumentApiApiTypesDocumentsPostRequest {
    /**
     * The new Type de document resource
     * @type {TypeDeDocumentJsonldTypedocumentCreate}
     * @memberof TypeDeDocumentApiApiTypesDocumentsPost
     */
    readonly typeDeDocumentJsonldTypedocumentCreate: TypeDeDocumentJsonldTypedocumentCreate
}

/**
 * TypeDeDocumentApi - object-oriented interface
 * @export
 * @class TypeDeDocumentApi
 * @extends {BaseAPI}
 */
export class TypeDeDocumentApi extends BaseAPI {
    /**
     * Obtenir la liste des types de document
     * @summary Obtenir la liste des types de document
     * @param {TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsGetCollection(requestParameters: TypeDeDocumentApiApiTypesDocumentsGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsGetCollection(requestParameters.page, requestParameters.itemsPerPage, requestParameters.type, requestParameters.type2, requestParameters.domaineId, requestParameters.domaineId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation type de document
     * @summary Supprimer une relation type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdDelete(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdDeleteRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation type de document
     * @summary Récupérer les informations d\'une relation type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdGet(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdGetRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Type de document resource.
     * @summary Modifier un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsIdPut(requestParameters: TypeDeDocumentApiApiTypesDocumentsIdPutRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsIdPut(requestParameters.id, requestParameters.typeDeDocumentJsonldTypedocumentUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Type de document resource.
     * @summary Ajouter un type de document
     * @param {TypeDeDocumentApiApiTypesDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentApi
     */
    public apiTypesDocumentsPost(requestParameters: TypeDeDocumentApiApiTypesDocumentsPostRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentApiFp(this.configuration).apiTypesDocumentsPost(requestParameters.typeDeDocumentJsonldTypedocumentCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TypeDeDocumentDesDossiersApi - axios parameter creator
 * @export
 */
export const TypeDeDocumentDesDossiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {Array<string>} [dossierNumero2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierTypesDocDossierGetCollection: async (dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierNumero2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierTypesDocDossierGetCollection', 'dossier', dossier)
            const localVarPath = `/formalite/dossiers/{dossier}/types-doc-dossier`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierNumero2) {
                localVarQueryParameter['dossier.numero[]'] = dossierNumero2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdDelete', 'id', id)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdGet', 'id', id)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate} typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPut: async (id: string, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPut', 'id', id)
            // verify required parameter 'typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate' is not null or undefined
            assertParamExists('apiTypesDocDossierIdPut', 'typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate', typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate)
            const localVarPath = `/formalite/types-doc-dossier/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate} typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate The new Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierPost: async (typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate' is not null or undefined
            assertParamExists('apiTypesDocDossierPost', 'typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate', typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate)
            const localVarPath = `/formalite/types-doc-dossier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TypeDeDocumentDesDossiersApi - functional programming interface
 * @export
 */
export const TypeDeDocumentDesDossiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TypeDeDocumentDesDossiersApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {string} dossier FproDossier identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {string} [dossierNumero] 
         * @param {Array<string>} [dossierNumero2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierTypesDocDossierGetCollection(dossier: string, page?: number, itemsPerPage?: number, dossierNumero?: string, dossierNumero2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierTypesDocDossierGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierTypesDocDossierGetCollection(dossier, page, itemsPerPage, dossierNumero, dossierNumero2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {string} id FproDossierDoc identifier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate} typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate The updated Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierIdPut(id: string, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierIdPut(id, typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate} typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate The new Type de document des dossiers resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTypesDocDossierPost(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTypesDocDossierPost(typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TypeDeDocumentDesDossiersApi - factory interface
 * @export
 */
export const TypeDeDocumentDesDossiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TypeDeDocumentDesDossiersApiFp(configuration)
    return {
        /**
         * Obtenir la liste des types de document liés au(x) dossier(s)
         * @summary Obtenir la liste des types de document liés au(x) dossier(s)
         * @param {TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierTypesDocDossierGetCollection(requestParameters: TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierTypesDocDossierGetCollection200Response> {
            return localVarFp.apiDossiersDossierTypesDocDossierGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierNumero2, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une relation type de document dossier
         * @summary Supprimer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdDelete(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiTypesDocDossierIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une relation type de document dossier
         * @summary Récupérer les informations d\'une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdGet(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead> {
            return localVarFp.apiTypesDocDossierIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Type de document des dossiers resource.
         * @summary Modifier une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierIdPut(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierRead> {
            return localVarFp.apiTypesDocDossierIdPut(requestParameters.id, requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Type de document des dossiers resource.
         * @summary Créer une relation type de document dossier
         * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTypesDocDossierPost(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest, options?: AxiosRequestConfig): AxiosPromise<TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreated> {
            return localVarFp.apiTypesDocDossierPost(requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierTypesDocDossierGetCollection operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest
 */
export interface TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossier: string

    /**
     * The collection page number
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly page?: number

    /**
     * The number of items per page
     * @type {number}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly itemsPerPage?: number

    /**
     * 
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollection
     */
    readonly dossierNumero2?: Array<string>
}

/**
 * Request parameters for apiTypesDocDossierIdDelete operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocDossierIdGet operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiTypesDocDossierIdPut operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest {
    /**
     * FproDossierDoc identifier
     * @type {string}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPut
     */
    readonly id: string

    /**
     * The updated Type de document des dossiers resource
     * @type {TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPut
     */
    readonly typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate
}

/**
 * Request parameters for apiTypesDocDossierPost operation in TypeDeDocumentDesDossiersApi.
 * @export
 * @interface TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest
 */
export interface TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest {
    /**
     * The new Type de document des dossiers resource
     * @type {TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate}
     * @memberof TypeDeDocumentDesDossiersApiApiTypesDocDossierPost
     */
    readonly typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate: TypeDeDocumentDesDossiersJsonldTypedocumentdossierCreate
}

/**
 * TypeDeDocumentDesDossiersApi - object-oriented interface
 * @export
 * @class TypeDeDocumentDesDossiersApi
 * @extends {BaseAPI}
 */
export class TypeDeDocumentDesDossiersApi extends BaseAPI {
    /**
     * Obtenir la liste des types de document liés au(x) dossier(s)
     * @summary Obtenir la liste des types de document liés au(x) dossier(s)
     * @param {TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiDossiersDossierTypesDocDossierGetCollection(requestParameters: TypeDeDocumentDesDossiersApiApiDossiersDossierTypesDocDossierGetCollectionRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiDossiersDossierTypesDocDossierGetCollection(requestParameters.dossier, requestParameters.page, requestParameters.itemsPerPage, requestParameters.dossierNumero, requestParameters.dossierNumero2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une relation type de document dossier
     * @summary Supprimer une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdDelete(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdDeleteRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une relation type de document dossier
     * @summary Récupérer les informations d\'une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdGet(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdGetRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Type de document des dossiers resource.
     * @summary Modifier une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierIdPut(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierIdPutRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierIdPut(requestParameters.id, requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Type de document des dossiers resource.
     * @summary Créer une relation type de document dossier
     * @param {TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TypeDeDocumentDesDossiersApi
     */
    public apiTypesDocDossierPost(requestParameters: TypeDeDocumentDesDossiersApiApiTypesDocDossierPostRequest, options?: AxiosRequestConfig) {
        return TypeDeDocumentDesDossiersApiFp(this.configuration).apiTypesDocDossierPost(requestParameters.typeDeDocumentDesDossiersJsonldTypedocumentdossierCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


