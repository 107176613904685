import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    CatalogueApi,
    CatalogueCatalogueRead,
} from '../../../openApi/ApiOffre';
import { OFFRE_REDUCER_NAME } from '../constants';

export type CatalogApiObject = CatalogueCatalogueRead & Record<string, unknown>;

export type Catalogs = CatalogApiObject;

export const CATALOGS_SLICE_NAME = 'catalogs';

export const mapperCatalogs = function (catalogs: CatalogApiObject) {
    return {
        ...catalogs,
    } as Catalogs;
};

export function createCatalogsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new CatalogueApi(conf);

    // apiPrescripteursCataloguesGetCollection
    const fetchCatalogsCollection: FetchCollectionData<CatalogApiObject> = ({
        pagination: { page, itemsPerPage },
        xIdSociete,
        orders,
    }) => {
        const ordersSchema = {
            groupement: 'groupementLibelle',
            offre: 'offreLibelle',
        };
        const defaultOrders = {
            orderOffreLibelle: 'asc',
            orderGroupementLibelle: 'asc',
            orderNomCommercial: 'asc',
        };

        const capitalize = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);
        const orderParameters = () => {
            if (orders.length === 0) {
                return defaultOrders;
            }
            const prefix = 'order';
            return orders.reduce((acc, { field, value }) => {
                const keyName = ordersSchema[field as keyof typeof ordersSchema] ?? field;
                const key = `${prefix}${capitalize(keyName)}`;
                return {
                    ...acc,
                    [key]: value,
                };
            }, {});
        };

        const requestParameters = {
            xIdSociete,
            page: page + 1,
            itemsPerPage,
            domaineSocietesSocieteId: xIdSociete,
            ...orderParameters(),
        };

        return api
            .apiCataloguesGetCollection(requestParameters)
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<CatalogApiObject>>;
    };

    const fetchCatalogsItem: FetchItemDataType<CatalogApiObject> = ({ idItem, xIdSociete }) =>
        api
            .apiCataloguesIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<CatalogApiObject>;

    const { slice: catalogsSlice, dataSourcesThunks: catalogsDataSourcesThunks } =
        SliceFactory.createSlice<CatalogApiObject, Catalogs>(
            CATALOGS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [],
            fetchCatalogsCollection,
            fetchCatalogsItem,
            mapperCatalogs,
        );

    return { catalogsSlice, catalogsDataSourcesThunks };
}
