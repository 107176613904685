import React from 'react';
import { Box } from '@mui/material';
import FlexyFilters from '@europrocurement/flexy-datatable/components/FlexyFilters/FlexyFilters';
import { SpcContext, SpcContextProps } from '@europrocurement/flexy-components';
import { SearchOpts } from '@europrocurement/flexy-datatable';

export type CompatibilitiesSearchProps = {
    searchOpts: SearchOpts;
};

const CompatibilitiesSearch: React.FunctionComponent<CompatibilitiesSearchProps> = function (
    props,
) {
    const { searchOpts } = props;
    const { toggleRightMenu } = React.useContext<SpcContextProps>(SpcContext);

    return (
        <Box sx={{ marginBottom: '25px' }}>
            <FlexyFilters
                isSearch
                isExport
                isFilters
                searchOpts={searchOpts}
                toggleRightMenu={toggleRightMenu}
            />
        </Box>
    );
};

export default CompatibilitiesSearch;
