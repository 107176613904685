/* eslint-disable @typescript-eslint/no-explicit-any */
import useApiRequest from '@b2d/hooks/useApiRequest';
import { formaliteApi } from '@b2d/redux/subReducers/FormalityReducer';
import { Formalite } from '@europrocurement/l2d-domain';
import {
    FormaliteJsonldFormaliteCreate,
    FormaliteJsonldFormaliteUpdate,
} from '@europrocurement/l2d-domain/openApi/ApiFormalite';

const useFormalityFormHandler = () => {
    const { request, requestState } = useApiRequest();

    const updateFormality = async (formality: Formalite, payload: FormaliteJsonldFormaliteUpdate) =>
        new Promise((resolve) => {
            request(
                formaliteApi.apiFormalitesIdPut({
                    id: formality.id as unknown as string,
                    formaliteJsonldFormaliteUpdate: {
                        ...{
                            categorie: formality?.categorie?.id as unknown as string,
                            libelle: formality.libelle,
                            informationsSupplementaires: formality.informationsSupplementaires,
                            iconeName: formality.iconeName,
                            deleted: formality.deleted,
                            legalStatuses: formality.legalStatuses,
                            compatibilities: formality.compatibilities,
                            documents: formality.documents,
                        },
                        ...payload,
                    },
                }),
                {
                    successCallback: resolve,
                },
            );
        });

    const handleCreateForm = async (formData: { [key: string]: any }) =>
        new Promise((resolve) => {
            const { categorie, libelle, informationsSupplementaires, iconeName, formulaire } =
                formData;

            const payload: FormaliteJsonldFormaliteCreate = {
                categorie: categorie?.id,
                libelle,
                informationsSupplementaires,
                iconeName,
                deleted: false,
                documents: [],
                formulaire: formulaire?.id,
            };

            request(
                formaliteApi.apiFormalitesPost({
                    formaliteJsonldFormaliteCreate: payload,
                }),
                {
                    successCallback: resolve,
                },
            );
        });

    const handleEditForm = async (formality: Formalite, formData: { [key: string]: any }) => {
        const { libelle, informationsSupplementaires, categorie, iconeName, formulaire } = formData;

        const payload: FormaliteJsonldFormaliteUpdate = {
            categorie: categorie?.id,
            libelle,
            informationsSupplementaires,
            iconeName,
            documents: formality.documents,
            formulaire: formulaire?.id,
        };

        return updateFormality(formality, payload);
    };

    return { handleCreateForm, handleEditForm, updateFormality, requestState };
};

export default useFormalityFormHandler;
